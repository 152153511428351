import { useState } from "react";
import styled from "styled-components";
import * as ServerApi from "../../constants/ServerApiM";

interface ToggleButtonProps {
  id: string;
  type: "mod_yn" | "account_yn";
  initialAccountYn: string;
  initialModYn: string;
  onUpdate: (newValue: string) => void;
}

const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 40px;
    height: 18px;
    border-radius: 30px;
    background-color: #bebebe;
  }

  > .toggle--checked {
    background-color: #09348a;
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
  }

  > .toggle--checked {
    left: 23px;
    transition: 0.5s;
  }
`;

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  type,
  initialAccountYn,
  initialModYn,
  onUpdate,
}) => {
  const initialIsOn =
    type === "account_yn" ? initialAccountYn === "y" : initialModYn === "y";
  const [isOn, setIsOn] = useState(initialIsOn);

  const updateSetting = async (settingType: string, settingValue: string) => {
    try {
      const data = {
        u_id: id,
        account_yn:
          settingType === "account_yn" ? settingValue : initialAccountYn,
        mod_yn: settingType === "mod_yn" ? settingValue : initialModYn,
      };

      const response = await ServerApi.m_app_center_member_set_u(data);
      if (response.rsp_code === "100") {
        console.log("성공:", response);
        onUpdate(settingValue);
      } else {
        console.error("실패:", response);
      }
    } catch (error) {
      console.error("API 호출 오류:", error);
    }
  };

  const handleToggle = () => {
    const newValue = isOn ? "n" : "y";
    setIsOn(!isOn);
    updateSetting(type, newValue);
  };

  return (
    <>
      <ToggleContainer onClick={handleToggle}>
        <div className={`toggle-container ${isOn ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isOn ? "toggle--checked" : ""}`} />
      </ToggleContainer>
    </>
  );
};
