import { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import { useSelector } from "react-redux";
import { RootState } from "../component/redux/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #222;
`;

const Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  font-size: 24px;
  background-color: white;
  border: 2px solid #999;
  border-radius: 10px;
  margin-bottom: 50px;
  color: black;
`;

const Keypad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 200px;
  overflow-y: visible;

  @media (max-width: 768px) {
    overflow-y: auto;
    height: 52vh;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 24px;
  background-color: white;
  border: 2px solid #999;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;

  &:active {
    background-color: #e0e0e0;
  }
`;

const sendMessage = (value: any, center_no: any) => {
  fetch("http://3.37.242.5:4000/send", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ center_no, value }),
  }).catch((err) => console.error("Error sending message:", err));
  console.log("sendMessage", value);
};

const NumberPad = ({ location }: any) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const pro_no = location.state.pro_no;
  const center_no = MLoginInfo.MLoginInfo.center_no;
  const [sequences, setSequences] = useState([]);
  const [lastNumber, setLastNumber] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const m_center_pro_m_dt = useCallback(async () => {
    let result = await ServerApi.m_center_pro_m_dt({ pro_no: pro_no });

    console.log("m_center_pro_m_dt", result);
    const { isOk, data } = MyUtil._isResultSucces("m_center_pro_m_dt", result);
    if (isOk) {
      setSequences(data.pro_array);
    } else {
      console.error("Failed to fetch data");
    }
  }, [pro_no]);

  useEffect(() => {
    if (pro_no) {
      m_center_pro_m_dt();
      // sendMessage("RESET", center_no);  // 초기화 메시지 전송
    }
  }, [m_center_pro_m_dt, pro_no, center_no]);

  const handleButtonClick = (number: any) => {
    sendMessage(number, center_no);
    setLastNumber(number);
    setIsPlaying(false);
    console.log(sendMessage);
  };

  // const handleNext = () => {
  //   sendMessage("NEXT", center_no);
  // };

  const handlePlayPause = () => {
    const newStatus = !isPlaying ? "PAUSE" : "PLAY";
    sendMessage(newStatus, center_no);
    setIsPlaying(!isPlaying);
  };

  return (
    <Container>
      <Display>{lastNumber !== null ? lastNumber : ""}</Display>
      <Keypad>
        {sequences.map((item: any, idx: any) => (
          <Button key={idx} onClick={() => handleButtonClick(item.seq)}>
            {item.seq}
          </Button>
        ))}
      </Keypad>
      <div style={{ display: "flex", marginTop: "20px", gap: "10px" }}>
        {/* <Button onClick={handleNext}>
          <FontAwesomeIcon
            icon={faForward}
            style={{ width: "22px", height: "22px", marginLeft: "6px" }}
          />
        </Button> */}
        <Button onClick={handlePlayPause}>
          <FontAwesomeIcon
            icon={isPlaying ? faPlay : faPause}
            style={{
              width: "22px",
              height: "22px",
              marginLeft: isPlaying ? "4px" : "0",
            }}
          />
        </Button>
      </div>
    </Container>
  );
};

export default NumberPad;