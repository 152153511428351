import React, { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Picker } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../component/redux/allActions";
import * as ServerApi from "../constants/ServerApiM";
import * as MyUtil from "../constants/MyUtilM";
import "../css/DragItem.css";
import { RootState } from "../component/redux/rootReducer";
import styled from "styled-components";
import MobileHeader from "./Compornent/MobileHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { ItemType, UpdateStateFunctions } from "./Interface/Interface";
import AccountButton from "./Compornent/AccountButton";
import AlertModal from "react-modal";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { LuArrowUpDown } from "react-icons/lu";

interface TabButtonProps {
  active: boolean;
}

interface ProgramsVideoContainerProps {
  isModalOpen: boolean;
}

const ProgramsVideoContainer = styled.div<ProgramsVideoContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  width: 100%;
  padding-top: 70px;

  .containerWrap {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .hamburgerIcon {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .hamburgerWrap {
    position: absolute;
    top: 15px;
    right: 50px;
    background-color: #cccccc;
    border-radius: 15px;
    padding: 10px 15px;
    z-index: 999;
    text-align: left;
  }

  .hamburgerWrap li {
    margin: 15px 0;
    font-size: 1rem;
    font-weight: 500;
  }

  .hamburgerWrap li:hover {
    font-weight: 700;
  }
`;

const Overlay = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.85);
  display: ${({ isModalOpen }) => (isModalOpen ? "block" : "none")};
  z-index: 99;
`;

const VideoContents = styled.div`
  height: 100%;
  max-height: 70vh;
  overflow-y: auto;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  margin: 15px 15px 0 15px;
`;

const TabButton = styled.div<TabButtonProps>`
  flex-grow: 1;
  font-size: 0.6rem;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  background-color: transparent;
  color: ${(props) => (props.active ? "#09348a" : "none")};
  border-bottom: ${(props) => (props.active ? "2px solid #09348a" : "none")};
`;

const VideoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 80px 0;
  gap: 10px;
`;

// 영상 UI

const VideoContainer = styled.div`
  margin: 0 auto;
  padding: 5px;
  width: calc(100% - 10px);
  height: 25%;
  position: relative;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
`;

const VideoWrapper = styled.div`
  width: calc(33% - 5px);
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: start;
`;

const VideoBox = styled.div`
  width: 100%;
  height: 100%;
  max-height: 70%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;

  video {
    width: 100%;
  }
`;

const VideoImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const VideoTitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: start;
  padding: 5px;
`;

const VideoTitle = styled.div`
  font-size: 10px;
  margin: auto 0;
`;

const TimeWrap = styled.div`
  width: calc(66% - 5px);
  display: flex;
`;

const TimeTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 0 15px;
  position: relative;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 40%;
    background-color: #000;
  }

  &:last-child::after {
    background-color: transparent;
  }
`;

const SubmitContainer = styled.div`
  position: absolute;
  top: 10px;
  right: -50px;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const SubmitButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
  align-items: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #09348a;

  &:focus {
    outline: none;
  }

  &:active {
    color: #eeeeee;
    background-color: #09348a;
    border: none;
  }
`;

const UpdownButton = styled.div`
  width: 20px;
  height: 20px;
  background: #09348a;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 15px;
`;

const CancelButton = styled(SubmitButton)``;

// 위 아래 버튼 컴포넌트
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: auto 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff !important;

  &:hover {
    background-color: #e9e9e9;
    border: 1px solid #09348a;
    color: #09348a !important;
  }
`;

// 버튼을 세로로 배치
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  margin-bottom: 50px;
  border-radius: 15px 15px 0 0;
`;

const StyledInputContainer = styled.div`
  width: 80%;
  margin: 10px 0 30px 0;
  position: relative;
  border-bottom: 1px solid #09348a;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 75%;
  padding: 10px;
  background: none;
  border: none;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const VideoTitleFirst = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #000;
  text-align: left;
  border: none;
  margin-bottom: 5px;

  &:after {
    content: "";
    width: calc(100% - 35px);
    border-bottom: 1px solid #000;
    position: absolute;
    top: 30px;
    left: 18px;
  }
`;

const TitleInput = styled.input`
  background: none;
  border: none;
  margin-left: 10px;
  font-weight: 600;
`;

const VideoTitleContentsWrap = styled.div`
  display: flex;
`;

const VideoTitleContents = styled.div`
  margin-top: -15px;
  flex: 1;
  font-size: 1.2em;
  color: #000000;
  font-family: "Pretendard", serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #000000;
`;

const ModalText = styled.div`
  font-size: 0.9rem;
  line-height: 1.4rem;
  font-weight: 400;
`;

const TimeSetup: React.FC = ({ location }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const pro_no = location?.state?.pro_no;
  const [branchName, setBranchName] = useState("");
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [exTime, setExTime] = useState<string>("0");
  const [brTime, setBrTime] = useState<string>("0");
  const [waitTime, setWaitTime] = useState<string>("0");
  const [modalSelectValue, setModalSelectValue] = useState<(string | null)[]>([
    "0",
    "0",
    "0",
  ]);

  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(
    null
  );
  const [selectedTimeType, setSelectedTimeType] = useState<
    "ex_time" | "wait_time" | null
  >(null);

  const [seqType, setSeqType] = useState<
    "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H"
  >("A");
  const [arrVideoA, setArrVideoA] = useState<ItemType[]>([]);
  const [arrVideoB, setArrVideoB] = useState<ItemType[]>([]);
  const [arrVideoC, setArrVideoC] = useState<ItemType[]>([]);
  const [arrVideoD, setArrVideoD] = useState<ItemType[]>([]);
  const [arrVideoE, setArrVideoE] = useState<ItemType[]>([]);
  const [arrVideoF, setArrVideoF] = useState<ItemType[]>([]);
  const [arrVideoG, setArrVideoG] = useState<ItemType[]>([]);
  const [arrVideoH, setArrVideoH] = useState<ItemType[]>([]);

  const [checked, setChecked] = useState(false);

  // const buttonToShow = ["logo", "play", "profile", "setup"];
  const buttonToShow = ["logo", "stopWatchOn", "vibe"];

  const [activePage, setActivePage] = useState("exercise");
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedProgramIndex, setSelectedProgramIndex] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");

  // 시간 설정 모달
  const [modalOpenStates, setModalOpenStates] = useState<any>({});

  // TimeSetup 컴포넌트 상단에 상태 정의
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [initialSeconds, setInitialSeconds] = useState(0);

  // 비디오, 이미지 토글
  const [videoPlayStates, setVideoPlayStates] = useState<any>({});

  // 모달을 열고 초기 초를 설정하는 함수
  const openTimeModal = (seconds: any) => {
    setInitialSeconds(seconds);
    setIsTimeModalOpen(true);
  };

  const toggleModal = (modalId: any) => {
    setModalOpenStates((prev: any) => ({
      ...prev,
      [modalId]: !prev[modalId],
    }));
  };

  const closeModal = (modalId: any) => {
    setModalOpenStates((prev: any) => ({
      ...prev,
      [modalId]: false,
    }));
  };

  // 모달에서 시간을 설정하고 모달을 닫는 함수
  const handleTimeConfirm = (seconds: any) => {
    if (selectedTimeType && selectedVideoIndex !== null) {
      updateVideoProperty(
        selectedTimeType,
        selectedVideoIndex,
        seconds.toString()
      );
    }
    setIsTimeModalOpen(false);
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setSelectedProgramIndex(null);
    console.log("현재 수정모드 상태", isEditMode);
  };

  function handleHeaderButtonClick(page: string) {
    setActivePage(page);
  }

  function generateNumberArray(begin: number, end: number, addStr: string) {
    let array = [];
    for (let i = begin; i <= end; i++) {
      array.push({
        value: (i < 10 ? "0" : "") + i,
        label: (i < 10 ? "0" : "") + i + addStr,
      });
    }
    return array;
  }

  const TimeInputModal = ({
    isOpen,
    onRequestClose,
    onConfirm,
    initialSeconds,
  }: any) => {
    const [seconds, setSeconds] = useState(initialSeconds.toString());

    const handleSubmit = () => {
      onConfirm(parseInt(seconds, 10));
      onRequestClose();
    };

    return (
      <StyledModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Time Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: "99",
          },
        }}
      >
        <ModalTitle>Time Setup</ModalTitle>
        <StyledInputContainer>
          <StyledInput
            type="tel"
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            placeholder="초 단위로 입력"
          />
        </StyledInputContainer>
        <BtnContainer>
          <StyledButton onClick={handleSubmit} style={{ marginRight: "15px" }}>
            OK
          </StyledButton>
          <StyledButton onClick={onRequestClose}>Cancel</StyledButton>
        </BtnContainer>
      </StyledModal>
    );
  };

  const renderVideoTimeSettings = (
    video: ItemType,
    index: number,
    type: any
  ) => {
    const modalId = `${type}${video.seq}`;
    return (
      <TimeWrap>
        <TimeTitle
          className="fontRed"
          onClick={() => {
            setSelectedVideoIndex(index);
            setSelectedTimeType("ex_time");
            openTimeModal(parseInt(video.ex_time));
            toggleModal(modalId);
          }}
        >
          <span className="videoTitle">EXERCISE</span>
          <div className="myTimeTxt">
            {MyUtil._secondToTimeFormat(parseInt(video.ex_time))}
          </div>
        </TimeTitle>
        <TimeTitle
          className="fontGreen"
          onClick={() => {
            setSelectedVideoIndex(index);
            setSelectedTimeType("wait_time");
            openTimeModal(parseInt(video.wait_time));
            toggleModal(modalId);
          }}
        >
          <span className="videoTitle">REST</span>
          <div className="myTimeTxt">
            {MyUtil._secondToTimeFormat(parseInt(video.wait_time))}
          </div>
        </TimeTitle>
        <TimeInputModal
          // isOpen={isTimeModalOpen}
          // onRequestClose={() => setIsTimeModalOpen(false)}
          isOpen={modalOpenStates[modalId] || false}
          onRequestClose={() => closeModal(modalId)}
          onConfirm={handleTimeConfirm}
          initialSeconds={initialSeconds}
        />
      </TimeWrap>
    );
  };

  useEffect(() => {
    m_app_pro_m_dt();
  }, [MLoginInfo.MLoginInfo, history, dispatch, allActions]);

  const m_app_pro_m_dt = useCallback(async () => {
    try {
      const result = await ServerApi.m_app_pro_m_dt({ pro_no: pro_no });
      if (result.rsp_code === "100") {
        setBranchName(result.pro_m_nm);
        setChecked(result.auto_yn === "y");

        // 각 리스트에 대한 상태 업데이트 함수 매핑
        const updateStateFunctions: UpdateStateFunctions = {
          list_a: setArrVideoA,
          list_b: setArrVideoB,
          list_c: setArrVideoC,
          list_d: setArrVideoD,
          list_e: setArrVideoE,
          list_f: setArrVideoF,
          list_g: setArrVideoG,
          list_h: setArrVideoH,
        };

        let totalExTime = 0;
        let totalWaitTime = 0;

        // 각 비디오 리스트에 대한 상태 업데이트 및 ex_time, wait_time 계산
        Object.keys(updateStateFunctions).forEach((listName) => {
          const videoList = result[listName] || [];
          const setStateFunction = updateStateFunctions[listName];
          if (setStateFunction) {
            const updatedList = videoList.map((video: ItemType) => {
              // ex_time과 wait_time을 숫자로 변환하여 누적
              const exTime = parseInt(video.ex_time || "0", 10);
              const waitTime = parseInt(video.wait_time || "0", 10);
              totalExTime += exTime;
              totalWaitTime += waitTime;

              return {
                ...video,
                ex_time: video.ex_time || "0",
                wait_time: video.wait_time || "0",
              };
            });
            setStateFunction(updatedList);
          }
        });

        // 전체 프로그램에 대한 ex_time과 wait_time 설정
        setExTime(totalExTime.toString());
        setWaitTime(totalWaitTime.toString());
        setBrTime(result.br_time);
      }
    } catch (error) {
      console.error("Failed to load program data:", error);
    } finally {
      setLoading(false);
    }
  }, [pro_no]);

  // const m_app_pro_m = async () => {
  //     try {
  //         const response = await ServerApi.m_app_pro_m({
  //             center_no: MLoginInfo.MLoginInfo.center_no,
  //         });
  //         // API 응답 확인 및 데이터 처리
  //         if (response && response.rsp_code === '100') {
  //             console.log('시간설정 운동 전체 조회 m_app_pro_m', response);
  //         } else {
  //             console.error('데이터를 가져오는데 실패했습니다.');
  //         }
  //     } catch (error) {
  //         console.error('API 호출 중 오류 발생:', error);
  //     } finally {
  //         setLoading(false);
  //     }
  // }
  //
  // useEffect(() => {
  //     m_app_pro_m();
  // }, []);

  const calculateAllTime = () => {
    let totalTimeInSeconds = 0;
    const videoLists = [
      arrVideoA,
      arrVideoB,
      arrVideoC,
      arrVideoD,
      arrVideoE,
      arrVideoF,
      arrVideoG,
      arrVideoH,
    ];

    videoLists.forEach((list) => {
      if (list.length > 0) {
        // 각 시퀀스의 운동 시간과 휴식 시간을 합산
        list.forEach((video) => {
          totalTimeInSeconds +=
            parseInt(video.ex_time || "0", 10) +
            parseInt(video.wait_time || "0", 10);
        });
        // 각 시퀀스의 운동 사이에 brTime 추가 (운동이 하나 이상일 때만)
        if (list.length > 1) {
          totalTimeInSeconds += (list.length - 1) * parseInt(brTime, 10);
        }
      }
    });

    // 총 시간을 포맷팅하여 반환
    return MyUtil._secondToTimeFormat(totalTimeInSeconds);
  };

  const handleSubmit = async () => {
    const dataArray: any[] = [];

    arrVideoA.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "A",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });

    arrVideoB.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "B",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoC.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "C",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoD.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "D",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoE.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "E",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoF.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "F",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoG.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "G",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });
    arrVideoH.forEach((video, index) => {
      dataArray.push({
        pro_no: pro_no,
        seq: (index + 1).toString(),
        seq_type: "H",
        wait_time: video.wait_time,
        ex_time: video.ex_time,
      });
    });

    // API 호출
    try {
      const response = await ServerApi.m_app_pro_mv_time_set({
        array: dataArray,
        br_time: brTime,
      });

      if (response && response.rsp_code === "100") {
        setFirstLine("시간 설정이 성공적으로");
        setSecondLine("업데이트되었습니다.");
        setModalIsOpen(true);
        console.log("m_app_pro_mv_time_set - response", response);
        console.log("ServerApi.m_app_pro_mv_time_set", dataArray);
      } else {
        // 에러 처리
        console.error("업데이트 실패:", response.msg);
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "./ProgramsItemM",
      state: { pro_no: pro_no },
    });
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
    history.goBack();
  };

  const moveProgram = async (index: any, direction: any) => {
    // 현재 선택된 시퀀스 타입에 따른 배열 가져오기
    const currentArr = {
      A: arrVideoA,
      B: arrVideoB,
      C: arrVideoC,
      D: arrVideoD,
      E: arrVideoE,
      F: arrVideoF,
      G: arrVideoG,
      H: arrVideoH,
    }[seqType];

    // 신규 인덱스 계산
    let newIndex = index + (direction === "up" ? -1 : 1);
    if (newIndex < 0 || newIndex >= currentArr.length) return;

    // 배열 복사 및 요소 위치 변경
    let newArr = [...currentArr];
    [newArr[index], newArr[newIndex]] = [newArr[newIndex], newArr[index]];

    // 상태 업데이트
    const updateFunc = {
      A: setArrVideoA,
      B: setArrVideoB,
      C: setArrVideoC,
      D: setArrVideoD,
      E: setArrVideoE,
      F: setArrVideoF,
      G: setArrVideoG,
      H: setArrVideoH,
    }[seqType];
    updateFunc(newArr);
    setSelectedProgramIndex(newIndex);

    // API 요청 데이터 준비
    const dataToUpdate = {
      pro_no: pro_no,
      seq_type: seqType,
      array: newArr.map((video, index) => ({
        mv_no: video.mv_no,
        seq: (index + 1).toString(),
      })),
    };

    try {
      const response = await ServerApi.m_app_pro_m_u(dataToUpdate);
      if (response && response.rsp_code === "100") {
        console.log("순서 변경 성공:", response);
        console.log("순서 변경 데이터:", dataToUpdate);
      } else {
        console.error("순서 변경 실패:", response.msg);
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  const renderVideos = (type: string): JSX.Element => {
    let videos: ItemType[] = [];
    let setVideos: React.Dispatch<React.SetStateAction<ItemType[]>>;

    switch (type) {
      case "A":
        videos = arrVideoA;
        setVideos = setArrVideoA;
        break;
      case "B":
        videos = arrVideoB;
        setVideos = setArrVideoB;
        break;
      case "C":
        videos = arrVideoC;
        setVideos = setArrVideoC;
        break;
      case "D":
        videos = arrVideoD;
        setVideos = setArrVideoD;
        break;
      case "E":
        videos = arrVideoE;
        setVideos = setArrVideoE;
        break;
      case "F":
        videos = arrVideoF;
        setVideos = setArrVideoF;
        break;
      case "G":
        videos = arrVideoG;
        setVideos = setArrVideoG;
        break;
      case "H":
        videos = arrVideoH;
        setVideos = setArrVideoH;
        break;
      default:
        videos = [];
        setVideos = () => {};
    }

    return (
      <>
        {videos.map((video, index) => (
          <VideoContainer key={`${type}-${video.mv_no}-${index}`}>
            <VideoWrapper>
              <VideoBox>
                {!videoPlayStates[`${seqType}${video.seq}`] ? (
                  <VideoImg
                    src={video.mv_rep_img}
                    alt="thumbnail"
                    onClick={() => handleVideoClick(seqType, video.seq || "")}
                  />
                ) : (
                  <video
                    src={video.mv_url}
                    loop
                    autoPlay
                    muted
                    playsInline
                    onClick={() => handleVideoClick(seqType, video.seq || "")}
                  />
                )}
              </VideoBox>
              <VideoTitleWrap>
                <VideoTitle>{video.mv_nm}</VideoTitle>
              </VideoTitleWrap>
            </VideoWrapper>
            {renderVideoTimeSettings(video, index, type)}
            {isEditMode && (
              <ButtonContainer>
                <StyledButton onClick={() => moveProgram(index, "up")}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </StyledButton>
                <StyledButton onClick={() => moveProgram(index, "down")}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </StyledButton>
              </ButtonContainer>
            )}
          </VideoContainer>
        ))}
      </>
    );
  };

  const updateVideoProperty = (
    type: "ex_time" | "wait_time",
    index: number,
    timeInSeconds: string
  ) => {
    const setVideos = {
      A: setArrVideoA,
      B: setArrVideoB,
      C: setArrVideoC,
      D: setArrVideoD,
      E: setArrVideoE,
      F: setArrVideoF,
      G: setArrVideoG,
      H: setArrVideoH,
    }[seqType];

    // 선택된 비디오 리스트의 상태 업데이트
    setVideos((prev) => {
      const updatedVideos = [...prev];
      const updatedVideo = { ...updatedVideos[index], [type]: timeInSeconds };
      updatedVideos[index] = updatedVideo;
      return updatedVideos;
    });
  };

  // 비디오 클릭 핸들러
  const handleVideoClick = (seqType: string, seq: string) => {
    const key = `${seqType}${seq}`;
    setVideoPlayStates((prevStates: any) => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  return (
    <>
      <Overlay isModalOpen={isVideoIuModalOpen} />
      <ProgramsVideoContainer isModalOpen={isVideoIuModalOpen}>
        <MobileHeader
          textOne={"EXERCISE SETUP"}
          // textTwo={"DAILY EXERCISE SETUP"}
          onTextOneClick={() => handleHeaderButtonClick("exercise")}
          onTextTwoClick={() => handleHeaderButtonClick("daily")}
          initialActive={"textOne"}
        />
        {activePage === "exercise" && (
          <>
            <div className="containerWrap">
              <SubmitContainer>
                <UpdownButton onClick={toggleEditMode}>
                  <LuArrowUpDown />
                </UpdownButton>
                <SubmitButton onClick={handleSubmit}>
                  <FaCircleCheck />
                </SubmitButton>
                <CancelButton onClick={handleCancel}>
                  <FaCircleXmark />
                </CancelButton>
              </SubmitContainer>
            </div>

            <div className="videoTitleWrap">
              <VideoTitleFirst>
                <span className="videoTitle">TITLE</span>
                <TitleInput
                  type="text"
                  required
                  placeholder="Please enter"
                  value={branchName}
                  onChange={(evt) => setBranchName(evt.target.value)}
                ></TitleInput>
              </VideoTitleFirst>
            </div>
            <VideoTitleContentsWrap>
              <VideoTitleContents>
                <span className="videoTitle" style={{ fontWeight: "300" }}>
                  TOTAL TIME
                </span>
                <div className="myTimeTxt">{calculateAllTime()}</div>
              </VideoTitleContents>
              <VideoTitleContents
                onClick={() => {
                  setModalSelectValue(MyUtil._secondToTimeArray(brTime));
                  setModalType("b");
                  setIsModalSelect(true);
                }}
                style={{ border: "none" }}
              >
                <span className="videoTitle">BREAK TIME</span>
                <div className="myTimeTxt">
                  {MyUtil._secondToTimeFormat(parseInt(brTime))}
                </div>
              </VideoTitleContents>
            </VideoTitleContentsWrap>

            <TabContainer>
              {(["A", "B", "C", "D", "E", "F", "G", "H"] as const).map(
                (type) => (
                  <TabButton
                    key={type}
                    active={seqType === type}
                    onClick={() => setSeqType(type)}
                  >
                    <span className="packagesItemTabTxt">{type}</span>
                  </TabButton>
                )
              )}
            </TabContainer>

            <VideoContents>
              <VideoListContainer>{renderVideos(seqType)}</VideoListContainer>
              <Picker
                confirmText={"OK"}
                cancelText={"CANCEL"}
                columns={[
                  generateNumberArray(0, 12, "시"),
                  generateNumberArray(0, 59, "분"),
                  generateNumberArray(0, 59, "초"),
                ]}
                visible={isModalSelect}
                onClose={() => {
                  setIsModalSelect(false);
                }}
                value={modalSelectValue}
                onConfirm={(timeValue: any) => {
                  if (!MyUtil._isNull(timeValue)) {
                    let formatSec = 0;
                    formatSec = formatSec + parseInt(timeValue[0]) * 3600;
                    formatSec = formatSec + parseInt(timeValue[1]) * 60;
                    formatSec = formatSec + parseInt(timeValue[2]);

                    if (selectedTimeType && selectedVideoIndex !== null) {
                      updateVideoProperty(
                        selectedTimeType,
                        selectedVideoIndex,
                        formatSec.toString()
                      );
                    } else if (modalType === "b") {
                      setBrTime(formatSec.toString());
                    }
                  }
                }}
              />
            </VideoContents>
            <div className={"buttonContainer"}>
              <AccountButton
                buttonUse={buttonToShow}
                mod_yn={MLoginInfo.MLoginInfo.mod_yn}
                pro_no={pro_no}
              />
            </div>
          </>
        )}

        {activePage === "daily" && <></>}
      </ProgramsVideoContainer>
      <AlertModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "99",
          },
        }}
      >
        <ModalText>{firstLine}</ModalText>
        <ModalText>{secondLine}</ModalText>
        <button
          onClick={closeAlertModal}
          style={{ marginTop: "20px", color: "#fff" }}
        >
          OK
        </button>
      </AlertModal>
    </>
  );
};

export default TimeSetup;
