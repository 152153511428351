import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../component/redux/rootReducer";
import AccountButton from "./Compornent/AccountButton";
import MobileHeader from "./Compornent/MobileHeader";
import * as ServerApi from "../constants/ServerApiM";
import { Video, Folder, InFolder } from "./Interface/Interface";
import ExerciseSetup from "./Compornent/ExerciseSetup";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { toggleCalendarModal } from "../component/redux/allActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import AlertModal from "react-modal";
import ProgramsItemContents from "./Compornent/ProgramsItemContents";
import * as MyUtil from "../constants/MyUtil";
import { useSwipeable } from "react-swipeable";
import Sprintf from "sprintf-js";
import VideoListModal from "./Compornent/VideoListModal";
import trash from "../img/trash.png";
import { IoCloseCircle, IoTrashOutline } from "react-icons/io5";
import { FaRegFolder, FaRegTrashCan } from "react-icons/fa6";
import ImportProgramsModal from "./Compornent/importProgramsModal";
import { IoIosCloseCircle } from "react-icons/io";
import Calendar from "react-calendar";
import moment from "moment";
import { MdContentCopy } from "react-icons/md";
import NewSaveButton from "./Compornent/NewSaveButton";

const sprintf = Sprintf.sprintf;

const GlobalStyle = createGlobalStyle`
    .react-calendar__navigation button {
        color: #000;
        font-weight: bold;
    }

    .react-calendar__navigation button:hover {
        background-color: #cccccc;
    }

    .react-calendar__navigation__prev-button {
        font-size: 1.2rem;
        position: absolute;
        top: -4px;
        left: 100px;
        transform: translate(-50%, 50%);
    }

    .react-calendar__navigation__next-button {
        font-size: 1.2rem;
        position: absolute;
        top: -4px;
        left: 250px;
        transform: translate(-50%, 50%);
    }
`;

const ProgramContainer = styled.div<{ background: string | null | undefined }>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  height: 100vh;
  padding-top: 75px;
  max-width: 100%;
  background-color: ${({ background }) =>
    background === "black" ? "#000000" : "#fff"};

  .containerWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin: 0 auto;
    padding-bottom: 50px;
  }
`;

const CalendarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const CalendarWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;

  &.visible {
    display: block;
    z-index: 999;
  }

  .react-calendar {
    border-radius: 15px;
    padding-bottom: 20px;
    position: relative;
  }

  .react-calendar__navigation {
    background: none;
    color: #09348a;
  }

  .react-calendar__navigation button:disabled {
    background: none;
    color: #000;
  }

  .react-calendar__tile--active {
    background-color: #09348a;
    border-radius: 10px;
  }

  .react-calendar__tile--now {
    background-color: rgba(9, 52, 138, 0.2);
    border-radius: 10px;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #09348a;
  }

  .react-calendar__month-view__weekdays {
    margin-top: 30px;
    color: #09348a;
  }

  .react-calendar__month-view__days {
    margin-bottom: 50px;
  }

  .calendarSaveBtn {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    background: #09348a;
    border: none;
    border-radius: 15px;
    color: #ffffff;
  }
`;

const SelectWrap = styled.div`
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
`;

const Select = styled.select`
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 0.8rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  box-sizing: border-box;
  background: none;
  color: #09348a;
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:active {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }

  &:hover {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }
`;

// MIIT에도 수정예정
const ProgramTiTle = styled.div`
  font-size: 1.3rem;
  font-weight: 800;
  color: #09348a;
`;

const CalendarModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: rgb(248, 249, 250);
`;

const IconButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  margin: 0 12px;
  color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  p {
    color: #ccc;
    font-size: 0.8rem;
  }

  :active {
    color: #09348a;
  }

  .on {
    color: #09348a;
  }
`;

const SearchContainer = styled.div`
  width: calc(100% - 10px);
  padding: 10px 0;
  background-color: #f8f9fa;
  position: absolute;
  top: 57px;
  left: 50%;
  transform: translate(-50%, 50%);
  border: 1px solid #65737e;
  border-radius: 10px;
  margin-top: 5px;
  z-index: 99;
`;

const InputContainer = styled.input`
  width: 90%;
  background: none;
  border: none;
  font-size: 10pt;
  float: left;
  color: #09348a;
  padding-left: 10px;
`;

const InputContainer2 = styled.input`
  width: 90%;
  background: none;
  border: none;
  font-size: 10pt;
  float: left;
  color: #09348a;
  placeholder: center;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #09348a;
`;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledSelectModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledNewFolderModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  border-radius: 15px 15px 0 0;
`;

const ModalText = styled.div`
  text-align: center;
  width: 100%;
  height: 40vh;
  overflow-y: auto;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 0;
  color: #000;
  line-height: 1.5rem;
`;

const ModalSelectTextWrap = styled.div`
  text-align: center;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 1.5rem;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #09348a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 10px;
  }
`;

const ModalSelectTextWrap2 = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  overflow-y: scroll;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 1.5rem;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #09348a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 10px;
  }
`;

const ModalSelectText = styled.div`
  margin: 10px 0;
  padding: 0 15px;
  font-size: 0.8rem;
  color: #000;
`;

const StyledInputContainer = styled.div`
  width: 80%;
  margin: 10px 0 30px 0;
  position: relative;
  border-bottom: 1px solid #09348a;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 75%;
  padding: 10px;
  background: none;
  border: none;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  margin: 30px 0 10px 0;
  background-color: #09348a;
  font-weight: 500;
  color: #fff;
  border: 2px solid #09348a;
  border-radius: 20px;
  cursor: pointer;
`;

const Folders = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  background-color: #09348a;
  border-radius: 10px;
  border: none;
  height: 100px;
  color: #09348a;
`;

// To do MIIT
const FolderName = styled.div`
  width: 100%;
  background-color: #ccc;
  color: #000;
  font-size: 0.7rem;
  padding: 5px;
  font-weight: 600;
`;

const FolderInList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
`;

// To do MIIT
const ListName = styled.div`
  flex: 0 0 43%;
  box-sizing: border-box;
  padding: 5px;
  background: #ededed;
  border-radius: 8px;
  margin: 5px;
  text-align: center;
  font-size: 0.7rem;
`;

const NavIcon = styled.img`
  filter: brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(351%)
    hue-rotate(148deg) brightness(92%) contrast(91%);
  width: 20px;
  height: 20px;

  :active {
    color: #09348a;
  }
`;

const AddBtn = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #09348a;
  background-color: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #09348a;
  font-size: 1.5rem;
`;

const DailyAddBtn = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #09348a;
  background-color: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #09348a;
  font-size: 1.5rem;
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const ButtonWrap2 = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const DailyWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
`;

const DailyContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto !important;
`;

const ProgramsItemM: React.FC = ({ location }: any) => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [videos, setVideos] = useState<Video[]>([]);
  const [exercise, setExercise] = useState<Video[]>([]);
  const [importVideo, setImportVideo] = useState<Video[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const buttonToShow = ["logo", "play", "profile", "setupOn"];
  const history = useHistory();
  const dispatch = useDispatch();
  const isModalVisible = useSelector(
    (state: RootState) => state.calendarModal.isVisible
  );
  const [activePage, setActivePage] = useState("exercise");
  const [background, setBackground] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [newFolderTitle, setNewFolderTitle] = useState("");
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [editingFolder, setEditingFolder] = useState({
    fold_no: "",
    fold_nm: "",
  });
  const [inFolder, setInFolder] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [showSelectDeleteModal, setShowSelectDeleteModal] = useState(false);
  const [showDailyExerciseModal, setShowDailyExerciseModal] = useState(false);

  // Import 모달의 상태
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  // 캘린더 타이틀 선택
  const [isCalendarSelectModal, setIsCalendarSelectModal] = useState(false);
  const customWeekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    console.log("background", background);
  }, [background]);

  // Import 모달을 토글하는 함수
  const toggleImportModal = () => {
    setIsImportModalOpen(!isImportModalOpen);
  };

  // 프로그램 가져오기 성공 시 처리할 함수
  const handleImportSuccess = async (selectedPrograms: any) => {
    console.log("Selected program:", selectedPrograms);

    const updatedPrograms = [...videos, ...selectedPrograms];
    setVideos(updatedPrograms);

    toggleImportModal(); // 모달 닫기
    fetchVideos();
  };

  // Daily
  const [arrCalData, setArrCalData] = useState<any>([]);
  const [viewWeekNo, setViewWeekNo] = useState<any>({
    weekNo: -1,
    startNo: 0,
    endNo: 0,
    maxWeekNo: 0,
  }); // #미니캘린더용 @뷰의 주차 @한주의 시작일 @한주의 끝일 @달의 최대주차
  const [selectDay, setSelectDay] = useState<any>({ day: null, fullDay: null });
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [selectedVideos, setSelectedVideos] = useState<
    {
      url: string;
      title: string;
      mv_no: string;
      mv_rep_img: string;
      mv_nm: string;
    }[]
  >([]);
  const [title, setTitle] = useState();
  const [dailyVideo, setDailyVideos] = useState<Video[]>([]);

  const fetchDailyVideos = async (selectedDay: string) => {
    const centerNo = MLoginInfo?.MLoginInfo.center_no;
    console.log("centerNo", centerNo);
    if (!centerNo) {
      console.error("센터 정보가 없습니다.");
      return;
    }

    try {
      const response = await ServerApi.m_app_center_pro_m({
        center_no: MLoginInfo.MLoginInfo.center_no,
        c_day: selectedDay,
      });
      // API 응답 확인 및 데이터 처리
      if (response && response.rsp_code === "100") {
        // API 응답으로 받은 비디오 목록 저장
        setDailyVideos(response.array);
        const allVideos = response.array.flatMap((video: any) =>
          video.in_array.map((v: any) => ({
            url: v.mv_url,
            title: v.mv_nm,
            mv_no: v.mv_no,
            thumbnailUrl: v.mv_rep_img,
          }))
        );
        setSelectedVideos(allVideos);
        setTitle(response.data.title);
      } else {
        console.error("데이터를 가져오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const today = new Date().toISOString().split("T")[0];
    if (isMounted) fetchDailyVideos(today);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleVideoSelect = useCallback((video: any) => {
    if (video && video.in_array) {
      const selected = video.in_array.map((v: any) => ({
        url: v.mv_url,
        mv_no: v.mv_no,
        mv_rep_img: v.mv_rep_img,
        mv_nm: v.mv_nm,
        thumbnailUrl: v.mv_rep_img,
      }));
      setSelectedVideos(selected);
      console.log("선택된 비디오의 데이터들", video);
      console.log("선택된 pro_no", video.pro_no);
    }
  }, []);

  const renderVideos = () => {
    return dailyVideo.map((video, index) => (
      <ProgramsItemContents
        key={index}
        onSelectVideo={() => handleVideoSelect(video)}
        onListClick={handleListClick}
        fetchVideos={fetchDailyVideos}
        videoData={{
          url: video.in_array[0].mv_url,
          title: video.pro_m_nm,
          in_array: video.in_array,
          mv_nm: video.in_array[0].mv_nm,
          mv_rep_img: video.in_array[0].mv_rep_img,
          pro_no: video.pro_no,
        }}
        videos={dailyVideo}
        setVideos={setDailyVideos}
        selectedDate={selectDay.fullDay}
        DailySetup={true}
        centerProNo={video.center_pro_no}
        fetchAndInitCalendar={fetchAndInitCalendar}
      />
    ));
  };

  useEffect(() => {
    console.log("videos", dailyVideo);
  }, []);

  useEffect(() => {
    const today = new Date();
    initCalendar(today, true, true);
  }, [MLoginInfo]);

  const fetchAndInitCalendar = async () => {
    await fetchVideos();
    const today = new Date();
    initCalendar(today, true, true);
  };

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const initCalendar = useCallback(
    async (getDate, isWeekStart, isReset) => {
      const calData = [];
      const firstDate = new Date(getDate.getFullYear(), getDate.getMonth(), 1);
      const lastDate = new Date(
        getDate.getFullYear(),
        getDate.getMonth() + 1,
        0
      );
      const week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

      // 첫 달 시작 요일 무시하고 1일부터 시작
      for (let dayCnt = 1; dayCnt <= lastDate.getDate(); dayCnt++) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          dayCnt
        );
        const dayOfWeek = week[new Date(fullDay).getDay()];
        calData.push({
          day: dayCnt.toString(),
          fullDay: fullDay,
          dayOfWeekKor: dayOfWeek,
        });
        MyUtil._consoleLog(
          "day : " +
            dayCnt +
            " / fullDay : " +
            fullDay +
            " / dayOfWeekKor : " +
            dayOfWeek
        );
      }

      const maxWeekNo = Math.ceil(calData.length / 7);

      // 최초 세팅 또는 뷰 변경 시
      if (viewWeekNo.weekNo === -1 || isReset) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          getDate.getDate()
        );
        SelectCalDay(
          {
            day: getDate.getDate(),
            fullDay: fullDay,
          },
          getDate.getDate() - 1,
          { maxWeekNo }
        );
      } else {
        if (isWeekStart) {
          setViewWeekNo({ weekNo: 1, startNo: 0, endNo: 6, maxWeekNo });
        } else {
          const weekNo = maxWeekNo;
          const startNo = (weekNo - 1) * 7;
          const endNo = startNo + 6;
          setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo });
        }
      }

      setViewDate(getDate);
      setArrCalData(calData);
    },
    [viewWeekNo]
  );

  const SelectCalDay = useCallback(async (item, idx, getViewWeelNo) => {
    console.log("SelectCalDay 호출됨, 선택된 날짜:", item.fullDay); // 로그 추가
    const weekNo = Math.floor(idx / 7) + 1;
    const startNo = (weekNo - 1) * 7;
    const endNo = startNo + 6;

    setViewWeekNo({
      weekNo,
      startNo,
      endNo,
      maxWeekNo: getViewWeelNo.maxWeekNo,
    });
    setSelectDay({ day: item.day, fullDay: item.fullDay });

    fetchDailyVideos(item.fullDay); // 선택된 날짜로 fetchVideos 호출
  }, []);

  const WeekPrev = useCallback(
    async (getViewDate) => {
      const weekNo = viewWeekNo.weekNo - 1;
      if (weekNo <= 0) {
        getViewDate.setMonth(getViewDate.getMonth() - 1);
        const newDate = new Date(getViewDate);
        setViewDate(newDate);
        initCalendar(newDate, false, false);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo]
  );

  const WeekNext = useCallback(
    async (getViewDate) => {
      const weekNo = viewWeekNo.weekNo + 1;
      MyUtil._consoleLog(
        "weekNo : " +
          weekNo +
          " / viewWeekNo.maxWeekNo : " +
          viewWeekNo.maxWeekNo
      );

      if (weekNo > viewWeekNo.maxWeekNo) {
        getViewDate.setMonth(getViewDate.getMonth() + 1);
        const newDate = new Date(getViewDate);
        setViewDate(newDate);
        initCalendar(newDate, true, false);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo]
  );

  const renderDayItem = (idx: number, item: any) => {
    return MyUtil._isNull(item.day) ? (
      <></>
    ) : (
      <div
        key={idx}
        className={`dayProgramCalBox ${
          selectDay.fullDay === item.fullDay ? "dayProgramCalBoxOn" : ""
        }`}
        onClick={() => {
          console.log("날짜 클릭됨:", item.fullDay);
          SelectCalDay(item, idx, viewWeekNo);
        }}
      >
        <span className="calBoxWeek">{item.dayOfWeekKor}</span>
        <span className="calBoxDay">{item.day}</span>
      </div>
    );
  };

  const handleYearChange = (event: any) => {
    const newYear = event.target.value;
    const newDate = new Date(viewDate);
    newDate.setFullYear(newYear);
    setViewDate(newDate);
    initCalendar(newDate, true, true); // 년도를 변경할 때 달력도 업데이트
  };

  const handleMonthChange = (event: any) => {
    const newMonth = event.target.value;
    const newDate = new Date(viewDate);
    newDate.setMonth(newMonth);
    setViewDate(newDate);
    initCalendar(newDate, true, true); // 월을 변경할 때 달력도 업데이트
  };

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const handlers = useSwipeable({
    onSwipedLeft: () => WeekNext(viewDate),
    onSwipedRight: () => WeekPrev(viewDate),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const [isVideoListModalOpen, setIsVideoListModalOpen] = useState(false);

  const handleListClick = () => {
    setIsVideoListModalOpen(true);
  };

  // 캘린더 모달을 닫는 함수
  const closeCalendarModal = () => {
    setIsCalendarSelectModal(false);
  };

  // 날짜 선택 핸들러
  const onDateChange = (value: Date) => {
    const selectedDate = moment(value).format("YYYY-MM-DD");
    setSelectDay({ day: value.getDate(), fullDay: selectedDate });
  };

  // 'SAVE' 버튼 클릭 핸들러
  const onSaveClick = () => {
    console.log("selectDay", selectDay);
    if (selectDay.fullDay) {
      const selectedDate = new Date(selectDay.fullDay);
      initCalendar(selectedDate, true, true);
      fetchDailyVideos(selectDay.fullDay);
      setIsCalendarSelectModal(false); // 달력 모달 닫기
    }
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  // Daily End

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchToggle(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchRef]);

  // 캘린더 모달을 표시하거나 숨기는 함수
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    dispatch(toggleCalendarModal());
  };

  // 로그인 여부 판단
  useEffect(() => {
    if (!MLoginInfo.MLoginInfo) {
      history.push("/MLogin");
    }
  }, [MLoginInfo.MLoginInfo, history]);

  // 비디오 불러오기
  const fetchVideos = async () => {
    try {
      const response = await ServerApi.m_app_pro_m({
        center_no: MLoginInfo.MLoginInfo.center_no,
      });
      console.log("ProgramsItemM.response", response);
      if (response && response.rsp_code === "100") {
        setVideos(response.array);
        setExercise(response.array);
        setFolders(response.array2);
      } else {
        console.error("데이터를 가져오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  const fetchImport = async () => {
    const data = {
      center_no: MLoginInfo.MLoginInfo.center_no,
      fold_yn: "n",
    };
    try {
      const response = await ServerApi.m_app_pro_m(data);
      console.log("fetchImport response", response, data);
      if (response && response.rsp_code === "100") {
        setImportVideo(response.array);
      } else {
        console.error("데이터를 가져오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
    fetchImport();
  }, []);

  // 폴더 생성 핸들러
  const m_mng_pro_fold_i = async () => {
    setDnStatus("loading");
    try {
      const data = {
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_fold_no: "",
        fold_nm: newFolderTitle,
      };
      const response = await ServerApi.m_mng_pro_fold_i(data);
      if (response.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            console.log("폴더 생성 성공");
            setShowCreateFolderModal(false);
            fetchVideos();
            setDnStatus("ready");
          }, 300);
        }, 500);
      } else {
        setDnStatus("error");
        console.log("폴더 생성 실패");
        setTimeout(() => {
          setDnStatus("ready");
        }, 1000);
      }
    } catch (error) {}
  };

  // 폴더 수정 핸들러
  const handleEditFolder = async () => {
    setDnStatus("loading");
    try {
      const data = {
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_fold_no: editingFolder.fold_no,
        fold_nm: newFolderTitle, // 새로운 폴더 이름
      };
      const response = await ServerApi.m_mng_pro_fold_i(data);
      if (response.rsp_code === "100") {
        console.log("폴더 수정 성공");
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            setShowEditFolderModal(false);
            setNewFolderTitle("");
            setDnStatus("ready");
            fetchVideos();
          }, 500);
        }, 1000);
      } else {
        console.log("폴더 수정 실패");
        setDnStatus("error");
        setTimeout(() => {
          setDnStatus("ready");
        }, 1000);
      }
    } catch (error) {
      setDnStatus("error");
      console.error("폴더 수정 중 오류 발생:", error);
      setTimeout(() => {
        setDnStatus("ready");
      }, 1000);
    }
  };

  // 폴더 진입 핸들러
  const handleEnterFolder = async (folder: InFolder) => {
    console.log(`${folder.fold_nm} 진입`);
    setInFolder(true);
    setEditingFolder(folder);

    try {
      // 폴더 내용 조회
      const response = await ServerApi.m_mng_pro_fold_dt({
        fold_no: folder.fold_no,
      });
      if (response && response.rsp_code === "100") {
        if (response.array && response.array[0] && response.array[0].pro) {
          console.log("폴더 내용 조회 성공:", response.array[0].pro);
          setExercise(response.array[0].pro);
        } else {
          console.log("폴더 내용이 비어있습니다.");
          setExercise([]);
        }
      } else {
        console.error("폴더 내용 조회 실패:", response.msg);
        setExercise([]);
      }
    } catch (error) {
      console.error("폴더 내용 조회 중 오류 발생:", error);
      setExercise([]);
    }
  };

  // 폴더 이탈 핸들러
  const handleLeaveFolder = async () => {
    setExercise(videos);
    setInFolder(false);
    fetchVideos();
  };

  // 운동선택 모달
  const renderExerciseModal = () => {
    if (!showExerciseModal) return null;

    return (
      <StyledSelectModal
        isOpen={showExerciseModal}
        onRequestClose={() => setShowExerciseModal(false)}
        contentLabel="Select Exercise"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: "999" },
        }}
      >
        <ModalTitle>SELECT EXERCISE</ModalTitle>

        <ModalSelectTextWrap>
          {videos.map((video: Video) => (
            <ModalSelectText
              key={video.pro_no}
              onClick={() => video.pro_no && handleSelectExercise(video.pro_no)}
            >
              {video.pro_m_nm}
            </ModalSelectText>
          ))}
        </ModalSelectTextWrap>

        <ButtonWrap>
          <NewSaveButton
            text={"CLOSE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={() => setShowExerciseModal(false)}
          />
        </ButtonWrap>
      </StyledSelectModal>
    );
  };

  // 폴더 삽입 핸들러
  const handleSelectExercise = async (selectedProNo: string) => {
    try {
      const data = {
        fold_no: editingFolder.fold_no, // 현재 진입한 폴더 번호
        array: [{ pro_no: selectedProNo }],
      };
      const response = await ServerApi.m_mng_pro_fold_exec_i(data);
      if (response.rsp_code === "100") {
        console.log("운동 삽입 성공");
        fetchFolderContents(editingFolder.fold_no);
      } else {
        console.error("운동 삽입 실패");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
    setShowExerciseModal(false); // 모달창 닫기
  };

  // 폴더 삽입 후 조회 핸들러
  const fetchFolderContents = async (fold_no: string) => {
    try {
      const response = await ServerApi.m_mng_pro_fold_dt({ fold_no });
      if (response && response.rsp_code === "100") {
        if (response.array && response.array[0] && response.array[0].pro) {
          console.log("폴더 내용 조회 성공:", response.array[0].pro);
          setExercise(response.array[0].pro);
        } else {
          console.log("폴더 내용이 비어있습니다.");
          setExercise([]);
        }
      } else {
        console.error("폴더 내용 조회 실패:", response.msg);
        setExercise([]);
      }
    } catch (error) {
      console.error("폴더 내용 조회 중 오류 발생:", error);
      setExercise([]);
    }
  };

  // 복사 핸들러
  const handleCopyExerciseSetup = async (proNo: string) => {
    if (!proNo) {
      console.error("프로그램 번호가 없습니다.");
      return;
    }

    const center_no = MLoginInfo.MLoginInfo.center_no;

    try {
      const response = await ServerApi.m_app_pro_m_copy_i({
        pro_no: proNo,
        center_no: center_no,
      });
      if (response.rsp_code === "100") {
        if (!inFolder) {
          fetchVideos();
        } else {
          fetchFolderContents(editingFolder.fold_no);
        }
      } else {
        console.error("복사 실패:", response.msg);
      }
    } catch (error) {
      console.error("복사 중 오류 발생:", error);
    }
  };

  // 삭제 핸들러
  const handleDeleteExerciseSetup = async (proNo: string) => {
    try {
      const response = await ServerApi.m_app_pro_m_d({ pro_no: proNo });
      if (response.rsp_code === "100") {
        console.log("삭제 성공");
        if (!inFolder) {
          fetchVideos();
        } else {
          fetchFolderContents(editingFolder.fold_no);
        }
      } else {
        console.error("삭제 실패:", response.msg);
      }
    } catch (error) {
      console.error("삭제 중 오류 발생:", error);
    }
  };

  // 폴더 삭제 핸들러
  const handleFolderDelete = async () => {
    try {
      const response = await ServerApi.m_mng_pro_fold_d({
        fold_no: editingFolder.fold_no,
      });
      if (response.rsp_code === "100") {
        console.log("폴더 삭제 성공");
        setInFolder(false);
        fetchVideos();
      } else {
        console.error("폴더 삭제 실패:", response.msg);
      }
    } catch (error) {
      console.error("삭제 중 오류 발생:", error);
    }
  };

  // 폴더 삭제 할거야?
  const handleFolderDeleteConfirm = async () => {
    await handleFolderDelete();
    setShowDeleteFolderModal(false);
  };

  // '추가' 버튼 클릭 시 ProgramsVideoM2 페이지로 이동
  const handleAddButtonClick = () => {
    history.push("/ProgramsVideoM2");
  };

  // 수정모드 핸들러
  const handleSettingExerciseSetup = (proNo: string) => {
    history.push({
      pathname: "/ProgramsVideoM2",
      state: { pro_no: proNo },
    });
    console.log("proNo", proNo);
  };

  // 검색창 토글 핸들러
  const SearchToggle = () => {
    setSearchToggle(!searchToggle);
    setSearchTerm("");
    console.log(searchToggle);
  };

  // 검색 핸들러
  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
    // console.log("handleSearchChange", handleSearchChange);
  };

  const filteredVideos = exercise.filter((video) =>
    video.pro_m_nm?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
  };

  // 항목 선택/선택 해제 처리 함수
  const handleSelectItem = (proNo: string | undefined) => {
    const newSelectedItems = new Set(selectedItems);
    if (proNo) {
      if (newSelectedItems.has(proNo)) {
        newSelectedItems.delete(proNo);
        console.log(`Item deselected: ${proNo}`);
      } else {
        newSelectedItems.add(proNo);
        console.log(`Item selected: ${proNo}`);
      }
      setSelectedItems(newSelectedItems);
    }
  };

  // 전체선택 핸들러
  const selectAllItems = () => {
    if (inFolder) {
      // 폴더 내부에서의 전체 선택
      const newSelectedItems = new Set(selectedItems);
      let allSelected = true;

      exercise.forEach((video) => {
        if (!newSelectedItems.has(video.pro_no)) {
          newSelectedItems.add(video.pro_no);
          allSelected = false;
        }
      });

      if (allSelected) {
        // 모든 항목이 이미 선택된 경우, 선택 해제
        exercise.forEach((video) => {
          newSelectedItems.delete(video.pro_no);
        });
      }

      setSelectedItems(newSelectedItems);
    } else {
      // 폴더 밖에서의 전체 선택
      if (selectedItems.size === videos.length) {
        setSelectedItems(new Set());
      } else {
        const newSelectedItems = new Set(videos.map((video) => video.pro_no));
        setSelectedItems(newSelectedItems);
      }
    }
  };

  // 운동선택 모달 핸들러
  const handleShowExerciseModal = () => {
    setShowExerciseModal(true);
  };

  // 데일리 엑서사이즈 셋업 + 버튼
  const handleSelectProgramForDay = async (proNo: string) => {
    const data = {
      center_no: MLoginInfo.MLoginInfo.center_no,
      c_day: selectDay.fullDay, // 선택된 날짜
      pro_no: proNo,
    };
    try {
      const response = await ServerApi.m_app_pro_center_i(data);
      console.log(
        "데일리에 데이터 넣는거 리스폰스",
        response,
        "데일리에 데이터 넣는거 데이타",
        data
      );

      if (response.rsp_code === "100") {
        console.log("프로그램 성공적으로 추가됨");
        setShowDailyExerciseModal(false);
        setSearchTerm("");
        fetchDailyVideos(selectDay.fullDay);
      } else {
        console.error("프로그램 추가 실패:", response.msg);
      }
    } catch (error) {
      console.error("프로그램 추가 중 오류 발생:", error);
    }
  };

  // 데일리 엑서사이즈 셋업 + 버튼누르면 나오는 모달
  const renderDailyExerciseModal = () => {
    if (!showDailyExerciseModal) return null;

    const filteredImportVideo = importVideo.filter((video) =>
      video.pro_m_nm?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <StyledSelectModal
        isOpen={showDailyExerciseModal}
        onRequestClose={() => {
          setShowDailyExerciseModal(false);
          setSearchTerm("");
        }}
        contentLabel="Select Exercise"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: "999" },
        }}
      >
        <ModalTitle>EXERCISE LIST</ModalTitle>

        <InputContainer2
          type="text"
          onChange={handleSearchChange}
          value={searchTerm}
          placeholder="Search exercises"
        />
        <ModalSelectTextWrap2>
          {filteredImportVideo.map((video: Video) => (
            <ModalSelectText
              key={video.pro_no}
              onClick={() => handleSelectProgramForDay(video.pro_no || "")}
            >
              {video.pro_m_nm}
            </ModalSelectText>
          ))}
        </ModalSelectTextWrap2>
        <ButtonWrap2>
          <NewSaveButton
            text={"CLOSE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={() => {
              setShowDailyExerciseModal(false);
              setSearchTerm("");
            }}
          />
        </ButtonWrap2>
      </StyledSelectModal>
    );
  };

  // 선택된 항목 삭제 핸들러
  const handleDeleteSelectedItems = async () => {
    if (selectedItems.size === 0) {
      console.log("No items selected for deletion.");
      setShowSelectDeleteModal(false);
      return;
    }
    setShowSelectDeleteModal(false);
    const foldersToDelete: any[] = [];
    const exercisesToDelete: any[] = [];

    selectedItems.forEach((itemId) => {
      if (folders.some((folder) => folder.fold_no === itemId)) {
        foldersToDelete.push({ fold_no: itemId });
      } else if (exercise.some((ex) => ex.pro_no === itemId)) {
        exercisesToDelete.push({ pro_no: itemId });
      }
    });

    // 삭제 요청
    try {
      const response = await ServerApi.m_mng_pro_m_all_d({
        arr2: exercisesToDelete,
        arr1: foldersToDelete,
      });

      if (response.rsp_code === "100") {
        console.log("삭제 성공", response);
        setFolders((prevFolders) =>
          prevFolders.filter(
            (folder) =>
              !foldersToDelete.some((f) => f.fold_no === folder.fold_no)
          )
        );
        setExercise((prevExercises) =>
          prevExercises.filter(
            (ex) => !exercisesToDelete.some((e) => e.pro_no === ex.pro_no)
          )
        );

        // 선택 상태 초기화
        setSelectedItems(new Set());
        fetchVideos();
      } else {
        console.error("Failed to delete selected items:", response.msg);
      }
    } catch (error) {
      console.error("Error deleting selected items:", error);
    }
  };

  // 선택된 항목 복사 핸들러
  const handleCopySelectedItems = async () => {
    const exercisesToCopy: any[] = [];

    selectedItems.forEach((itemId) => {
      if (exercise.some((ex) => ex.pro_no === itemId)) {
        exercisesToCopy.push({ pro_no: itemId });
      }
    });

    try {
      const response = await ServerApi.m_mng_pro_m_select_copy_i({
        array: exercisesToCopy,
        center_no: MLoginInfo.MLoginInfo.center_no,
      });
      console.log("response select copy", response);
      console.log("response selectedItems", selectedItems);
      console.log("response exercisesToCopy", exercisesToCopy);

      if (response.rsp_code === "100") {
        setExercise((prevExercises) =>
          prevExercises.filter(
            (ex) => !exercisesToCopy.some((e) => e.pro_no === ex.pro_no)
          )
        );
        fetchVideos();
      } else {
        console.error("Failed to Copy selected items:", response.msg);
      }
    } catch (error) {
      console.error("Error Copy selected items:", error);
    }
  };

  useEffect(() => {
    if (activePage === "daily") {
      fetchAndInitCalendar();
    }
  }, [activePage]);

  const handleTextOneClick = () => {
    setActivePage("exercise");
  };

  const handleTextTwoClick = () => {
    setActivePage("daily");
  };

  return (
    <ProgramContainer background={background}>
      {isModalVisible && <CalendarModalBackground />}
      <MobileHeader
        textOne={"EXERCISE SETUP"}
        textTwo={"DAILY EXERCISE SETUP"}
        onTextOneClick={handleTextOneClick}
        onTextTwoClick={handleTextTwoClick}
        initialActive={"textOne"}
      />
      {activePage === "exercise" && (
        <>
          <NavContainer>
            {isEditMode && (
              <>
                <IconButton onClick={handleCopySelectedItems}>
                  <MdContentCopy />
                </IconButton>
                {!inFolder && (
                  <IconButton
                    onClick={() => {
                      setShowSelectDeleteModal(true);
                    }}
                  >
                    <IoTrashOutline />
                  </IconButton>
                )}
                <IconButton onClick={selectAllItems}>
                  <p>ALL</p>
                </IconButton>
              </>
            )}
            {inFolder && (
              <>
                <IconButton onClick={handleLeaveFolder}>
                  <FontAwesomeIcon icon={faArrowLeft} color="#ccc" />
                </IconButton>
                <IconButton onClick={() => setShowDeleteFolderModal(true)}>
                  <NavIcon src={trash} alt="trash" color="#ccc" />
                </IconButton>
              </>
            )}
            {!inFolder && (
              <>
                <IconButton onClick={toggleImportModal}>
                  <p>Import</p>
                </IconButton>
                <IconButton onClick={() => setShowCreateFolderModal(true)}>
                  <FaRegFolder />
                </IconButton>
              </>
            )}
            <IconButton
              onClick={() => {
                setIsEditMode(!isEditMode);
              }}
            >
              {isEditMode ? (
                <FontAwesomeIcon className="on" icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </IconButton>
            <IconButton onClick={SearchToggle}>
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </NavContainer>
          {searchToggle && (
            <SearchContainer ref={searchRef}>
              <form action="" onSubmit={handleFormSubmit}>
                <IoCloseCircle
                  style={{ color: "#09348a", scale: "1.5" }}
                  onClick={() => {
                    setSearchToggle(false);
                  }}
                />
                <InputContainer
                  type="text"
                  onChange={handleSearchChange}
                  value={searchTerm}
                ></InputContainer>
              </form>
            </SearchContainer>
          )}
          <div
            className="containerWrap"
            style={{
              height: "85%",
              overflowY: "auto",
              position: "absolute",
              top: "110px",
            }}
          >
            {inFolder ? (
              <AddBtn onClick={handleShowExerciseModal}>
                <FontAwesomeIcon icon={faPlus} />
              </AddBtn>
            ) : (
              <AddBtn onClick={handleAddButtonClick}>
                <FontAwesomeIcon icon={faPlus} />
              </AddBtn>
            )}
            {inFolder ? (
              <>
                {filteredVideos.map((video: Video, index) => {
                  return (
                    <ExerciseSetup
                      key={index}
                      videoData={{
                        url: video.in_array[0].mv_url,
                        title: video.pro_m_nm,
                        in_array: video.in_array,
                        mv_nm: video.in_array[0].mv_nm,
                        mv_rep_img: video.in_array[0].mv_rep_img,
                        pro_no: video.pro_no,
                      }}
                      proNo={video.pro_no}
                      onCopy={handleCopyExerciseSetup}
                      onDelete={handleDeleteExerciseSetup}
                      onSetting={handleSettingExerciseSetup}
                      toggleCalendar={toggleCalendarVisibility}
                      isSelected={selectedItems.has(video.pro_no)}
                      selectedItems={() => handleSelectItem(video.pro_no)}
                      isEditMode={isEditMode}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {folders.map((folder: Folder, index) => {
                  return (
                    <Folders
                      key={index}
                      onClick={() => handleEnterFolder(folder)}
                    >
                      <FolderName
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowEditFolderModal(true);
                          setEditingFolder({
                            fold_no: folder.fold_no,
                            fold_nm: folder.fold_nm,
                          });
                        }}
                      >
                        {folder.fold_nm}
                      </FolderName>
                      <FolderInList>
                        {folder.pro &&
                          folder.pro.map((program, programIndex) => (
                            <ListName key={programIndex}>
                              {program.pro_m_nm}
                            </ListName>
                          ))}
                      </FolderInList>
                    </Folders>
                  );
                })}
                {filteredVideos.map((video: Video, index) => {
                  return (
                    <>
                      <ExerciseSetup
                        key={index}
                        videoData={{
                          url: video.in_array[0].mv_url,
                          title: video.pro_m_nm,
                          in_array: video.in_array,
                          mv_nm: video.in_array[0].mv_nm,
                          mv_rep_img: video.in_array[0].mv_rep_img,
                          pro_no: video.pro_no,
                        }}
                        proNo={video.pro_no}
                        onCopy={handleCopyExerciseSetup}
                        onDelete={handleDeleteExerciseSetup}
                        onSetting={handleSettingExerciseSetup}
                        toggleCalendar={toggleCalendarVisibility}
                        isSelected={selectedItems.has(video.pro_no)}
                        selectedItems={() => handleSelectItem(video.pro_no)}
                        isEditMode={isEditMode}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}

      {activePage === "daily" && (
        <div
          style={{
            marginTop: "-20px",
            height: "100%",
            overflow: "hidden",
            backgroundColor: "#fff",
          }}
        >
          <DailyWrap>
            <div className="mobileContentsRow">
              <ProgramTiTle
                onClick={() => {
                  setIsCalendarSelectModal(true);
                }}
              >
                {viewDate.getFullYear()}. {viewDate.getMonth() + 1}
              </ProgramTiTle>
            </div>

            <div className="mobileContentsRow">
              <div {...handlers} className="calendarDiv">
                {arrCalData.map(
                  (item: any, idx: number) =>
                    idx >= viewWeekNo.startNo &&
                    idx <= viewWeekNo.endNo &&
                    renderDayItem(idx, item)
                )}
              </div>
            </div>
            <DailyContent>
              <DailyAddBtn
                onClick={() => {
                  setShowDailyExerciseModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </DailyAddBtn>
              {renderVideos()}
            </DailyContent>
          </DailyWrap>
          <div className={"buttonContainer"}>
            {MLoginInfo && MLoginInfo.MLoginInfo && (
              <AccountButton
                buttonUse={buttonToShow}
                mod_yn={MLoginInfo.MLoginInfo.mod_yn}
                member_type={MLoginInfo.MLoginInfo.member_type}
              />
            )}
          </div>

          {isVideoListModalOpen && (
            <VideoListModal
              videos={selectedVideos}
              title={title || ""}
              onClose={() => setIsVideoListModalOpen(false)}
            />
          )}
        </div>
      )}

      <div className={"buttonContainer"}>
        <AccountButton
          buttonUse={buttonToShow}
          mod_yn={MLoginInfo.MLoginInfo.mod_yn}
          member_type={MLoginInfo.MLoginInfo.member_type}
        />
      </div>

      {/* 새로운 폴더 */}
      {showCreateFolderModal && (
        <StyledNewFolderModal
          isOpen={showCreateFolderModal}
          onRequestClose={() => setShowCreateFolderModal(false)}
          contentLabel="Create New Folder"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>FOLDER TITLE</ModalTitle>
          <StyledInputContainer>
            <StyledInput
              type="text"
              value={newFolderTitle}
              onChange={(e) => setNewFolderTitle(e.target.value)}
              placeholder="Please Enter"
            />
          </StyledInputContainer>
          <ButtonWrap>
            <NewSaveButton
              text={"SAVE"}
              dnStatus={dnStatus}
              setDnStatus={setDnStatus}
              myClick={m_mng_pro_fold_i}
            />
          </ButtonWrap>
        </StyledNewFolderModal>
      )}

      {/* 폴더 이름 변경 */}
      {showEditFolderModal && (
        <StyledModal
          isOpen={showEditFolderModal}
          onRequestClose={() => setShowEditFolderModal(false)}
          contentLabel="Edit Folder Name"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>Edit Folder Name</ModalTitle>
          <StyledInputContainer>
            <StyledInput
              type="text"
              value={newFolderTitle}
              onChange={(e) => setNewFolderTitle(e.target.value)}
              placeholder="New Folder Name"
            />
          </StyledInputContainer>
          <NewSaveButton
            text={"SAVE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={handleEditFolder}
          />
        </StyledModal>
      )}

      {/* 선택 삭제 */}
      {showSelectDeleteModal && (
        <StyledModal
          isOpen={showSelectDeleteModal}
          onRequestClose={() => setShowDeleteFolderModal(false)}
          contentLabel="Delete Folder"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>Delete</ModalTitle>
          <ModalText>Are you sure you want to delete this EXERCISE?</ModalText>
          <div>
            <StyledButton
              onClick={handleDeleteSelectedItems}
              style={{ marginRight: "15px" }}
            >
              Yes, Delete
            </StyledButton>
            <StyledButton onClick={() => setShowSelectDeleteModal(false)}>
              Cancel
            </StyledButton>
          </div>
        </StyledModal>
      )}

      {/* 폴더 삭제 */}
      {showDeleteFolderModal && (
        <StyledModal
          isOpen={showDeleteFolderModal}
          onRequestClose={() => setShowDeleteFolderModal(false)}
          contentLabel="Delete Folder"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>Delete Folder</ModalTitle>
          <ModalText>Are you sure you want to delete this folder?</ModalText>
          <div>
            <StyledButton
              onClick={handleFolderDeleteConfirm}
              style={{ marginRight: "15px" }}
            >
              Yes, Delete
            </StyledButton>
            <StyledButton onClick={() => setShowDeleteFolderModal(false)}>
              Cancel
            </StyledButton>
          </div>
        </StyledModal>
      )}

      {renderExerciseModal()}
      {renderDailyExerciseModal()}
      {/*  프로그램 다른 센터에서 불러오기  */}
      <ImportProgramsModal
        isOpen={isImportModalOpen}
        onRequestClose={handleImportSuccess}
        center_no={MLoginInfo.MLoginInfo.center_no}
        searchText={""} // 검색 텍스트
        fold_yn={"n"}
      />

      {isCalendarSelectModal && (
        <CalendarBackdrop>
          <GlobalStyle />
          <CalendarWrap
            className={isCalendarSelectModal ? "visible" : ""}
            ref={calendarWrapRef}
            onClick={stopPropagation}
          >
            <CloseButton onClick={closeCalendarModal}>
              <IoIosCloseCircle />
            </CloseButton>
            <Calendar
              onChange={onDateChange}
              onActiveStartDateChange={() => {}}
              value={viewDate}
              formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
              minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              // nextLabel={null} // 다음 버튼 안보이게 설정
              next2Label={null} // 다음 버튼 안보이게 설정
              // prevLabel={null} // 이전 버튼 안보이게 설정
              prev2Label={null} // 이전 버튼 안보이게 설정
              formatShortWeekday={(locale, date) =>
                customWeekdayLabels[date.getDay()]
              } // 요일부분 영문 표기
              tileContent={({ date, view }) => {
                // 날짜 타일에 컨텐츠 추가하기 (html 태그)
                let html: any = [];

                return <div className="calitem">{html}</div>;
              }}
            />
            <SelectWrap>
              <Select
                value={viewDate.getFullYear()}
                onChange={handleYearChange}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => moment().year() - 2 + i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
              {/* <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Select> */}
              <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month - 1}>
                    {month + "월"}
                  </option>
                ))}
              </Select>
            </SelectWrap>
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={dnStatus}
                setDnStatus={setDnStatus}
                myClick={onSaveClick}
              />
            </ButtonWrap>
          </CalendarWrap>
        </CalendarBackdrop>
      )}
    </ProgramContainer>
  );
};

export default ProgramsItemM;
