import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as ServerApi from "../../constants/ServerApiM";
import { FaCircleXmark } from "react-icons/fa6";
import ReactPlayer from "react-player";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSwipeable } from "react-swipeable";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";

interface VideoPopupProps {
  mv_no: string;
  videoUrl: string;
  title: string;
  onClose: () => void;
}

interface MvInfo {
  mv_no: string;
  mv_nm: string;
  mv_rep_img: string;
  mv_text: string;
  mv_guide_file_nm1?: string;
  mv_guide_file_nm2?: string;
  mv_guide_file_nm3?: string;
}

interface MediaUrls {
  play: string | null | undefined;
  "1"?: string | null | undefined;
  "2"?: string | null | undefined;
  "3"?: string | null | undefined;
}

const ModalTitle = styled.div`
  width: 100%;
  padding-bottom: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 700;
`;

const PopupContainer = styled.div`
  width: 90%;
  height: 90vh;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

const PlayContainer = styled.div`
  width: 60%;
  max-width: 400px;
  margin: auto;
`;

const PopupContent = styled.div`
  margin: 10px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const GuideMediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  /* overflow: hidden; */
`;

const VideoWrap = styled.div`
  flex: 1 1 calc(33% - 10px);
  margin: 5px;
  height: auto; // 비율 유지
  overflow: hidden;

  img {
    max-width: 150px;
    width: 100%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 8px;
  transform: translate(-50%, 50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #09348a;
`;

const Title = styled.h2`
  font-size: 14px;
  margin: 0 0 15px 0;
  text-align: left;
`;

const GuideText = styled.div`
  // height: 80%;
  font-size: 14px;
  margin: 10px auto 30px auto;
  text-align: left;
  width: calc(100% - 20px);
  box-sizing: border-box;
  overflow-x: hidden;
  transition: transform 0.3s ease-out;
`;

// 티치가이드 스타일링
const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;
  padding-bottom: 100px;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WrapTitle = styled.p`
  min-width: 80px;
  font-size: 1.2em;
  color: #000000;
  font-family: "Pretendard", serif;
  font-weight: 600;
  margin-bottom: 10px;
`;

const WrapSubTitle = styled.p`
  font-size: 1em;
  color: #000000;
  font-family: "Pretendard", serif;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  margin-bottom: 15px;

  & > * {
    flex: 1 1 calc(50% - 15px);
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 0.8rem;
`;

const InputText = styled.p`
  color: #09348a;
  text-align: end;
`;

const InputBox = styled.textarea`
  width: 100%;
  padding: 8px 0;
  border: none;
  font-family: "Pretendard", serif;
  font-size: 0.9em;
  margin-bottom: 20px;
  resize: none;
  overflow-y: scroll;
`;

const TeachText = styled.textarea`
  width: 100%;
  padding: 8px 0 50px 0;
  border: none;
  font-family: "Pretendard", serif;
  font-size: 0.9em;
  margin-bottom: 15px;
  resize: none;

  &:focus {
    outline: none;
  }
`;

const LeftButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 70%;
  left: 10px;
  transform: translate(-50%, 50%);
`;

const RightButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 70%;
  right: 5px;
  transform: translate(-50%, 50%);
`;

const CheckIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: #09348a;
`;

const VideoPopup: React.FC<VideoPopupProps> = ({
  videoUrl,
  mv_no,
  title,
  onClose,
}) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [mvInfo, setMvInfo] = useState<MvInfo | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const handlePopupClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const [mediaUrls, setMediaUrls] = useState<MediaUrls>({
    play: videoUrl,
  });

  // 티칭 가이드
  // 선택된 항목 상태 관리
  const [selectedToolsEncompass, setSelectedToolsEncompass] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [selectedToolsMen, setSelectedToolsMen] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [selectedToolsWomen, setSelectedToolsWomen] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [selectedToolsOther, setSelectedToolsOther] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [motionAnalysis, setMotionAnalysis] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [cue, setCue] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [modification, setModification] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [handsOn, setHandsOn] = useState<{
    [key: string]: boolean | string;
  }>({});

  const [activeIndex, setActiveIndex] = useState(0);

  // 왼쪽으로
  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : carouselItems.length - 1
    );
  };

  // 오른쪽으로
  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < carouselItems.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
  });

  useEffect(() => {
    console.log("MLoginInfo", MLoginInfo);
  }, []);

  const carouselItems = [
    <ModalWrap>
      <Wrap>
        <WrapTitle>MEMO</WrapTitle>
        <ItemContainer>
          <Label style={{ height: "200px" }}>
            <InputBox
              style={{ height: "200px" }}
              value={mvInfo?.mv_text}
              readOnly
            />
          </Label>
        </ItemContainer>
      </Wrap>
    </ModalWrap>,
    <ModalWrap>
      <Wrap>
        <WrapTitle>TOOL</WrapTitle>
        <WrapSubTitle>ENCOMPASS SETUP</WrapSubTitle>
        <ItemContainer>
          {selectedToolsEncompass["STANDING PLATFORM"] && (
            <Label>
              STANDING PLATFORM
              <InputText>
                {selectedToolsEncompass["STANDING PLATFORM"] && (
                  <CheckIcon icon={faCheck} />
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass["CLAMP"] && (
            <Label>
              CLAMP
              <InputText>{selectedToolsEncompass["CLAMP"] || ""}</InputText>
            </Label>
          )}
          {selectedToolsEncompass["ARM PULLEY"] && (
            <Label>
              ARM PULLEY
              <InputText>
                {selectedToolsEncompass["ARM PULLEY"] && (
                  <CheckIcon icon={faCheck} />
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass["SCRUNCH"] && (
            <Label>
              SCRUNCH
              <InputText>
                {selectedToolsEncompass["SCRUNCH"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass[" LEG PULLEY"] && (
            <Label>
              LEG PULLEY
              <InputText>
                {selectedToolsEncompass["LEG PULLEY"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass["TOE BAR"] && (
            <Label>
              TOE BAR
              <InputText>
                {selectedToolsEncompass["TOE BAR"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass["PRESS BAR"] && (
            <Label>
              PRESS BAR
              <InputText>
                {selectedToolsEncompass["PRESS BAR"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
          {selectedToolsEncompass["WEIGHT BAR"] && (
            <Label>
              WEIGHT BAR
              <InputText>
                {selectedToolsEncompass["WEIGHT BAR"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
        </ItemContainer>
        <WrapSubTitle>INTENSITY - MEN</WrapSubTitle>
        <ItemContainer>
          {selectedToolsMen["ENCOMPASS INCLINE"] && (
            <Label>
              ENCOMPASS INCLINE
              <InputText>
                {selectedToolsMen["ENCOMPASS INCLINE"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsMen["KETTLEBELL"] && (
            <Label>
              KETTLEBELL
              <InputText>{selectedToolsMen["KETTLEBELL"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["BULGARIAN BAG"] && (
            <Label>
              BULGARIAN BAG
              <InputText>{selectedToolsMen["BULGARIAN BAG"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["WEIGHT BAG"] && (
            <Label>
              WEIGHT BAG
              <InputText>{selectedToolsMen["WEIGHT BAG"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["DUMBELL"] && (
            <Label>
              DUMBELL
              <InputText>{selectedToolsMen["DUMBELL"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["BARBELL"] && (
            <Label>
              BARBELL
              <InputText>{selectedToolsMen["BARBELL"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["MEDICINE BELL"] && (
            <Label>
              MEDICINE BELL
              <InputText>{selectedToolsMen["MEDICINE BELL"] || "-"}</InputText>
            </Label>
          )}
          {selectedToolsMen["BATTLE ROPE"] && (
            <Label>
              BATTLE ROPE
              <InputText>
                {selectedToolsMen["BATTLE ROPE"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
        </ItemContainer>
        <WrapSubTitle>INTENSITY - WOMEN</WrapSubTitle>
        <ItemContainer>
          {selectedToolsWomen["ENCOMPASS INCLINE WOMEN"] && (
            <Label>
              ENCOMPASS INCLINE
              <InputText>
                {selectedToolsWomen["ENCOMPASS INCLINE WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["KETTLEBELL WOMEN"] && (
            <Label>
              KETTLEBELL
              <InputText>
                {selectedToolsWomen["KETTLEBELL WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["BULGARIAN BAG WOMEN"] && (
            <Label>
              BULGARIAN BAG
              <InputText>
                {selectedToolsWomen["BULGARIAN BAG WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["WEIGHT BAG WOMEN"] && (
            <Label>
              WEIGHT BAG
              <InputText>
                {selectedToolsWomen["WEIGHT BAG WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["DUMBELL WOMEN"] && (
            <Label>
              DUMBELL
              <InputText>
                {selectedToolsWomen["DUMBELL WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["BARBELL WOMEN"] && (
            <Label>
              BARBELL
              <InputText>
                {selectedToolsWomen["BARBELL WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["MEDICINE BELL WOMEN"] && (
            <Label>
              MEDICINE BELL
              <InputText>
                {selectedToolsWomen["MEDICINE BELL WOMEN"] || "-"}
              </InputText>
            </Label>
          )}
          {selectedToolsWomen["BATTLE ROPE WOMEN"] && (
            <Label>
              BATTLE ROPE
              <InputText>
                {selectedToolsWomen["BATTLE ROPE WOMEN"] ? (
                  <CheckIcon icon={faCheck} />
                ) : (
                  "No"
                )}
              </InputText>
            </Label>
          )}
        </ItemContainer>
      </Wrap>
    </ModalWrap>,
    <ModalWrap>
      <Wrap>
        <WrapSubTitle>GOAL</WrapSubTitle>
        {selectedToolsOther["GOAL"] && (
          <TeachText
            value={
              selectedToolsOther["GOAL"] === true
                ? "true"
                : selectedToolsOther["GOAL"] || ""
            }
          />
        )}
        <WrapSubTitle>COMMON MISTAKE</WrapSubTitle>
        {selectedToolsOther["MISTAKE"] && (
          <TeachText
            value={
              selectedToolsOther["MISTAKE"] === true
                ? "true"
                : selectedToolsOther["MISTAKE"] || ""
            }
          />
        )}
      </Wrap>
    </ModalWrap>,
    <ModalWrap>
      <Wrap>
        <WrapTitle>MOTION ANALYSIS</WrapTitle>
        <WrapSubTitle>MOVING JOINT</WrapSubTitle>
        <ItemContainer>
          {motionAnalysis["CERVICAL MOVING"] && (
            <Label>
              CERVICAL MOVING
              <InputText>{motionAnalysis["CERVICAL MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["THORACIC MOVING"] && (
            <Label>
              THORACIC
              <InputText>{motionAnalysis["THORACIC MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["LUMBAR MOVING"] && (
            <Label>
              LUMBAR
              <InputText>{motionAnalysis["LUMBAR MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["SCAPULAR MOVING"] && (
            <Label>
              SCAPULAR
              <InputText>{motionAnalysis["SCAPULAR MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["GH MOVING"] && (
            <Label>
              G.H
              <InputText>{motionAnalysis["GH MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["ELBOW MOVING"] && (
            <Label>
              ELBOW
              <InputText>{motionAnalysis["ELBOW MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["WRIST MOVING"] && (
            <Label>
              WRIST
              <InputText>{motionAnalysis["WRIST MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["PELVIS MOVING"] && (
            <Label>
              PELVIS
              <InputText>{motionAnalysis["PELVIS MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["HIP MOVING"] && (
            <Label>
              HIP
              <InputText>{motionAnalysis["HIP MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["KNEE MOVING"] && (
            <Label>
              KNEE
              <InputText>{motionAnalysis["KNEE MOVING"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["ANKLE MOVING"] && (
            <Label>
              ANKLE
              <InputText>{motionAnalysis["ANKLE MOVING"] || "-"}</InputText>
            </Label>
          )}
        </ItemContainer>
        <WrapSubTitle>HOLD JOINT</WrapSubTitle>
        <ItemContainer>
          {motionAnalysis["CERVICAL HOLD"] && (
            <Label>
              CERVICAL
              <InputText>{motionAnalysis["CERVICAL HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["THORACIC HOLD"] && (
            <Label>
              THORACIC
              <InputText>{motionAnalysis["THORACIC HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["LUMBAR HOLD"] && (
            <Label>
              LUMBAR
              <InputText>{motionAnalysis["LUMBAR HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["SCAPULAR HOLD"] && (
            <Label>
              SCAPULAR
              <InputText>{motionAnalysis["SCAPULAR HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["GH HOLD"] && (
            <Label>
              G.H
              <InputText>{motionAnalysis["GH HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["ELBOW HOLD"] && (
            <Label>
              ELBOW
              <InputText>{motionAnalysis["ELBOW HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["WRIST HOLD"] && (
            <Label>
              WRIST
              <InputText>{motionAnalysis["WRIST HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["PELVIS HOLD"] && (
            <Label>
              PELVIS
              <InputText>{motionAnalysis["PELVIS HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["HIP HOLD"] && (
            <Label>
              HIP
              <InputText>{motionAnalysis["HIP HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["KNEE HOLD"] && (
            <Label>
              KNEE
              <InputText>{motionAnalysis["KNEE HOLD"] || "-"}</InputText>
            </Label>
          )}
          {motionAnalysis["ANKLE HOLD"] && (
            <Label>
              ANKLE
              <InputText>{motionAnalysis["ANKLE HOLD"] || "-"}</InputText>
            </Label>
          )}
        </ItemContainer>
      </Wrap>
    </ModalWrap>,
    <ModalWrap>
      <Wrap>
        <WrapTitle>CUE</WrapTitle>
        {cue["STARTPOSITION STOP"] && (
          <>
            <WrapSubTitle>STARTPOSITION STOP</WrapSubTitle>
            <TeachText
              value={
                cue["STARTPOSITION STOP"] === true
                  ? "true"
                  : cue["STARTPOSITION STOP"] || ""
              }
              readOnly
            />
          </>
        )}
        {cue["STICKINGPOINT STOP"] && (
          <>
            <WrapSubTitle>STICKINGPOINT STOP</WrapSubTitle>
            <TeachText
              value={
                cue["STICKINGPOINT STOP"] === true
                  ? "true"
                  : cue["STICKINGPOINT STOP"] || ""
              }
              readOnly
            />
          </>
        )}
        {cue["3 REP"] && (
          <>
            <WrapSubTitle>3 REP</WrapSubTitle>
            <TeachText
              value={cue["3 REP"] === true ? "true" : cue["3 REP"] || ""}
              readOnly
            />
          </>
        )}
        {cue["COMMON MISTAKE"] && (
          <>
            <WrapSubTitle>COMMON MISTAKE</WrapSubTitle>
            <TeachText
              value={
                cue["COMMON MISTAKE"] === true
                  ? "true"
                  : cue["COMMON MISTAKE"] || ""
              }
              readOnly
            />
          </>
        )}
        {cue["NORMAL SIGN"] && (
          <>
            <WrapSubTitle>NORMAL SIGN</WrapSubTitle>
            <TeachText
              value={
                cue["NORMAL SIGN"] === true ? "true" : cue["NORMAL SIGN"] || ""
              }
              readOnly
            />
          </>
        )}
        {modification["MODIFICATION"] && (
          <>
            <WrapTitle>MODIFICATION</WrapTitle>
            <TeachText
              value={
                modification["MODIFICATION"] === true
                  ? "true"
                  : modification["MODIFICATION"] || ""
              }
              readOnly
            />
          </>
        )}
      </Wrap>
    </ModalWrap>,
    <ModalWrap>
      <Wrap>
        <WrapTitle>HANDS ON</WrapTitle>
        {handsOn["PASSIVE"] && (
          <>
            <WrapSubTitle>PASSIVE</WrapSubTitle>
            <TeachText
              value={
                handsOn["PASSIVE"] === true ? "true" : handsOn["PASSIVE"] || ""
              }
              readOnly
            />
          </>
        )}
        {handsOn["ACTIVE"] && (
          <>
            <WrapSubTitle>ACTIVE</WrapSubTitle>
            <TeachText
              value={
                handsOn["ACTIVE"] === true ? "true" : handsOn["ACTIVE"] || ""
              }
              readOnly
            />
          </>
        )}
        {handsOn["HURDLE"] && (
          <>
            <WrapSubTitle>HURDLE</WrapSubTitle>
            <TeachText
              value={
                handsOn["HURDLE"] === true ? "true" : handsOn["HURDLE"] || ""
              }
              readOnly
            />
          </>
        )}
      </Wrap>
    </ModalWrap>,
  ];

  useEffect(() => {
    const fetchMvInfo = async () => {
      try {
        const response = await ServerApi.m_app_mv_info({ mv_no });
        if (response && response.rsp_code === "100") {
          const mvText2 = JSON.parse(response.mv_text2 || "{}");
          const mvText3 = JSON.parse(response.mv_text3 || "{}");
          const mvText4 = JSON.parse(response.mv_text4 || "{}");
          const mvText5 = JSON.parse(response.mv_text5 || "{}");
          const mvText6 = JSON.parse(response.mv_text6 || "{}");
          const mvText7 = JSON.parse(response.mv_text7 || "{}");
          const mvText8 = JSON.parse(response.mv_text8 || "{}");
          const mvText9 = JSON.parse(response.mv_text9 || "{}");

          setSelectedToolsEncompass(mvText2);
          setSelectedToolsMen(mvText3);
          setSelectedToolsWomen(mvText4);
          setSelectedToolsOther(mvText5);
          setMotionAnalysis(mvText6);
          setCue(mvText7);
          setModification(mvText8);
          setHandsOn(mvText9);

          setMvInfo(response);
          console.log("mvInfo", response);
          setMediaUrls((urls) => ({
            ...urls,
            1: response.mv_guide_file_nm1,
            2: response.mv_guide_file_nm2,
            3: response.mv_guide_file_nm3,
          }));
        }
      } catch (error) {
        console.error("API 호출 중 오류 발생:", error);
      }
    };

    if (mv_no) {
      fetchMvInfo();
    }
  }, [mv_no]);

  //   const handleMediaClick = (index: keyof MediaUrls) => {
  //     // 클릭된 미디어 파일을 play로 설정
  //     const newPlayUrl = mediaUrls[index];
  //     const currentPlayUrl = mediaUrls.play;

  //     setMediaUrls((urls) => ({
  //       ...urls,
  //       play: newPlayUrl, // 새로 클릭된 미디어를 재생
  //       [index]: currentPlayUrl, // 원래 재생 중이던 미디어를 클릭된 위치에 설정
  //     }));
  //     setIsPlaying(true);
  //   };

  const handleMediaClick = (index: keyof MediaUrls) => {
    setMediaUrls((urls) => {
      const newPlayUrl = urls[index];
      const updatedUrls = { ...urls, play: newPlayUrl };

      // 기존 플레이 중이던 미디어를 클릭된 위치로 설정
      if (index === "1") {
        updatedUrls["1"] = urls.play;
      } else if (index === "2") {
        updatedUrls["2"] = urls.play;
      } else if (index === "3") {
        updatedUrls["3"] = urls.play;
      }

      return updatedUrls;
    });
    setIsPlaying(true);
  };

  const isVideoUrl = (url: string) => {
    return url.match(/\.(mp4|webm|ogg)$/i);
  };

  const renderMedia = (url: string, key: keyof MediaUrls) => {
    if (!url) return null; // URL이 없으면 아무것도 렌더링하지 않음
    const isPlayingMedia = mediaUrls.play === url;
    return (
      <VideoWrap key={key} onClick={() => handleMediaClick(key)}>
        {isVideoUrl(url) ? (
          <ReactPlayer
            url={url + "#t=0.5"}
            playing={isPlayingMedia && key === "play"}
            loop
            muted
            controls={false}
            width="100%"
            height="100%"
            onClick={togglePlayPause}
          />
        ) : (
          <img src={url} alt={`Guide ${key}`} width="100%" height="100%" />
        )}
      </VideoWrap>
    );
  };

  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // 이벤트 버블링 중단
    onClose();
  };

  const togglePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  return (
    <PopupContainer onClick={handlePopupClick}>
      <ModalTitle>TEACH GUIDE</ModalTitle>
      <CloseButton onClick={handleCloseClick}>
        <FaCircleXmark />
      </CloseButton>
      {/* <Title>{title}</Title> */}
      <PlayContainer>
        {isVideoUrl(mediaUrls.play || "") ? (
          <ReactPlayer
            url={mediaUrls.play || ""}
            playing={isPlaying}
            loop={true}
            muted={true}
            width="100%"
            height="100%"
            onClick={togglePlayPause}
          />
        ) : (
          <img
            src={mediaUrls.play || ""}
            alt="Main Content"
            width="100%"
            height="100%"
          />
        )}
      </PlayContainer>
      {mvInfo && (
        <PopupContent>
          <GuideMediaContainer>
            {mediaUrls[1] && renderMedia(mediaUrls[1] || "", "1")}
            {mediaUrls[2] && renderMedia(mediaUrls[2] || "", "2")}
            {mediaUrls[3] && renderMedia(mediaUrls[3] || "", "3")}
          </GuideMediaContainer>
          {MLoginInfo.MLoginInfo.guide_yn === "y" && (
            <>
              <LeftButton onClick={handlePrev}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </LeftButton>
              <GuideText {...handlers}>{carouselItems[activeIndex]}</GuideText>
              <RightButton onClick={handleNext}>
                <FontAwesomeIcon icon={faChevronRight} />
              </RightButton>
            </>
          )}
        </PopupContent>
      )}
    </PopupContainer>
  );
};

export default VideoPopup;
