import * as MyUtil from "./MyUtilM";
import { M_URL } from "./Config";

// https://velog.io/@jkl1545/React-Query
// https://velog.io/@chltjdrhd777/React-Query-%EC%BA%90%EC%8B%B1%EC%97%90-%EB%8C%80%ED%95%9C-%EA%B5%AC%ED%98%84

// 요약하면 R ( Read ) 은 useQuery , CUD ( Create, Update, Delete )는 useMutation을 사용하면 된다.
// 캐시가 있는 모든 쿼리 무효화 :  queryClient.invalidateQueries();
// staleTime : 동일 파라미터 요청 금지 시간

interface ApiResponse {
  rsp_code: string;
}

export function m_app_join(data: {
  center_nm: string;
  name: string;
  handphone: string;
  userid: string;
  password: string;
  member_type: string;
  center_cd: string;
}): Promise<ApiResponse> {
  const REQ_METHODS = "m_app_join";

  return MyUtil._httpReq(REQ_METHODS, data)
    .then((response) => {
      return {
        rsp_code: response.rsp_code,
        data: response,
      };
    })
    .catch((error) => {
      console.error("회원가입 중 API 호출 오류:", error);
      throw error;
    });
}

export function m_app_check_id(userid: string): Promise<ApiResponse> {
  const REQ_METHODS = "m_app_check_id"; // 서버에서 정의한 API 경로명

  return MyUtil._httpReq(REQ_METHODS, { userid })
    .then((response) => {
      return {
        rsp_code: response.rsp_code,
        data: response,
      };
    })
    .catch((error) => {
      console.error("중복 검사 중 API 호출 오류:", error);
      throw error;
    });
}

export function m_app_login(data: { userid: string; password: string }) {
  const REQ_METHODS = "m_app_login";
  return MyUtil._httpReq(REQ_METHODS, data)
    .then((response) => {
      return {
        rsp_code: response.rsp_code,
        data: response,
      };
    })
    .catch((error) => {
      console.error("로그인 중 API 호출 오류:", error);
      throw error;
    });
}

export function m_app_center(data: { center_nm: string }) {
  const REQ_METHODS = "m_app_center";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_center_pro_m(data: {
  center_no: string;
  c_day?: string;
}) {
  const REQ_METHODS = "m_app_center_pro_m";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_tu(data: {}) {
  const REQ_METHODS = "m_app_tu";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_info(data: { u_id: string }) {
  const REQ_METHODS = "m_app_info";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_mv_info(data: { mv_no: string }) {
  const REQ_METHODS = "m_app_mv_info";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m(data: { center_no: string; fold_yn?: string }) {
  const REQ_METHODS = "m_app_pro_m";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_center_i(data: {
  center_no: string;
  c_day: string;
  pro_no: string;
}) {
  const REQ_METHODS = "m_app_pro_center_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m_dt(data: { pro_no: string }) {
  const REQ_METHODS = "m_app_pro_m_dt";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_mv(data: {}) {
  const REQ_METHODS = "m_app_mv";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_mv_category(data: {}) {
  const REQ_METHODS = "m_app_mv_category";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m_i(data: {
  pro_m_nm: string;
  pro_no: string;
  center_no: string;
  array_a: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_b: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_c: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_d: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_e: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_f: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_g: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
  array_h: {
    mv_no: string;
    mv_nm: string;
    mv_url: string;
    mv_rep_img: string;
  }[];
}) {
  const REQ_METHODS = "m_app_pro_m_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m_u(data: {
  pro_no: string;
  seq_type: string;
  array: {
    mv_no: string;
    seq: string;
    pro_fold_no?: string;
  }[];
}) {
  const REQ_METHODS = "m_app_pro_m_u";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m_copy_i(data: {
  pro_no: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_app_pro_m_copy_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_m_d(data: { pro_no: string }) {
  const REQ_METHODS = "m_app_pro_m_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_center_member(data: { center_no: string }) {
  const REQ_METHODS = "m_app_center_member";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_center_member_set_u(data: {
  u_id: string;
  account_yn: string;
  mod_yn: string;
}) {
  const REQ_METHODS = "m_app_center_member_set_u";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_mv_time_set(data: {
  array: {
    pro_no: string;
    seq: string;
    seq_type: string;
    wait_time: string;
    ex_time: string;
  }[];
  br_time: string;
}) {
  const REQ_METHODS = "m_app_pro_mv_time_set";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_list(data: {
  center_no: string;
  pro_no: string;
  play_fold_no?: string;
}) {
  const REQ_METHODS = "m_app_play_list";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_fold_i(data: {
  title: string;
  sub_title: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_app_play_fold_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_fold_u(data: {
  play_fold_no: string;
  title: string;
  sub_title: string;
}) {
  const REQ_METHODS = "m_app_play_fold_u";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_i(data: {
  array: {
    config_type: string;
    file_nm: string;
    dis_nm: string;
  }[];
  play_fold_no?: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_app_play_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_d(data: {
  array: {
    config_no: string;
  }[];
}) {
  const REQ_METHODS = "m_app_play_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_my_play_list(data: {
  pro_no: string;
  music_gb_check?: string;
}) {
  const REQ_METHODS = "m_app_pro_my_play_list";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_play_i(data: {
  array: {
    config_no: string;
  }[];
  pro_no: string;
}) {
  const REQ_METHODS = "m_app_pro_play_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_play_gb_i(data: {
  pro_no: string;
  music_gb: string;
}) {
  const REQ_METHODS = "m_app_pro_play_gb_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_play_d(data: {
  array: {
    pro_music_no: string;
  }[];
  pro_no: string;
}) {
  const REQ_METHODS = "m_app_pro_play_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_assign(data: {
  array: {
    config_no: string;
    seq: string;
  }[];
  play_folder_no?: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_app_play_assign";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_fold_i(data: {
  pro_fold_no?: string;
  fold_nm: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_mng_pro_fold_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_fold_exec_i(data: {
  fold_no: string;
  array: {
    pro_no: string;
  }[];
}) {
  const REQ_METHODS = "m_mng_pro_fold_exec_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_fold_dt(data: { fold_no: string }) {
  const REQ_METHODS = "m_mng_pro_fold_dt";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_fold_d(data: { fold_no: string }) {
  const REQ_METHODS = "m_mng_pro_fold_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_play_fold_d(data: {
  play_fold_no: string;
  center_no: string;
}) {
  const REQ_METHODS = "m_app_play_fold_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

// 댓글 기능 관련
export function m_mng_pro_info(data: { pro_no: string }) {
  const REQ_METHODS = "m_mng_pro_info";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_reply_d(data: { pro_brd_no: string }) {
  const REQ_METHODS = "m_mng_pro_reply_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_reply(data: { pro_no: string }) {
  const REQ_METHODS = "m_mng_pro_reply";
  return MyUtil._httpReq(REQ_METHODS, data);
}

// information 등록
export function m_mng_pro_info_i(data: {
  pro_no: string;
  info: string;
  file_nm1: string;
  file_nm2: string;
  file_nm3: string;
  file_nm4: string;
  mv_url: string;
}) {
  const REQ_METHODS = "m_mng_pro_info_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export async function m_app_profile_u(formData: any) {
  const REQ_METHODS = "m_app_profile_u";
  return await MyUtil._multiPartReq(REQ_METHODS, formData);
}

export function m_app_email_pw_find(data: {
  userid: string;
  handphone: string;
}) {
  const REQ_METHODS = "m_app_email_pw_find";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_email_auth_confirm(data: {
  no: string;
  auth_no: string;
}) {
  const REQ_METHODS = "m_app_email_auth_confirm";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pwd_change(data: {
  no: string;
  auth_no: string;
  password: string;
}) {
  const REQ_METHODS = "m_app_pwd_change";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_my_pwd_change(data: { u_id: string; password: string }) {
  const REQ_METHODS = "m_app_my_pwd_change";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_reply_i(data: {
  pro_no: string;
  center_no: string;
  contents: string;
}) {
  const REQ_METHODS = "m_mng_pro_reply_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_mv_time_all_set(data: {
  pro_no: string;
  m_ex_time?: string;
  m_wait_time?: string;
  br_time?: string;
}) {
  const REQ_METHODS = "m_app_pro_mv_time_all_set";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_m_have_i(data: { center_no: string; array: any }) {
  const REQ_METHODS = "m_mng_pro_m_have_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_m(data: {
  center_no: string;
  c_day: string;
  pro_m_nm: string;
  fold_yn: string;
}) {
  const REQ_METHODS = "m_mng_pro_m";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_center(data: { center_nm: string }) {
  const REQ_METHODS = "m_mng_center";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_m_all_d(data: {
  arr1: { pro_no: string }[];
  arr2: { fold_no: string }[];
}) {
  const REQ_METHODS = "m_mng_pro_m_all_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_mng_pro_m_select_copy_i(data: {
  array: { pro_no: string }[];
  center_no: string;
}) {
  const REQ_METHODS = "m_mng_pro_m_select_copy_i";
  return MyUtil._httpReq(REQ_METHODS, data);
}

export function m_app_pro_center_d(data: { center_pro_no: string }) {
  const REQ_METHODS = "m_app_pro_center_d";
  return MyUtil._httpReq(REQ_METHODS, data);
}
