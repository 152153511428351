import { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import ReactAudioPlayer from "react-audio-player";
import {
  faVolumeMute,
  faVolumeHigh,
  faPlay,
  faPause,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApi";
import symbol from "../img/symbol.png";
import logo from "../img/logo_mobile.png";
import gifHeartQuick from "../img/gif_heart_red_q.gif";
import gifHeartSlow from "../img/gif_heart_red_s.gif";
import { RootState } from "../component/redux/rootReducer";
import Loader from "../component/Loader";
import "../css/ModalAntd.css";
import styled from "styled-components";
import AlertModal from "react-modal";

const SBVideoPlay = ({ location }: any) => {
  const { rxLoginInfo } = useSelector(
    (state: RootState) => state.rxLoginInfo,
    (prev, next) => prev.rxLoginInfo === next.rxLoginInfo
  );
  const history = useHistory();
  const [pro_no, setPro_no] = useState(
    MyUtil._isNull(location?.state?.pro_no) ? "" : location?.state?.pro_no
  );
  const my_center_no = rxLoginInfo.center_no;
  const [selectProgram, setSelectProgram] = useState<any>({});
  const [musicIdx, setMusicIdx] = useState(0);
  const [playSoundFile, setPlaySoundFile] = useState("sound_go");
  const [playStatus, setPlayStatus] = useState("br");
  const [volumeLevel, setVolumeLevel] = useState(0.7);
  const [countVolumeLevel, setCountVolumeLevel] = useState(0);
  const [isLogoScreen, setIsLogoScreen] = useState(true);
  const [isBgSound, setIsBgSound] = useState(true);
  const [isCountSound, setIsCountSound] = useState(false);
  const [isPlayEx, setIsPlayEx] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [exTimer, setExTimer] = useState<number>(-1);
  const audioPlayer = useRef<any>(null);
  const countPlayer = useRef<any>(null);
  const interval = useRef<any>(null);
  const [tempVideo, setTempVideo] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [arrMusic, setArrMusic] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // modal 처리
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openAlertModal = () => setModalIsOpen(true);
  const closeAlertModal = () => {
    setModalIsOpen(false);
    history.goBack();
  };

  const EMPTY_BR_TIME = 4;

  const mParams = useRef<any>({
    exTimer: 0,
    exStatus: "br",
    EX_TIME: 0,
    BR_TIME: 0,
    WAIT_TIME: 0,
    isRestTime: false,
    videoLength: 0,
    autoYn: "n",
    seqIndex: 0,
    seqVideoIndex: 0,
  });

  const handleReset = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    mParams.current = {
      exTimer: 0,
      exStatus: "br",
      EX_TIME: 0,
      BR_TIME: 0,
      WAIT_TIME: 0,
      isRestTime: false,
      videoLength: 0,
      autoYn: "n",
      seqIndex: 0,
      seqVideoIndex: 0,
    };
    setPlayStatus("br");
    setIsPlayEx(false);
    setIsTimerVisible(false);
    setIsLogoScreen(true);
    // setExTimer(EMPTY_BR_TIME);
  }, []);

  let Count = 1;

  // SSE 리모컨
  useEffect(() => {
    const eventSource = new EventSource("http://3.37.242.5:4000/events");

    eventSource.onmessage = function (event) {
      if (Count == 1) {
        Count = Count + 1;
        // mParams.current.exTimer = EMPTY_BR_TIME;
        mParams.current.exStatus = "br";
        setPlayStatus("br");
        return;
      }
      setIsPlayEx(false);
      setIsLogoScreen(true);
      setExTimer(EMPTY_BR_TIME);
      setIsTimerVisible(false);

      if (interval.current) {
        clearInterval(interval.current);
      }
      const { value: newNumber, center_no } = JSON.parse(event.data);
      console.log("넘어오는 값들", event.data);

      if (center_no !== (location?.state?.center_no ?? my_center_no)) {
        return;
      }

      if (newNumber === "RESET") {
        mParams.current.seqIndex = 0;
        mParams.current.seqVideoIndex = 0;
        setPlayStatus("br");
        setIsPlayEx(false);
        setIsTimerVisible(false);
        setIsLogoScreen(true);
        timerHandler();
        handleReset();

        return;
      }

      if (newNumber === "PLAY") {
        if (
          mParams.current.seqIndex === 0 &&
          mParams.current.seqVideoIndex === 0
        ) {
          const firstVideo =
            selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array[
              mParams.current.seqVideoIndex
            ];
          mParams.current.EX_TIME = MyUtil._isNull(firstVideo?.ex_time)
            ? 0
            : parseInt(firstVideo?.ex_time);
          mParams.current.WAIT_TIME = MyUtil._isNull(firstVideo?.wait_time)
            ? 0
            : parseInt(firstVideo?.wait_time);
          setTempVideo(
            selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array
          );
        }
        setIsPlayEx(true);
        setPlayStatus("p");
        setIsTimerVisible(true);
        setIsLogoScreen(false);
        timerHandler();
        return;
      }

      if (newNumber === "PAUSE") {
        setIsPlayEx(false);
        setPlayStatus("p");
        setIsTimerVisible(false);
        setIsLogoScreen(false);
        timerHandler();
        if (interval.current) {
          clearInterval(interval.current);
        }
        return;
      }

      if (selectProgram.pro_array?.[newNumber - 1]) {
        setCountVolumeLevel(0);
        if (countPlayer.current) {
          countPlayer.current.audioEl.current.pause();
        }

        mParams.current.seqIndex = newNumber - 1;
        mParams.current.seqVideoIndex = 0;
        const firstVideo =
          selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array[
            mParams.current.seqVideoIndex
          ];
        mParams.current.EX_TIME = MyUtil._isNull(firstVideo?.ex_time)
          ? 0
          : parseInt(firstVideo?.ex_time);
        mParams.current.WAIT_TIME = MyUtil._isNull(firstVideo?.wait_time)
          ? 0
          : parseInt(firstVideo?.wait_time);
        setIsPlayEx(true);
        setIsLogoScreen(false);
        setExTimer(EMPTY_BR_TIME);
        setIsTimerVisible(true);
        mParams.current.exTimer = EMPTY_BR_TIME;
        mParams.current.exStatus = "r";
        setPlayStatus("r");

        // Select new program and update the state
        // setSelectProgram((prevProgram) => {
        //   const newProgram = { ...prevProgram };
        //   newProgram.pro_array[newNumber - 1] = {
        //     ...newProgram.pro_array[newNumber - 1],
        //   };
        //   return newProgram;
        // });
        setTempVideo(
          selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array
        );

        timerHandler();
      }
    };

    return () => {
      eventSource.close();
    };
  }, [selectProgram, my_center_no]);

  const m_center_pro_m_dt = useCallback(
    async (isFirst) => {
      let result = await ServerApi.m_center_pro_m_dt({ pro_no: pro_no });

      const { isOk, data } = MyUtil._isResultSucces(
        "m_center_pro_m_dt",
        result
      );
      console.log("m_center_pro_m_dt result", result);
      if (isOk) {
        const configResult = await ServerApi.m_mng_config({
          center_no: location?.state?.center_no ?? rxLoginInfo.center_no,
          config_type: "c1",
        });
        setLoading(false);

        if (
          configResult.IS_SUCCESS === true &&
          configResult.DATA_RESULT.rsp_code === "100"
        ) {
          setArrMusic(configResult.DATA_RESULT.array);
          let getIdx = musicIdx;
          getIdx = Math.floor(
            Math.random() * configResult.DATA_RESULT.array.length
          );
          setMusicIdx(getIdx);
        } else {
          MyUtil._alertMsg("m_mng_config", configResult.DATA_RESULT);
        }

        mParams.current.autoYn = data?.auto_yn;
        const restTime = MyUtil._isNull(data?.br_time)
          ? EMPTY_BR_TIME
          : parseInt(data?.br_time); // 쉬는 시간
        mParams.current.BR_TIME = restTime;
        mParams.current.isRestTime = !MyUtil._isNull(data?.br_time);
        mParams.current.videoLength = data?.pro_array?.length;
        // 시퀀스 초기화
        mParams.current.seqIndex = 0;
        mParams.current.seqVideoIndex = 0;

        // 각 시퀀스의 첫 영상의 ex_time과 wait_time을 설정
        const firstVideo = data?.pro_array?.[0]?.mv_array[0];
        const exTime = MyUtil._isNull(firstVideo?.ex_time)
          ? 0
          : parseInt(firstVideo?.ex_time);
        const waitTime = MyUtil._isNull(firstVideo?.wait_time)
          ? 0
          : parseInt(firstVideo?.wait_time);

        if (exTime === 0 || waitTime === 0) {
          if (!isModalVisible) {
            setModalMessage("EX_TIME과 WAIT_TIME을 확인해주세요.");
            openAlertModal();
            return;
          }
        }

        mParams.current.EX_TIME = exTime;
        mParams.current.WAIT_TIME = waitTime;

        // 최초 실행
        if (isFirst) {
          mParams.current.seqIndex = 0;
          mParams.current.seqVideoIndex = 0;
          if (mParams.current.BR_TIME > 0) {
            // restTime = mParams.current.BR_TIME
            // mParams.current.BR_TIME = restTime;
            setPlayStatus("br");
            setIsPlayEx(true);
            setIsTimerVisible(true);
            setIsLogoScreen(false);
            // setExTimer(mParams.current.BR_TIME);
            mParams.current.exStatus = "br";
            // mParams.current.exTimer = exTime
          } else {
            setPlayStatus("r");
            setIsPlayEx(false);
            setIsTimerVisible(false);
            setIsLogoScreen(false);
          }
        } else {
          setPlayStatus("r");
          setIsPlayEx(false);
          setIsTimerVisible(true);
          setIsLogoScreen(false);
          timerHandler();
        }

        setSelectProgram(data);

        setTempVideo(data?.pro_array?.[0]?.mv_array);
        audioPlayer.current.audioEl.current.load();
        audioPlayer.current.audioEl.current.play();
      } else {
        MyUtil._alertMsg("m_center_pro_m_dt", data);
      }
    },
    [pro_no, rxLoginInfo.center_no, musicIdx, mParams]
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (
        mParams.current.seqIndex >= 0 &&
        mParams.current.seqVideoIndex >= 0 &&
        !MyUtil._isNull(selectProgram.pro_array)
      ) {
        setTempVideo(
          selectProgram?.pro_array[mParams.current.seqIndex]?.mv_array
        );
      }
      setLoading(false);
    }
    fetchData();
  }, [mParams.current.seqIndex, mParams.current.seqVideoIndex, selectProgram]);

  useEffect(() => {
    async function fetchData() {
      mParams.current.seqIndex = 0;
      mParams.current.seqVideoIndex = 0;

      await m_center_pro_m_dt(true);
      setIsLogoScreen(false);
      setIsPlayEx(true);

      // BR_TIME이 설정되었을 때 초기 타이머를 BR_TIME으로 설정
      if (mParams.current.BR_TIME > 0) {
        mParams.current.exTimer = mParams.current.BR_TIME;
        mParams.current.exStatus = "br";
        setPlayStatus("br");
        setExTimer(mParams.current.BR_TIME);
        timerHandler();
      } else {
        // BR_TIME이 없을 때 다른 초기 설정
        mParams.current.exTimer = mParams.current.EX_TIME;
        mParams.current.exStatus = "e";
        setPlayStatus("e");
        setExTimer(mParams.current.EX_TIME);
        timerHandler();
      }
    }

    fetchData();
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [m_center_pro_m_dt]);

  const timerHandler = useCallback(async () => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    interval.current = setInterval(function () {
      const curTime = mParams.current.exTimer;
      let nextTime: any = curTime - 1;
      let nowStatus = mParams.current.exStatus;
      let nextStatus = nowStatus;

      console.log("타이머 핸들러 내 exTimer: ", curTime); // 로그 추가
      console.log("타이머 핸들러 내 nextTime: ", nextTime); // 로그 추가

      if (nextTime < 0) {
        if (nowStatus === "br") {
          // 현재 상태가 'br' (준비)이면
          nextStatus = "e"; // 다음 상태를 'e' (운동)로 변경
          nextTime = mParams.current.EX_TIME;
          setIsTimerVisible(true);
          setIsPlayEx(true);
          setIsLogoScreen(false);
          setVolumeLevel(0.7);
        } else if (nowStatus === "r") {
          // 현재 상태가 'r' (휴식)이면
          nextStatus = "e"; // 다음 상태를 'e' (운동)로 변경
          nextTime = mParams.current.EX_TIME;
          setIsTimerVisible(true);
          setIsPlayEx(true);
          setIsLogoScreen(false);
          setVolumeLevel(0.7);
        } else if (nowStatus === "e") {
          // 현재 상태가 'e' (운동)이면
          nextStatus = "w"; // 다음 상태를 'w' (대기)로 변경
          nextTime = mParams.current.WAIT_TIME;
          setIsTimerVisible(true);
          setIsPlayEx(true);
          setIsLogoScreen(false);
          handleNextVideo();

          // if (mParams.current.isRestTime) {
          //   handleNextVideo();
          //   setVolumeLevel(0.3);
          // } else {
          //   handleNextVideo();
          //   setIsPlayEx(true);
          //   setVolumeLevel(0.3);
          //   setIsTimerVisible(false);
          //   setExTimer(mParams.current.BR_TIME);
          //   if (interval.current) {
          //     clearInterval(interval.current);
          //   }
          //   return;
          // }
        } else if (nowStatus === "w") {
          // 현재 상태가 'w' (대기)이면
          nextStatus = "e"; // 다음 상태를 'e' (운동)로 변경
          nextTime = mParams.current.EX_TIME;
          setVolumeLevel(0.7);
        }
      }

      mParams.current.exStatus = nextStatus;
      mParams.current.exTimer = nextTime;

      if (parseInt(nextTime) - 1 === 2) {
        setCountVolumeLevel(1);
        setVolumeLevel(0.1);
        setPlaySoundFile("sound_time");
        countPlayer.current.audioEl.current.play();

        if (nextStatus === "e") {
          if (
            selectProgram?.pro_array &&
            mParams.current.seqVideoIndex ===
              selectProgram?.pro_array[mParams.current.seqIndex]?.mv_array
                ?.length -
                1
          ) {
            if (
              mParams.current.seqIndex ===
              selectProgram?.pro_array?.length - 1
            ) {
              setTimeout(() => {
                setPlaySoundFile("sound_stop");
                if (nowStatus === "p") {
                  setVolumeLevel(0.7);
                } else {
                  setVolumeLevel(0.3);
                }
                countPlayer.current.audioEl.current.play();
              }, 3000);
            } else {
              setTimeout(() => {
                setPlaySoundFile("sound_rest");
                if (nowStatus === "p") {
                  setVolumeLevel(0.7);
                } else {
                  setVolumeLevel(0.3);
                }
                countPlayer.current.audioEl.current.play();
              }, 3000);
            }
          } else {
            setTimeout(() => {
              setPlaySoundFile("sound_stop");
              if (nowStatus === "p") {
                setVolumeLevel(0.7);
              } else {
                setVolumeLevel(0.3);
              }
              countPlayer.current.audioEl.current.play();
            }, 3000);
          }
        } else {
          setTimeout(() => {
            setPlaySoundFile("sound_go");
            if (nowStatus === "p") {
              setVolumeLevel(0.7);
            } else {
              setVolumeLevel(0.3);
            }
            countPlayer.current.audioEl.current.play();
          }, 3000);
        }
      }

      setExTimer(nextTime);
      setPlayStatus(nextStatus);
    }, 1000);
  }, [audioPlayer, countPlayer, selectProgram]);

  useEffect(() => {
    if (audioPlayer?.current) {
      if (!MyUtil._isNull(arrMusic)) {
        if (isBgSound) {
          audioPlayer.current.audioEl.current.load();
          audioPlayer.current.audioEl.current.play();
        } else {
          audioPlayer.current.audioEl.current.pause();
        }
      }
    }
  }, [isBgSound]);

  useEffect(() => {
    if (isCountSound) {
      countPlayer.current.audioEl.current.play();
    } else {
      countPlayer.current.audioEl.current.pause();
      countPlayer.current.audioEl.current.currentTime = 0;
    }
    countPlayer.current.audioEl.current.play();
  }, [isCountSound]);

  const handleNextVideo = useCallback(() => {
    if (
      mParams.current.seqVideoIndex <
      selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array?.length - 1
    ) {
      mParams.current.seqVideoIndex += 1;
    } else {
      if (mParams.current.seqIndex < selectProgram?.pro_array?.length - 1) {
        mParams.current.seqIndex += 1;
        mParams.current.seqVideoIndex = 0;
      } else {
        endEx();
        return;
      }
    }

    const nextVideo =
      selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array[
        mParams.current.seqVideoIndex
      ];
    const exTime = MyUtil._isNull(nextVideo?.ex_time)
      ? 0
      : parseInt(nextVideo?.ex_time);
    const waitTime = MyUtil._isNull(nextVideo?.wait_time)
      ? 0
      : parseInt(nextVideo?.wait_time);

    if (exTime === 0 || waitTime === 0) {
      if (!isModalVisible) {
        setModalMessage("EX_TIME과 WAIT_TIME을 확인해주세요.");
        openAlertModal();
        return;
      }
    }

    mParams.current.EX_TIME = exTime;
    mParams.current.WAIT_TIME = waitTime;

    setTempVideo(
      selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array
    );
  }, [selectProgram]);

  const endEx = useCallback(async () => {
    console.log("끝났다.");
    const WAIT_TIME = mParams.current.WAIT_TIME;
    if (interval.current) {
      clearInterval(interval.current);
    }
    setIsPlayEx(false);
    setIsTimerVisible(false);
    setPlayStatus("p");
    // setExTimer(WAIT_TIME);
    setIsLogoScreen(true);
    mParams.current.exTimer = WAIT_TIME;
    mParams.current.exStatus = "p";
    mParams.current.seqIndex = 0;
    mParams.current.seqVideoIndex = 0;

    if (
      mParams.current.seqIndex === selectProgram?.pro_array?.length - 1 &&
      mParams.current.seqVideoIndex ===
        selectProgram?.pro_array[mParams.current.seqIndex]?.mv_array?.length - 1
    ) {
      if (mParams.current.autoYn === "n") {
        if (!isModalVisible) {
          setModalMessage("Congratulations on your mission :)");
          openAlertModal();
          return;
        }
      } else {
        setLoading(true);
        setTimeout(() => {
          m_center_pro_m_dt(false);
        }, 200);
      }
    } else {
      // 다음 시퀀스로 이동
      mParams.current.seqIndex = 0;
      mParams.current.seqVideoIndex = 0;
    }
  }, []);

  const handlePlayPauseToggle = useCallback(() => {
    setCountVolumeLevel(0);
    if (countPlayer.current) {
      countPlayer.current.audioEl.current.currentTime = 0;
      countPlayer.current.audioEl.current.pause();
    }

    if (mParams.current.exStatus === "p") {
      mParams.current.seqIndex = 0;
      mParams.current.seqVideoIndex = 0;
      const firstVideo =
        selectProgram?.pro_array?.[mParams.current.seqIndex]?.mv_array[0];
      mParams.current.EX_TIME = MyUtil._isNull(firstVideo?.ex_time)
        ? 0
        : parseInt(firstVideo?.ex_time);
      mParams.current.WAIT_TIME = MyUtil._isNull(firstVideo?.wait_time)
        ? 0
        : parseInt(firstVideo?.wait_time);
      setIsPlayEx(true);
      setIsTimerVisible(true);
      setIsLogoScreen(true);
      mParams.current.exTimer = mParams.current.BR_TIME;
      mParams.current.exStatus = "r";
      setExTimer(mParams.current.BR_TIME);
      timerHandler();
    } else {
      if (isPlayEx) {
        setIsPlayEx(false);
        setIsTimerVisible(false);
        if (interval.current) {
          clearInterval(interval.current);
        }
      } else {
        setIsPlayEx(true);
        setIsTimerVisible(true);
        setIsLogoScreen(false);
        timerHandler();
      }
    }
  }, [selectProgram, isPlayEx]);

  return (
    <div className="container-zero-row" style={{ backgroundColor: "#000000" }}>
      {!MyUtil._isNull(arrMusic) && (
        <ReactAudioPlayer
          ref={audioPlayer}
          style={{ display: "none" }}
          src={arrMusic[musicIdx].file_nm}
          autoPlay
          controls
          loop={false}
          volume={volumeLevel}
          onEnded={() => {
            let getIdx = musicIdx;
            if (arrMusic.length > 1) {
              while (getIdx === musicIdx) {
                getIdx = Math.floor(Math.random() * arrMusic.length);
              }
            }
            setMusicIdx(getIdx);
            audioPlayer.current.audioEl.current.pause();
            setTimeout(() => {
              audioPlayer.current.audioEl.current.load();
              audioPlayer.current.audioEl.current.play();
            }, 500);
          }}
        />
      )}

      <ReactAudioPlayer
        ref={countPlayer}
        style={{ display: "none" }}
        src={
          playSoundFile === "sound_time"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amthreesecs.mp3"
            : playSoundFile === "sound_rest"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3"
            : playSoundFile === "sound_go"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amgo.mp3"
            : "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3"
        }
        autoPlay={false}
        controls
        loop={false}
        volume={countVolumeLevel}
        onEnded={() => {}}
      />

      {loading ? (
        <Loader />
      ) : (
        <div className="sbvideoplay-contain" style={{ overflowY: "hidden" }}>
          <div className="sbvideoplay-header" style={{}}>
            {isTimerVisible ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ width: 34, height: 1 }}></div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <span className="sbvideoplay-header-text">WORKOUT </span>
                  {playStatus === "e" ? (
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "5px",
                        marginBottom: "5px",
                      }}
                      src={gifHeartQuick}
                      alt="logo image"
                    />
                  ) : (
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "5px",
                        marginBottom: "5px",
                      }}
                      src={gifHeartSlow}
                      alt="logo image"
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {exTimer >= 0 && (
                    <span
                      className="sbvideoplay-header-extext"
                      style={{
                        color:
                          playStatus === "p"
                            ? "#8a8a8a"
                            : playStatus === "e"
                            ? "#ff0000"
                            : playStatus === "br"
                            ? "#8a8a8a"
                            : "#55ff55",
                      }}
                    >
                      {exTimer === 0
                        ? mParams.current.exStatus === "r" ||
                          mParams.current.exStatus === "br" ||
                          mParams.current.exStatus === "w"
                          ? "GO!"
                          : "STOP"
                        : MyUtil._secondToTimeSmallFormat(exTimer)}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="sbvideoplay-header-text">
                    {(() => {
                      let totalVideos = 0;
                      let currentVideoIndex = 0;

                      for (
                        let i = 0;
                        i < selectProgram?.pro_array?.length;
                        i++
                      ) {
                        const seq = selectProgram.pro_array[i];
                        totalVideos += seq.mv_array.length;

                        if (i < mParams.current.seqIndex) {
                          currentVideoIndex += seq.mv_array.length;
                        } else if (i === mParams.current.seqIndex) {
                          currentVideoIndex +=
                            mParams.current.seqVideoIndex + 1;
                          break;
                        }
                      }

                      return `${currentVideoIndex} / `;
                    })()}
                    {selectProgram?.pro_array?.reduce(
                      (total: any, seq: any) => total + seq.mv_array.length,
                      0
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <span
                className="sbvideoplay-header-text"
                style={{
                  fontWeight: "900",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {}}
              >
                DON'T THINK, LET'S MIIT
              </span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="sbvideoplay-left">
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5px",
                  marginBottom: "7px",
                  cursor: "pointer",
                  // filter:
                  //   "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(320deg) brightness(103%) contrast(100%)",
                }}
                src={symbol}
                alt="logo image"
                onClick={() => {
                  history.goBack();
                }}
              />
              <div
                className="sbvideoplay-leftBtnWrap"
                onClick={handlePlayPauseToggle}
              >
                <FontAwesomeIcon
                  style={{
                    width: "22px",
                    height: "22px",
                    marginLeft:
                      mParams.current.exStatus === "p"
                        ? "4px"
                        : !isPlayEx
                        ? "4px"
                        : "0px",
                  }}
                  icon={
                    mParams.current.exStatus === "p"
                      ? faPlay
                      : !isPlayEx
                      ? faPlay
                      : faPause
                  }
                />
              </div>

              <div
                className="sbvideoplay-leftBtnWrap"
                onClick={() => {
                  let getIdx = musicIdx;
                  if (arrMusic.length > 1) {
                    while (getIdx === musicIdx) {
                      getIdx = Math.floor(Math.random() * arrMusic.length);
                    }
                  }
                  setIsBgSound(true);
                  setMusicIdx(getIdx);
                  audioPlayer.current.audioEl.current.pause();
                  setTimeout(() => {
                    audioPlayer.current.audioEl.current.load();
                    audioPlayer.current.audioEl.current.play();
                  }, 500);
                }}
              >
                <FontAwesomeIcon
                  style={{ width: "22px", height: "22px", marginLeft: "6px" }}
                  icon={faForward}
                />
              </div>

              <div
                className="sbvideoplay-leftBtnWrap"
                onClick={() => {
                  setIsBgSound(!isBgSound);
                }}
              >
                <FontAwesomeIcon
                  style={{ width: "22px", height: "22px", marginLeft: "6px" }}
                  icon={isBgSound ? faVolumeHigh : faVolumeMute}
                />
              </div>

              <div className="sbvideoplay-left-scroll">
                {selectProgram?.pro_array?.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    className="sbvideoplay-leftBtnWrap-play"
                    style={{
                      backgroundColor:
                        idx === mParams.current.seqIndex
                          ? "#09348a"
                          : "#000000",
                      // color:
                      //   idx === mParams.current.seqIndex
                      //     ? "#000000"
                      //     : "#ffffff",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      console.log(`Clicked index: ${idx}`);
                      setCountVolumeLevel(0);
                      if (countPlayer.current) {
                        countPlayer.current.audioEl.current.pause();
                      }

                      let nextTime = EMPTY_BR_TIME;
                      mParams.current.seqIndex = idx;
                      mParams.current.seqVideoIndex = 0;
                      const firstVideo =
                        selectProgram?.pro_array?.[mParams.current.seqIndex]
                          ?.mv_array[mParams.current.seqVideoIndex];
                      mParams.current.EX_TIME = MyUtil._isNull(
                        firstVideo?.ex_time
                      )
                        ? 0
                        : parseInt(firstVideo?.ex_time);
                      mParams.current.WAIT_TIME = MyUtil._isNull(
                        firstVideo?.wait_time
                      )
                        ? 0
                        : parseInt(firstVideo?.wait_time);
                      setIsPlayEx(true);
                      setIsLogoScreen(false);
                      setExTimer(nextTime);
                      setIsTimerVisible(true);
                      mParams.current.exTimer = nextTime;
                      mParams.current.exStatus = "r";
                      setPlayStatus("r");
                      setTempVideo(
                        selectProgram?.pro_array?.[mParams.current.seqIndex]
                          ?.mv_array
                      );

                      timerHandler();
                    }}
                  >
                    {idx + 1}
                  </div>
                ))}
              </div>
              <div style={{ width: "1px", minHeight: "145px" }}></div>
            </div>

            <div className="sbvideoplay-right">
              {loading ? (
                <Loader />
              ) : isLogoScreen ? (
                <img
                  style={{
                    width: "200px",
                    marginBottom: "150px",
                    filter:
                      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(320deg) brightness(103%) contrast(100%)",
                  }}
                  src={logo}
                  alt="logo image"
                />
              ) : !MyUtil._isNull(tempVideo) ? (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    display: "flex",
                    position: "relative",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: tempVideo?.length === 4 ? "60%" : "100%",
                      marginTop: "0px",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {tempVideo?.map((item: any, idx: number) => {
                      let videoSize = "23.3vw";
                      let videoMargin = {
                        marginLeft: "0.2vw",
                        marginRight: "0.3vw",
                      };
                      let videoMarginBottom = "2px";

                      if (tempVideo?.length >= 7) {
                        videoSize = "23.3vw";
                      } else if (tempVideo?.length >= 5) {
                        videoSize = "24.6vw";
                      } else if (tempVideo?.length >= 4) {
                        videoSize = "25vw";
                      } else if (tempVideo?.length >= 3) {
                        videoSize = "30vw";
                        videoMarginBottom = "70px";
                      } else if (tempVideo?.length >= 2) {
                        videoSize = "47vw";
                        videoMarginBottom = "40px";
                      } else if (tempVideo?.length >= 1) {
                        videoSize = "51vw";
                        videoMarginBottom = "10px";
                      }

                      return (
                        <div
                          key={idx}
                          style={{
                            marginTop: "8px",
                            marginBottom: videoMarginBottom,
                            position: "relative",
                          }}
                        >
                          <ReactPlayer
                            key={idx}
                            width={videoSize}
                            height={videoSize}
                            style={videoMargin}
                            url={item.mv_url}
                            playing={true}
                            controls={false}
                            muted={true}
                            volume={0}
                            progressInterval={1000}
                            pip={false}
                            loop={true}
                          />

                          <p
                            style={{
                              color: "#000000",
                              fontSize: "1.8rem",
                              fontWeight: "bold",
                              position: "absolute",
                              top: 10,
                              left: 10,
                            }}
                          >
                            {item.seq_type}
                          </p>
                          <textarea
                            style={{
                              width: "70%",
                              height: "34px",
                              overflow: "hidden",
                              color: "#000000",
                              background: "none",
                              border: "none",
                              fontSize: "6px",
                              fontWeight: "500",
                              position: "absolute",
                              top: 5,
                              left: 40,
                              lineHeight: "10px",
                              backdropFilter: "blur(10px)",
                              resize: "none",
                            }}
                            readOnly
                            disabled
                          >
                            {item.mv_text}
                          </textarea>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
      <AlertModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: "999",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeAlertModal}>OK</button>
      </AlertModal>
    </div>
  );
};

const RemoteButton = styled.div`
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 10px;
`;

export default SBVideoPlay;
