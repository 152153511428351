import * as MyUtil from "./MyUtil";

// https://velog.io/@jkl1545/React-Query
// https://velog.io/@chltjdrhd777/React-Query-%EC%BA%90%EC%8B%B1%EC%97%90-%EB%8C%80%ED%95%9C-%EA%B5%AC%ED%98%84

// 요약하면 R ( Read ) 은 useQuery , CUD ( Create, Update, Delete )는 useMutation을 사용하면 된다.
// 캐시가 있는 모든 쿼리 무효화 :  queryClient.invalidateQueries();
// staleTime : 동일 파라미터 요청 금지 시간

export function m_mng_center_check(data: { center_cd: string, center_pw: string }) {
    const REQ_METHODS = "m_mng_center_check";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_login(data: { center_cd: string, model_nm: string }) {
    const REQ_METHODS = "m_mng_center_login";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export async function m_mng_alarm(center_cd: string) {
    const REQ_METHODS = "m_mng_alarm";

    const data =
        {
            center_cd
        };

    return await MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_login(data: { userid: string, password: string }) {
    const REQ_METHODS = "m_mng_login";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_pack_d(data: {
    center_pack_no: string
}) {
    const REQ_METHODS = "m_mng_center_pack_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_config(data: {
    center_no: string,
    config_type: any

}) {
    const REQ_METHODS = "m_mng_config";
    return MyUtil._httpLegacyReq(REQ_METHODS, data);
};

export function m_mng_config_i(data: {
    center_no: string,
    array: any

}) {
    const REQ_METHODS = "m_mng_config_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

// export function m_mng_config_i(data: {
//     center_no:string,
//     file_nm: string,
//     config_type: string

// }) {
//     const REQ_METHODS = "m_mng_config_i";
//     return MyUtil._httpReq(REQ_METHODS, data);
// };

export function m_mng_center_pack_i(data: {
    pack_no: string,
    c_day: string,
    center_no: any
}) {
    const REQ_METHODS = "m_mng_center_pack_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_i(data: {
    center_nm: string,
    center_pw: string,
    p_nm: string,
    p_tel: string,
    p_email: string,
    cost: string,
    cost_day: string,
    begin_date: string,
    end_date: string,
    center_cd: string,
    pay_type: string
}) {
    const REQ_METHODS = "m_mng_center_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_d(data: {
    center_no: string
}) {
    const REQ_METHODS = "m_mng_center_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_code_check(data: {
    center_cd: string,
}) {
    const REQ_METHODS = "m_mng_center_code_check";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_i(data: {
    pro_m_nm: string,
    pro_no: string,
    center_no: string,
    br_time: string,
    ex_time: string,
    wait_time: string,
    array_a: any,
    array_b: any,
    array_c: any,
    array_d: any,
    array_e: any,
    array_f: any,
    array_g: any,
    array_h: any,
    auto_yn: any
}) {
    const REQ_METHODS = "m_mng_pro_m_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_pro_i(data: {
    pack_nm: string,
    pack_no: string,
    array: any,
    center_no: string
}) {
    const REQ_METHODS = "m_mng_pack_pro_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_center_u(data: {
    center_no: string,
    center_nm: string,
    center_pw: string,
    p_nm: string,
    p_tel: string,
    p_email: string,
    cost: string,
    cost_day: string,
    begin_date: string,
    end_date: string,
    center_cd: string,
    pay_type: string
}) {
    const REQ_METHODS = "m_mng_center_u";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_pack(data: { center_no: string, mon: string }) {
    const REQ_METHODS = "m_mng_center_pack";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_center(data: { center_nm: string }) {
    const REQ_METHODS = "m_mng_center";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_sales(data: { center_no: string, mon: string }) {
    const REQ_METHODS = "m_mng_center_sales";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_center_pro_m(data: { center_no: string, c_day: string }) {
    const REQ_METHODS = "m_center_pro_m";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m(data: { center_no: string, c_day: string, pro_m_nm: string, fold_yn: string }) {
    const REQ_METHODS = "m_mng_pro_m";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pay_i(data: { center_no: string, pay_type: string, amount: string }) {
    const REQ_METHODS = "m_mng_pay_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_card_reg_complete(data: { merchant_uid: string }) {
    const REQ_METHODS = "m_mng_card_reg_complete";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_center_pro_m_dt(data: { pro_no: string }) {
    const REQ_METHODS = "m_center_pro_m_dt";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_reply(data: { pro_no: string }) {
    const REQ_METHODS = "m_mng_pro_reply";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_dt(data: { pro_no: string }) {
    const REQ_METHODS = "m_mng_pro_m_dt";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_reply(data: { pack_no: string }) {
    const REQ_METHODS = "m_mng_pack_reply";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_reply_i(data: { pro_no: string, center_no: string, contents: string }) {
    const REQ_METHODS = "m_mng_pro_reply_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_init(data: { center_no: string }) {
    const REQ_METHODS = "m_mng_center_init";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_reply_i(data: { pack_no: string, center_no: string, contents: string }) {
    const REQ_METHODS = "m_mng_pack_reply_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_book_i(data: { pro_no: string, center_no: string }) {
    const REQ_METHODS = "m_mng_pro_book_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_info(data: { pro_no: string }) {
    const REQ_METHODS = "m_mng_pro_info";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_info(data: { pack_no: string }) {
    const REQ_METHODS = "m_mng_pack_info";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_book_i(data: { center_no: string, pack_no: string }) {
    const REQ_METHODS = "m_mng_pack_book_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_reply_d(data: { pro_brd_no: string }) {
    const REQ_METHODS = "m_mng_pro_reply_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_reply_d(data: { pack_brd_no: string }) {
    const REQ_METHODS = "m_mng_pack_reply_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_fold_i(data: { pro_fold_no: string, fold_nm: string, center_no: string }) {
    const REQ_METHODS = "m_mng_pro_fold_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_fold_i(data: { pack_fold_no: string, fold_nm: string, center_no: string }) {
    const REQ_METHODS = "m_mng_pack_fold_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_fold_exec_i(data: { fold_no: string, array: string }) {
    const REQ_METHODS = "m_mng_pro_fold_exec_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_have_i(data: { center_no: string, array: any }) {
    const REQ_METHODS = "m_mng_pro_m_have_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_pro_have_i(data: { center_no: string, array: any }) {
    const REQ_METHODS = "m_mng_pack_pro_have_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_copy_i(data: { center_no: string, pro_no: any }) {
    const REQ_METHODS = "m_mng_pro_m_copy_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_copy_have_i(data: { center_no: string, pro_no: any }) {
    const REQ_METHODS = "m_mng_pro_copy_have_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_pro_copy_i(data: { center_no: string, pack_no: any }) {
    const REQ_METHODS = "m_mng_pack_pro_copy_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_fold_exec_i(data: { fold_no: string, array: string }) {
    const REQ_METHODS = "m_mng_pack_fold_exec_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_fold_d(data: { fold_no: string }) {
    const REQ_METHODS = "m_mng_pro_fold_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_fold_d(data: { fold_no: string }) {
    const REQ_METHODS = "m_mng_pack_fold_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_fold_dt(data: { fold_no: string }) {
    const REQ_METHODS = "m_mng_pro_fold_dt";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_dt(data: { fold_no: string }) {
    const REQ_METHODS = "m_mng_pack_dt";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack(data: {}) {
    const REQ_METHODS = "m_mng_pack";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv(data: { mv_category_no: string, page: any, mv_nm: string }) {
    const REQ_METHODS = "m_mng_mv";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_info_i(data: { pro_no: string, info: string, file_nm1: string, file_nm2: string, file_nm3: string, file_nm4: string, mv_url: string, }) {
    const REQ_METHODS = "m_mng_pro_info_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_mv_category(data: { mv_category_nm: string }) {
    const REQ_METHODS = "m_mng_mv_category";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_mv_category_i(data: { mv_category_nm: string }) {
    const REQ_METHODS = "m_mng_mv_category_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_mv_category_nm_u(data: { mv_category_no: string, mv_category_nm: string }) {
    const REQ_METHODS = "m_mng_mv_category_nm_u";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu(data: { page: any }) {
    const REQ_METHODS = "m_mng_tu";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv_category_d(data: {
    mv_category_no: string
}) {
    const REQ_METHODS = "m_mng_mv_category_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv_i(data: {
    mv_category_no: string,
    mv_no: string,
    mv_nm: string,
    mv_rep_img: string,
    mv_url: string,
    mv_url2: string,
    mv_url3: string
}) {
    const REQ_METHODS = "m_mng_mv_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu_i(data: {
    tu_no: string,
    tu_nm: string,
    tu_rep_img: string,
    tu_url: string
}) {
    const REQ_METHODS = "m_mng_tu_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv_d(data: {
    mv_no: string
}) {
    const REQ_METHODS = "m_mng_mv_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu_d(data: {
    tu_no: string
}) {
    const REQ_METHODS = "m_mng_tu_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_d(data: {
    pro_no: string
}) {
    const REQ_METHODS = "m_mng_pro_m_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_d(data: {
    pack_no: string
}) {
    const REQ_METHODS = "m_mng_pack_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_pro_dt(data: {
    pack_no: string
}) {
    const REQ_METHODS = "m_mng_pack_pro_dt";
    return MyUtil._httpLegacyReq(REQ_METHODS, data);
};

export async function m_mng_profile_u(formData: any) {
    const REQ_METHODS = "m_mng_profile_u";
    return await MyUtil._multiPartReq(REQ_METHODS, formData);
};
