import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import OptionControlBoxM from "./OptionControlBoxM";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import VideoListModal from "./VideoListModal";
import * as ServerApi from "../../constants/ServerApiM";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";
import { IoIosCloseCircle } from "react-icons/io";
import AlertModal from "react-modal";
import NewSaveButton from "./NewSaveButton";

const CalendarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const CalendarWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;

  &.visible {
    display: block;
    z-index: 999;
  }

  .react-calendar {
    border-radius: 15px;
    padding-bottom: 20px;
    position: relative;
  }

  .react-calendar__navigation {
    background: none;
    color: #000;
  }

  .react-calendar__navigation button:disabled {
    background: none;
    color: #000;
  }

  .react-calendar__tile--active {
    background-color: #09348a;
    border-radius: 10px;
  }

  .react-calendar__tile--now {
    background-color: rgba(9, 52, 138, 0.2);
    border-radius: 10px;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #09348a;
  }

  .react-calendar__month-view__weekdays {
    margin-top: 30px;
    color: #09348a;
  }

  .react-calendar__month-view__days {
    margin-bottom: 50px;
  }

  .calendarSaveBtn {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    background: #09348a;
    border: none;
    border-radius: 15px;
    color: #ffffff;
  }
`;

const SelectWrap = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
`;

const Select = styled.select`
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 0.8rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  box-sizing: border-box;
  background: none;
  color: #09348a;
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;

  &:active {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }

  &:hover {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }
`;

const Items = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #09348a;
  height: 100px;
  position: relative;
  color: #09348a;
  font-size: 30px;
`;

const ItemsTitle = styled.div`
  font-size: 0.9rem;
  margin-top: 30px;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 7px;

  &:checked {
    background-color: #09348a;
    border-color: #09348a;
    //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 20 8l-1.41-1.41z"/></svg>');
    //background-repeat: no-repeat;
    //background-position: center;
  }
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  left: calc(50% - 5px);
  transform: translate(-50%, 50%);
  scale: 0.9;
`;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 15px 15px 0 0;
`;

function ExerciseSetup({
  videoData,
  proNo,
  onCopy,
  onDelete,
  onSetting,
  isSelected,
  selectedItems,
  isEditMode,
}: any) {
  const history = useHistory();
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  // 캘린더 요일을 영문 표기
  const customWeekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    console.log("videoData", videoData);
  }, []);

  // 클릭 이벤트를 이용하여 달력 창을 닫기 위한 로직
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarWrapRef.current &&
        !calendarWrapRef.current.contains(event.target as Node) &&
        isCalendarOpen
      ) {
        setIsCalendarOpen(false); // 모달 외부 클릭 시 모달 닫기
      }
    };

    // 컴포넌트가 마운트되면 document 클릭 이벤트 리스너를 추가
    document.addEventListener("click", handleClickOutside);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCalendarOpen]);

  // viewDate와 selectedDate가 변경될 때마다 캘린더를 업데이트
  useEffect(() => {
    if (selectedDate) {
      setViewDate(selectedDate);
    }
  }, [selectedDate]);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  // 캘린더 아이콘을 클릭하면 달력 창을 토글
  const toggleCalendarIcon = () => {
    console.log("pro_no", proNo);
    console.log("center_no1", MLoginInfo.MLoginInfo.center_no);
    setIsCalendarOpen(!isCalendarOpen);
  };

  // 캘린더 모달을 닫는 함수
  const closeCalendarModal = () => {
    setIsCalendarOpen(false);
  };

  // 날짜가 변경될 때 호출되며, 선택한 날짜를 업데이트
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setViewDate(date);
    console.log("Selected Date:", date);
  };

  // const handleDateChange = (date: Date) => {
  //     if (selectedDate && moment(selectedDate).isSame(date, 'day')) {
  //         setSelectedDate(null);
  //     } else {
  //         setSelectedDate(date);
  //         setViewDate(date);
  //     }
  //     console.log("Selected Date:", selectedDate, "Date:", date);
  // };

  const handleSaveConfirm = async () => {
    if (!MLoginInfo || !MLoginInfo.MLoginInfo || !proNo) {
      setTimeout(() => {
        setDnStatus("error");
        setIsCalendarOpen(false);
      }, 2000);
      return;
    }

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    console.log("formattedDate", formattedDate);
    try {
      setDnStatus("loading");
      const response = await ServerApi.m_app_pro_center_i({
        center_no: MLoginInfo.MLoginInfo.center_no,
        c_day: formattedDate,
        pro_no: proNo,
      });
      console.log("m_app_pro_center_i", response, proNo);
      if (response && response.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            setIsCalendarOpen(false);
            setDnStatus("ready");
          }, 1000);
        }, 1000);
      } else {
        setTimeout(() => {
          setDnStatus("error");
          setTimeout(() => {
            setIsCalendarOpen(false);
            setDnStatus("ready");
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setDnStatus("error");
        setTimeout(() => {
          setIsCalendarOpen(false);
          setDnStatus("ready");
        }, 1000);
      }, 2000);
    }
  };

  const handleItemClick = () => {
    history.push({
      pathname: "/VideoPlay",
      state: { pro_no: videoData.pro_no },
    });
  };

  const handleListClick = () => {
    setIsModalOpen(true);
  };

  const handleSettingClick = () => {
    onSetting(proNo);
  };

  const handleChatClick = () => {
    setIsCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  // 댓글 저장 함수
  const handleSaveComment = async () => {
    if (!commentContent.trim()) {
      setModalMessage("댓글 내용을 입력해주세요.");
      setModalIsOpen(true);
      return;
    }

    try {
      const response = await ServerApi.m_mng_pro_reply_i({
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no: videoData.pro_no,
        contents: commentContent,
      });

      if (response && response.rsp_code === "100") {
        setIsCommentModalOpen(false);
        setCommentContent("");
        setModalMessage("댓글이 성공적으로 등록되었습니다.");
        setModalIsOpen(true);
      } else {
        setModalMessage("댓글등록에 실패했습니다.");
        setModalIsOpen(true);
        setIsCommentModalOpen(false);
        setCommentContent("");
      }
    } catch (error) {
      console.error("댓글 등록 중 오류 발생:", error);
      setModalMessage("댓글 등록 중 오류가 발생했습니다.");
      setModalIsOpen(true);
      setIsCommentModalOpen(false);
      setCommentContent("");
    }
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  // 연도 변경 핸들러
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setFullYear(newYear);
    setViewDate(newDate);
    setSelectedDate(null);
    console.log("Year Change:", newDate);
  };

  // 월 변경 핸들러
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setMonth(newMonth);
    setViewDate(newDate);
    setSelectedDate(null);
    console.log("Month Change:", newDate);
  };

  return (
    <>
      <Items>
        {isEditMode && (
          <CheckBox
            type="checkbox"
            checked={isSelected}
            onChange={selectedItems}
          />
        )}
        <ItemsTitle onClick={handleItemClick}>{videoData.title}</ItemsTitle>
        {/*옵션 박스*/}
        <OptionControlBoxM
          options={["heart", "chat", "setting", "list", "calendar"]}
          handleListClick={handleListClick}
          onSettingClick={handleSettingClick}
          onChatClick={handleChatClick}
          handleCalendarClick={() => toggleCalendarIcon()}
        />
      </Items>
      {isCalendarOpen && (
        <CalendarBackdrop>
          <CalendarWrap
            className={isCalendarOpen ? "visible" : ""}
            ref={calendarWrapRef}
            onClick={stopPropagation}
          >
            <CloseButton onClick={closeCalendarModal}>
              <IoIosCloseCircle />
            </CloseButton>
            <Calendar
              onChange={handleDateChange}
              // value={selectedDate !== null ? selectedDate : viewDate}
              value={viewDate}
              formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
              minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              next2Label={null} // 다음 버튼 안보이게 설정
              prev2Label={null} // 이전 버튼 안보이게 설정
              nextLabel={null} // 다음 버튼 안보이게 설정
              prevLabel={null} // 이전 버튼 안보이게 설정
              formatShortWeekday={(locale, date) =>
                customWeekdayLabels[date.getDay()]
              } // 요일부분 영문 표기
            />
            <SelectWrap>
              <Select
                value={viewDate.getFullYear()}
                onChange={handleYearChange}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => moment().year() - 2 + i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
              {/* <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Select> */}
              <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month - 1}>
                    {month + "월"}
                  </option>
                ))}
              </Select>
            </SelectWrap>
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={dnStatus}
                setDnStatus={setDnStatus}
                myClick={handleSaveConfirm}
              />
            </ButtonWrap>
          </CalendarWrap>
        </CalendarBackdrop>
      )}
      {isModalOpen && (
        <VideoListModal
          videos={videoData.in_array.map((v: any) => ({
            url: v.mv_url,
            mv_nm: v.mv_nm,
            mv_no: v.mv_no,
            mv_rep_img: v.mv_rep_img,
          }))}
          onClose={() => setIsModalOpen(false)}
          title={videoData.title}
        />
      )}
      {isCommentModalOpen && (
        <StyledModal
          isOpen={isCommentModalOpen}
          onRequestClose={closeCommentModal}
          contentLabel="Comment Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>COMMENT</ModalTitle>
          <textarea
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
            style={{
              width: "80%",
              height: "100px",
              margin: "0 auto 20px auto",
              display: "block",
              backgroundColor: "white",
              borderWidth: "1px",
              borderColor: "#000",
              borderStyle: "solid",
              resize: "none",
            }}
          />
          <NewSaveButton
            text={"SAVE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={handleSaveComment}
          />
        </StyledModal>
      )}
      {modalIsOpen && (
        <AlertModal
          isOpen={modalIsOpen}
          onRequestClose={closeAlertModal}
          contentLabel="Alert Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <h2>{modalMessage}</h2>
          <NewSaveButton
            text={"OK"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={closeAlertModal}
          />
        </AlertModal>
      )}
    </>
  );
}

export default ExerciseSetup;
