import React, { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { RootState } from "../component/redux/rootReducer";
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApiM";
import Sprintf from "sprintf-js";
import ProgramsItemContents from "./Compornent/ProgramsItemContents";
import AccountButton from "./Compornent/AccountButton";
import { Video } from "./Interface/Interface";
import styled, { createGlobalStyle } from "styled-components";
import VideoListModal from "./Compornent/VideoListModal";
import Calendar from "react-calendar";
import moment from "moment/moment";
import { IoIosCloseCircle } from "react-icons/io";
import NewSaveButton from "./Compornent/NewSaveButton";

const sprintf = Sprintf.sprintf;

const GlobalStyle = createGlobalStyle`
    .react-calendar__navigation button {
        color: #000;
        font-weight: bold;
    }

    .react-calendar__navigation button:hover {
        background-color: #cccccc;
    }

    .react-calendar__navigation__prev-button {
        font-size: 1.2rem;
        position: absolute;
        top: -4px;
        left: 100px;
        transform: translate(-50%, 50%);
    }

    .react-calendar__navigation__next-button {
        font-size: 1.2rem;
        position: absolute;
        top: -4px;
        left: 250px;
        transform: translate(-50%, 50%);
    }
`;

const DailyContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto !important;
  padding-bottom: 50px;
`;

const ProgramTiTle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: #09348a;
`;

const CalendarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const CalendarWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;

  &.visible {
    display: block;
    z-index: 999;
  }

  .react-calendar {
    border-radius: 15px;
    padding-bottom: 20px;
    position: relative;
  }

  .react-calendar__navigation {
    background: none;
    color: #000;
  }

  .react-calendar__navigation button:disabled {
    background: none;
    color: #000;
  }

  .react-calendar__tile--active {
    background-color: #09348a;
    border-radius: 10px;
  }

  .react-calendar__tile--now {
    background-color: rgba(9, 52, 138, 0.2);
    border-radius: 10px;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #09348a;
  }

  .react-calendar__month-view__weekdays {
    margin-top: 30px;
    color: #09348a;
  }

  .react-calendar__month-view__days {
    margin-bottom: 50px;
  }

  .calendarSaveBtn {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    background: #09348a;
    border: none;
    border-radius: 15px;
    color: #ffffff;
  }
`;

const SelectWrap = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
`;

const Select = styled.select`
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 0.8rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  box-sizing: border-box;
  background: none;
  color: #09348a;
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:active {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }

  &:hover {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const DayProgramM: React.FC = () => {
  const history = useHistory();
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [loading, setLoading] = useState(true);
  const [arrCalData, setArrCalData] = useState<any>([]);
  const [viewWeekNo, setViewWeekNo] = useState<any>({
    weekNo: -1,
    startNo: 0,
    endNo: 0,
    maxWeekNo: 0,
  }); // #미니캘린더용 @뷰의 주차 @한주의 시작일 @한주의 끝일 @달의 최대주차
  const [selectDay, setSelectDay] = useState<any>({ day: null, fullDay: null });
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [selectedVideos, setSelectedVideos] = useState<
    {
      url: string;
      mv_nm: string;
      mv_no: string;
      mv_rep_img: string;
    }[]
  >([]);
  const [title, setTitle] = useState();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isCalendarSelectModal, setIsCalendarSelectModal] = useState(false);
  // 캘린더 요일을 영문 표기
  const customWeekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);

  const fetchVideos = async (selectedDay: string) => {
    const centerNo = MLoginInfo?.MLoginInfo.center_no;
    console.log("centerNo", centerNo);
    if (!centerNo) {
      console.error("센터 정보가 없습니다.");
      setLoading(false);
      return;
    }

    setLoading(true); // 로딩 시작

    try {
      const response = await ServerApi.m_app_center_pro_m({
        center_no: MLoginInfo.MLoginInfo.center_no,
        c_day: selectedDay,
      });
      // API 응답 확인 및 데이터 처리
      if (response && response.rsp_code === "100") {
        setVideos(response.array);

        const allVideos = response.array.flatMap((video: any) =>
          video.in_array.map((v: any) => ({
            url: v.mv_url,
            title: v.mv_nm,
            mv_no: v.mv_no,
            thumbnailUrl: v.mv_rep_img,
          }))
        );
        setSelectedVideos(allVideos);
        setTitle(response.data.title);
      } else {
        console.error("데이터를 가져오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const today = new Date().toISOString().split("T")[0];
    if (isMounted) fetchVideos(today);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleVideoSelect = useCallback((video: any) => {
    if (video && video.in_array) {
      const selected = video.in_array.map((v: any) => ({
        url: v.mv_url,
        title: v.mv_nm,
        mv_no: v.mv_no,
        mv_rep_img: v.mv_rep_img,
        thumbnailUrl: v.mv_rep_img,
      }));
      setSelectedVideos(selected);
      console.log("선택된 비디오의 데이터들", video);
      console.log("선택된 pro_no", video.pro_no);
    }
  }, []);

  const renderVideos = () => {
    return videos.map((video, index) => (
      <ProgramsItemContents
        key={index}
        onSelectVideo={() => handleVideoSelect(video)}
        onListClick={handleListClick}
        fetchVideos={fetchVideos}
        videoData={{
          url: video.in_array[0].mv_url,
          title: video.pro_m_nm,
          in_array: video.in_array,
          mv_nm: video.in_array[0].mv_nm,
          mv_rep_img: video.in_array[0].mv_rep_img,
          pro_no: video.pro_no,
        }}
        videos={videos}
        setVideos={setVideos}
        selectedDate={selectDay.fullDay}
        DailySetup={false}
      />
    ));
  };

  useEffect(() => {
    console.log("videos", videos);
  }, []);

  useEffect(() => {
    const today = new Date();
    initCalendar(today, true, true);
  }, []);

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const initCalendar = useCallback(
    async (getDate, isWeekStart, isReset) => {
      const calData = [];
      const firstDate = new Date(getDate.getFullYear(), getDate.getMonth(), 1);
      const lastDate = new Date(
        getDate.getFullYear(),
        getDate.getMonth() + 1,
        0
      );
      const week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

      // 첫 달 시작 요일 무시하고 1일부터 시작
      for (let dayCnt = 1; dayCnt <= lastDate.getDate(); dayCnt++) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          dayCnt
        );
        const dayOfWeek = week[new Date(fullDay).getDay()];
        calData.push({
          day: dayCnt.toString(),
          fullDay: fullDay,
          dayOfWeekKor: dayOfWeek,
        });
        MyUtil._consoleLog(
          "day : " +
            dayCnt +
            " / fullDay : " +
            fullDay +
            " / dayOfWeekKor : " +
            dayOfWeek
        );
      }

      const maxWeekNo = Math.ceil(calData.length / 7);

      // 최초 세팅 또는 뷰 변경 시
      if (viewWeekNo.weekNo === -1 || isReset) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          getDate.getDate()
        );
        SelectCalDay(
          {
            day: getDate.getDate(),
            fullDay: fullDay,
          },
          getDate.getDate() - 1,
          { maxWeekNo },
          false
        );
      } else {
        if (isWeekStart) {
          setViewWeekNo({ weekNo: 1, startNo: 0, endNo: 6, maxWeekNo });
        } else {
          const weekNo = maxWeekNo;
          const startNo = (weekNo - 1) * 7;
          const endNo = startNo + 6;
          setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo });
        }
      }

      setViewDate(getDate);
      setArrCalData(calData);
    },
    [viewWeekNo]
  );

  const SelectCalDay = useCallback(
    async (item, idx, getViewWeelNo, isLoading) => {
      console.log("SelectCalDay 호출됨, 선택된 날짜:", item.fullDay); // 로그 추가
      const weekNo = Math.floor(idx / 7) + 1;
      const startNo = (weekNo - 1) * 7;
      const endNo = startNo + 6;

      setViewWeekNo({
        weekNo,
        startNo,
        endNo,
        maxWeekNo: getViewWeelNo.maxWeekNo,
      });
      setSelectDay({ day: item.day, fullDay: item.fullDay });
      fetchVideos(item.fullDay); // 선택된 날짜로 fetchVideos 호출
    },
    []
  );

  const WeekPrev = useCallback(
    async (getViewDate) => {
      const weekNo = viewWeekNo.weekNo - 1;
      if (weekNo <= 0) {
        getViewDate.setMonth(getViewDate.getMonth() - 1);
        const newDate = new Date(getViewDate);
        setViewDate(newDate);
        initCalendar(newDate, false, false);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo]
  );

  const WeekNext = useCallback(
    async (getViewDate) => {
      const weekNo = viewWeekNo.weekNo + 1;
      MyUtil._consoleLog(
        "weekNo : " +
          weekNo +
          " / viewWeekNo.maxWeekNo : " +
          viewWeekNo.maxWeekNo
      );

      if (weekNo > viewWeekNo.maxWeekNo) {
        getViewDate.setMonth(getViewDate.getMonth() + 1);
        const newDate = new Date(getViewDate);
        setViewDate(newDate);
        initCalendar(newDate, true, false);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo]
  );

  const renderDayItem = (idx: number, item: any) => {
    const isSelectedDay = item.fullDay === selectDay.fullDay;
    return MyUtil._isNull(item.day) ? (
      <></>
    ) : (
      <div
        key={idx}
        className={`dayProgramCalBox ${
          isSelectedDay ? "dayProgramCalBoxOn selected" : ""
        }`}
        onClick={() => {
          console.log("날짜 클릭됨:", item.fullDay);
          SelectCalDay(item, idx, viewWeekNo, loading);
        }}
      >
        <span className="calBoxWeek">{item.dayOfWeekKor}</span>
        <span className="calBoxDay">{item.day}</span>
      </div>
    );
  };

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const handlers = useSwipeable({
    onSwipedLeft: () => WeekNext(viewDate),
    onSwipedRight: () => WeekPrev(viewDate),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const buttonToShow = ["logoOn", "play", "profile", "setup"];

  const [isVideoListModalOpen, setIsVideoListModalOpen] = useState(false);

  const handleListClick = () => {
    setIsVideoListModalOpen(true);
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  // 캘린더 모달을 닫는 함수
  const closeCalendarModal = () => {
    setIsCalendarSelectModal(false);
  };

  // 날짜 선택 핸들러
  const onDateChange = (value: Date) => {
    const selectedDate = moment(value).format("YYYY-MM-DD");
    setSelectDay({ day: value.getDate(), fullDay: selectedDate });
    setViewDate(value); // 추가
  };

  // 'SAVE' 버튼 클릭 핸들러
  const onSaveClick = () => {
    console.log("selectDay", selectDay);
    if (selectDay.fullDay) {
      const selectedDate = new Date(selectDay.fullDay);
      initCalendar(selectedDate, true, true);
      fetchVideos(selectDay.fullDay);
      setIsCalendarSelectModal(false); // 달력 모달 닫기
    }
  };

  // 연도 변경 핸들러
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setFullYear(newYear);
    setViewDate(newDate);
    setSelectDay({
      day: newDate.getDate(),
      fullDay: moment(newDate).format("YYYY-MM-DD"),
    }); // 추가
  };

  // 월 변경 핸들러
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setMonth(newMonth);
    setViewDate(newDate);
    setSelectDay({
      day: newDate.getDate(),
      fullDay: moment(newDate).format("YYYY-MM-DD"),
    }); // 추가
  };

  return (
    <>
      <div className="mobileContentsContainer">
        <div className="mobileContentsRow">
          <ProgramTiTle
            onClick={() => {
              setIsCalendarSelectModal(true);
            }}
          >
            {viewDate.getFullYear()}. {viewDate.getMonth() + 1}
          </ProgramTiTle>
        </div>

        <div className="mobileContentsRow">
          <div {...handlers} className="calendarDiv">
            {arrCalData.map(
              (item: any, idx: number) =>
                idx >= viewWeekNo.startNo &&
                idx <= viewWeekNo.endNo &&
                renderDayItem(idx, item)
            )}
          </div>
        </div>
        <DailyContent>{renderVideos()}</DailyContent>
      </div>
      <div className={"buttonContainer"}>
        {MLoginInfo && MLoginInfo.MLoginInfo && (
          <AccountButton
            buttonUse={buttonToShow}
            mod_yn={MLoginInfo.MLoginInfo.mod_yn}
            member_type={MLoginInfo.MLoginInfo.member_type}
          />
        )}
      </div>

      {isVideoListModalOpen && (
        <VideoListModal
          videos={selectedVideos}
          title={title || ""}
          onClose={() => setIsVideoListModalOpen(false)}
        />
      )}

      {isCalendarSelectModal && (
        <CalendarBackdrop>
          <GlobalStyle />
          <CalendarWrap
            className={isCalendarSelectModal ? "visible" : ""}
            ref={calendarWrapRef}
            onClick={stopPropagation}
          >
            <CloseButton onClick={closeCalendarModal}>
              <IoIosCloseCircle />
            </CloseButton>
            <Calendar
              onChange={onDateChange}
              onActiveStartDateChange={() => {}}
              value={viewDate} // 수정된 부분
              formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
              minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              // nextLabel={null} // 다음 버튼 안보이게 설정
              next2Label={null} // 다음 버튼 안보이게 설정
              // prevLabel={null} // 이전 버튼 안보이게 설정
              prev2Label={null} // 이전 버튼 안보이게 설정
              formatShortWeekday={(locale, date) =>
                customWeekdayLabels[date.getDay()]
              } // 요일부분 영문 표기
              tileContent={({ date, view }) => {
                // 날짜 타일에 컨텐츠 추가하기 (html 태그)
                let html: any = [];

                return <div className="calitem">{html}</div>;
              }}
            />
            <SelectWrap>
              <Select
                value={viewDate.getFullYear()}
                onChange={handleYearChange}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => moment().year() - 2 + i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
              <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month - 1}>
                    {month + "월"}
                  </option>
                ))}
              </Select>
            </SelectWrap>
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={"ready"}
                setDnStatus={""}
                myClick={onSaveClick}
              />
            </ButtonWrap>
          </CalendarWrap>
        </CalendarBackdrop>
      )}
    </>
  );
};

export default DayProgramM;
