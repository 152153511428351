import React, { useEffect, useRef, useState } from "react";
import AccountButton from "../mobile-pages/Compornent/AccountButton";
import * as ServerApi from "../constants/ServerApiM";
import { RootState } from "../component/redux/rootReducer";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Config from "../constants/Config";
import ImgProfile from "../component/ImgProfile";
import AlertModal from "react-modal";
import NewSaveButton from "./Compornent/NewSaveButton";

interface UserInfo {
  email?: string;
  handphone?: string;
  userid?: string;
  member_type?: string;
  member_type_nm?: string;
  term?: string;
  name?: string;
  lesson_fee?: string;
  lesson_ct?: string;
  profile_img?: string;
}

const MyAccountContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 55px;
  background-color: #09348a;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10%;
  border-bottom: 1px solid #ffffff;
`;

const UserInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  width: 100%;
  height: 90%;
`;

const UserInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cccccc;
  padding: 10px;
  align-items: flex-start;
  justify-content: center;

  .userInfoHeader {
    font-size: 0.8rem;
    color: #999999;
    margin-bottom: 5px;
  }

  &:first-child {
    grid-column: 1 / -1;
    background-color: #09348a;
    color: #000;
  }

  &:last-child {
    border: none;
  }

  &.first_m2 {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
`;

const UserInfoTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const UserInfoImg = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserInfoTitle = styled.p`
  font-size: 0.7rem;
  font-weight: 500;
  color: #858585;
  text-align: left;
  padding-left: 5px;
  margin-bottom: 10px;

  &.title {
    color: #cccccc;
  }
`;

const UserInfoData = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  text-align: left;
  padding-left: 5px;

  &:first-child {
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
  }

  &.title {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
  }
`;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  margin-bottom: 50px;
  border-radius: 15px 15px 0 0;
`;

const StyledInputContainer = styled.div`
  width: 80%;
  margin: 10px 0 30px 0;
  position: relative;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 75%;
  padding: 10px;
  background: none;
  border: none;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

function MyAccount() {
  const buttonToShow = ["logo", "play", "profileOn", "setup"];
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const [pwModalOpen, setPwModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // 이미지 업로드
  const [imgFile, setImgFile] = useState<any>(null);
  const [DELimgUrl, setDelImgUrl] = useState<any>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // 이미지 업로드 end

  useEffect(() => {
    console.log("현재 MLoginInfo 상태:", MLoginInfo);
  }, [MLoginInfo]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (MLoginInfo && MLoginInfo.MLoginInfo && MLoginInfo.MLoginInfo.u_id) {
        try {
          const response = await ServerApi.m_app_info({
            u_id: MLoginInfo.MLoginInfo.u_id,
          });
          console.log("API 응답 데이터:", response);

          if (response) {
            if (response.profile_img) {
              response.profile_img =
                Config.M_IMG + "profile/" + response.profile_img;
            }
            setUserInfo(response);
          }
        } catch (error) {
          console.error("사용자 정보 가져오기 실패:", error);
        }
      }
    };
    fetchUserInfo();
  }, [MLoginInfo]);

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  // 휴대폰 번호 자동으로 '-' 넣는 함수
  function formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length === 11) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        7
      )}-${phoneNumber.slice(7)}`;
    }
    return phoneNumber;
  }

  // 한국 원화로 포맷하는 함수
  function formatCurrency(amount: any) {
    return new Intl.NumberFormat("ko-KR", {
      style: "currency",
      currency: "KRW",
    }).format(amount);
  }

  // 일수를 연수, 남은 일수로 포맷하는 함수
  function formatDuration(days: any) {
    const daysPerYear = 365;
    const years = Math.floor(days / daysPerYear);
    const remainingDays = days % daysPerYear;

    if (years === 0) {
      return `${remainingDays}일`;
    } else {
      return `${years}년 ${remainingDays}일`;
    }
  }

  let memberTypeDisplay: string;
  if (userInfo.member_type === "m1") {
    memberTypeDisplay = "관리자";
  } else if (userInfo.member_type === "m2") {
    memberTypeDisplay = "강사";
  } else {
    memberTypeDisplay = userInfo.member_type || "undefined";
  }

  // 이미지 업로드 함수
  const handleImageUpload = async (selectedFile: any) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("image", selectedFile);

    const u_id = MLoginInfo.MLoginInfo.u_id;
    formData.append("u_id", u_id);

    console.log(formData);
    try {
      const result = await ServerApi.m_app_profile_u(formData);
      if (result && result.IS_SUCCESS === true) {
        setModalMessage("이미지 업로드 성공.");
        setModalIsOpen(true);
      } else {
        setModalMessage("이미지 업로드 실패.");
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("이미지 업로드 중 오류 발생:", error);
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword) {
      setPwModalOpen(false);
      setModalMessage("새로운 패스워드를 입력해주세요");
      setModalIsOpen(true);
      return;
    }

    try {
      const data = {
        u_id: MLoginInfo.MLoginInfo.u_id,
        password: newPassword,
      };
      console.log("패스워드 변경 데이타", data);
      const response = await ServerApi.m_app_my_pwd_change(data);
      console.log("패스워드 변경 리스폰스", response);
      if (response.rsp_code === "100") {
        setChangePasswordSuccess(true);
        setPwModalOpen(false);
        setNewPassword("");
      } else {
        setPwModalOpen(false);
        setModalMessage("패스워드 변경 실패");
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("비밀번호 변경 오류:", error);
      setModalMessage("패스워드 변경 실패");
      setModalIsOpen(true);
    }
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  const renderUserInfo = () => {
    switch (userInfo.member_type) {
      case "m1": // 비즈니스 계정
        return (
          <UserInfoContainer>
            <UserInfoWrap>
              <UserInfoTitleWrap>
                {userInfo.profile_img ? (
                  <ImgProfile
                    item={{ url: "", uid: "", anchor: "" }}
                    width={"40px"}
                    height={"40px"}
                    marginRight={"10px"}
                    setImgFile={setImgFile}
                    defaultImg={userInfo.profile_img}
                    setDelImgUrl={setDelImgUrl}
                    onImageSelected={handleImageUpload}
                  />
                ) : (
                  <ImgProfile
                    item={{ url: "", uid: "", anchor: "" }}
                    width={"40px"}
                    height={"40px"}
                    marginRight={"10px"}
                    setImgFile={setImgFile}
                    defaultImg={imgFile}
                    setDelImgUrl={setDelImgUrl}
                    onImageSelected={handleImageUpload}
                  />
                )}
                <UserInfoData className={"title"}>{userInfo.name}</UserInfoData>
              </UserInfoTitleWrap>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>대표자명</UserInfoTitle>
              <UserInfoData>{memberTypeDisplay}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>운영기간</UserInfoTitle>
              <UserInfoData>{formatDuration(userInfo.term)}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>ID(이메일)</UserInfoTitle>
              <UserInfoData>{userInfo.userid}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>전화번호</UserInfoTitle>
              <UserInfoData>
                {formatPhoneNumber(userInfo.handphone || "")}
              </UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>등급</UserInfoTitle>
              <UserInfoData>{memberTypeDisplay}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>이용요금</UserInfoTitle>
              <UserInfoData>{formatCurrency(userInfo.lesson_fee)}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap style={{ border: "none" }}>
              <UserInfoTitle>이용기간</UserInfoTitle>
              <UserInfoData>{formatDuration(userInfo.term)}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap
              onClick={() => {
                setPwModalOpen(true);
              }}
            >
              <UserInfoTitle>비밀번호</UserInfoTitle>
              <UserInfoData>****</UserInfoData>
            </UserInfoWrap>
          </UserInfoContainer>
        );
      case "m2": // 강사 계정
        return (
          <UserInfoContainer>
            <UserInfoWrap className={"first_m2"}>
              {userInfo.profile_img ? (
                <ImgProfile
                  item={{ url: "", uid: "", anchor: "" }}
                  width={"40px"}
                  height={"40px"}
                  marginRight={"10px"}
                  setImgFile={setImgFile}
                  defaultImg={userInfo.profile_img}
                  setDelImgUrl={setDelImgUrl}
                  onImageSelected={handleImageUpload}
                />
              ) : (
                <ImgProfile
                  item={{ url: "", uid: "", anchor: "" }}
                  width={"44px"}
                  height={"44px"}
                  setImgFile={setImgFile}
                  defaultImg={imgFile}
                  setDelImgUrl={setDelImgUrl}
                  onImageSelected={handleImageUpload}
                />
              )}
              <UserInfoData className={"title"}>{userInfo.name}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>등급</UserInfoTitle>
              <UserInfoData>{memberTypeDisplay}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>근무기간</UserInfoTitle>
              <UserInfoData>{formatDuration(userInfo.term)}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>ID(이메일)</UserInfoTitle>
              <UserInfoData>{userInfo.userid}</UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap>
              <UserInfoTitle>전화번호</UserInfoTitle>
              <UserInfoData>
                {formatPhoneNumber(userInfo.handphone || "")}
              </UserInfoData>
            </UserInfoWrap>
            <UserInfoWrap
              onClick={() => {
                setPwModalOpen(true);
              }}
            >
              <UserInfoTitle>비밀번호</UserInfoTitle>
              <UserInfoData>****</UserInfoData>
            </UserInfoWrap>
          </UserInfoContainer>
        );
      default:
        return <div>Unknown member type</div>;
    }
  };

  return (
    <MyAccountContainer>
      <Header>
        <p>MY ACCOUNT</p>
      </Header>
      {renderUserInfo()}
      <AccountButton
        buttonUse={buttonToShow}
        mod_yn={MLoginInfo.MLoginInfo.mod_yn}
      />
      <StyledModal
        isOpen={pwModalOpen}
        onRequestClose={() => setPwModalOpen(false)}
        contentLabel="Password Edit"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "999",
          },
        }}
      >
        <ModalTitle>New Password</ModalTitle>
        <StyledInputContainer>
          <StyledInput
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Edit Password "
          />
        </StyledInputContainer>
        <NewSaveButton
          text={"SAVE"}
          dnStatus={dnStatus}
          setDnStatus={setDnStatus}
          myClick={handleChangePassword}
        />
      </StyledModal>
      <AlertModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "999",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeAlertModal}>OK</button>
      </AlertModal>
    </MyAccountContainer>
  );
}

export default MyAccount;
