import React, { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../component/redux/allActions";
import * as ServerApi from "../constants/ServerApiM";
import * as MyUtil from "../constants/MyUtilM";
import ModalVideoSelect from "../mobile-pages/Compornent/ModalVideoSelectM2";
import "../css/DragItem.css";
import { RootState } from "../component/redux/rootReducer";
import styled from "styled-components";
import MobileHeader from "./Compornent/MobileHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faEllipsisH,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { ItemType, UpdateStateFunctions } from "./Interface/Interface";
import AccountButton from "./Compornent/AccountButton";
import AlertModal from "react-modal";
import { FaCheck, FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import Switch from "react-switch";
import icRepeat from "../img/ic_repeat.png";
import { ReactSortable } from "react-sortablejs";
import { IoMdShare } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { IoTrashOutline } from "react-icons/io5";

interface SelectedVideos {
  [key: string]: boolean;
}

interface TabButtonProps {
  active: boolean;
}

interface ProgramsVideoContainerProps {
  isModalOpen: boolean;
}

const ProgramsVideoContainer = styled.div<ProgramsVideoContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-top: 70px;

  .containerWrap {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .hamburgerIcon {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .hamburgerWrap {
    position: absolute;
    top: 15px;
    right: 50px;
    background-color: #cccccc;
    border-radius: 15px;
    padding: 10px 15px;
    z-index: 999;
    text-align: left;
  }

  .hamburgerWrap li {
    margin: 15px 0;
    font-size: 1rem;
    font-weight: 500;
  }

  .hamburgerWrap li:hover {
    font-weight: 700;
  }
`;

const Overlay = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.85);
  display: ${({ isModalOpen }) => (isModalOpen ? "block" : "none")};
  z-index: 99;
`;

const VideoContents = styled.div`
  height: 100%;
  max-height: 60vh;
  overflow-y: auto;
`;

const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  margin: 15px 15px 0 15px;
`;

const TabButton = styled.div<TabButtonProps>`
  flex-grow: 1;
  font-size: 0.6rem;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  color: ${(props) => (props.active ? "#09348a;" : "#000")};
  //background-color: ${(props) => (props.active ? "#f0f0f0" : "transparent")};
  border-bottom: ${(props) => (props.active ? "2px solid #09348a" : "none")};
`;

// 영상 UI
const VideoControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  width: 90%;
`;

const DeleteButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: #ff392b;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoNumber = styled.div`
  position: absolute;
  top: 8px;
  left: 24px;
  color: #0a0a0a;
  font-size: 10px;
  font-weight: 500;
`;

const TimeWrap = styled.div`
  position: absolute;
  top: 8px;
  right: 22px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;

  span {
    margin-right: 2px;
  }
`;

const VideoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 60px;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
`;

const VideoWrapper = styled.div`
  text-align: center;
  background-color: #ffffff;
  position: relative;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  width: calc(33.33% - 4px);
  min-height: 10vh;

  video,
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const Checkbox = styled.input`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #09348a;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-color: #09348a;
    border: none;
  }

  &:disabled {
    background-color: #858585;
    border: none;
  }
`;

const VideoTitleWrap = styled.div`
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 5px;
`;

const VideoTitle = styled.div`
  font-size: 10px;
  flex: 0 0 83%;
  margin: auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
`;

const CloneBtn = styled.div`
  position: relative;
  font-size: 8px;
  flex: 0 0 10%;
  margin: auto 0;
`;

const SubmitContainer = styled.div`
  position: absolute;
  top: 12px;
  right: -50px;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const SubmitButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
  align-items: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #09348a;

  &:focus {
    outline: none;
  }

  &:active {
    color: #eeeeee;
    background-color: #09348a;
    border: none;
  }
`;

const CancelButton = styled(SubmitButton)``;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff !important;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  margin-bottom: 50px;
  border-radius: 15px 15px 0 0;
`;

const StyledInputContainer = styled.div`
  width: 80%;
  margin: 0 0 30px 0;
  position: relative;
  border-bottom: 1px solid #09348a;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 75%;
  padding: 10px;
  background: none;
  border: none;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const VideoTitleContents = styled.div`
  width: 100%;
  grid-area: 1 / 1 / 2 / 4;
  flex-direction: row;
  color: #000;
  text-align: left;
  border: none;

  ::after {
    content: "";
    width: 92%;
    border-bottom: 2px solid #000;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const TotalTime = styled.div`
  grid-area: 2 / 1 / 2 / 4;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.4rem;

  span {
    font-size: 1.6rem;
  }
`;

const InputTitle = styled.input`
  width: calc(100% - 50px);
  background: none;
  border: none;
  margin-left: 10px;
  font-weight: 600;
`;

const ProgramsVideoM2: React.FC = ({ location }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  const [branchName, setBranchName] = useState("");
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  //   const pro_no = MyUtil._isNull(location?.state?.pro_no)
  //     ? ""
  //     : location?.state?.pro_no;

  // To do MIIT
  const initialProNo = MyUtil._isNull(location?.state?.pro_no)
    ? ""
    : location?.state?.pro_no;
  const [proNo, setProNo] = useState(initialProNo);
  const [newProNo, setNewProNo] = useState("");
  const [brTime, setBrTime] = useState<string>("0");

  // 시퀀스, 영상 배열 관련
  const [seqType, setSeqType] = useState<string>("A");
  const [arrVideoA, setArrVideoA] = useState<ItemType[]>([]);
  const [arrVideoB, setArrVideoB] = useState<ItemType[]>([]);
  const [arrVideoC, setArrVideoC] = useState<ItemType[]>([]);
  const [arrVideoD, setArrVideoD] = useState<ItemType[]>([]);
  const [arrVideoE, setArrVideoE] = useState<ItemType[]>([]);
  const [arrVideoF, setArrVideoF] = useState<ItemType[]>([]);
  const [arrVideoG, setArrVideoG] = useState<ItemType[]>([]);
  const [arrVideoH, setArrVideoH] = useState<ItemType[]>([]);

  const [checked, setChecked] = useState(false);

  const [videoList, setVideoList] = useState<ItemType[]>([]);
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<ItemType | null>(null);
  const [selectedVideoSeqType, setSelectedVideoSeqType] = useState<
    string | null
  >(null);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(
    null
  );
  const [selectedVideos, setSelectedVideos] = useState<SelectedVideos>({});
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [checkboxVisible, setCheckboxVisible] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const [videoPlayStates, setVideoPlayStates] = useState<any>({});

  // 수정모드를 위한 상태
  const [editMode, setEditMode] = useState(false);

  const buttonToShow = ["videoAdd", "stopWatch", "vibe"];

  const [activePage, setActivePage] = useState("exercise");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // 시간 설정 모달
  const [exerciseTime, setExerciseTime] = useState(0);
  const [restTime, setRestTime] = useState(0);
  const [breakTime, setBreakTime] = useState(0);
  const [timeSettingMode, setTimeSettingMode] = useState("");
  const [isTimeInputModalOpen, setIsTimeInputModalOpen] = useState(false);

  const [videoUpdatesCompleted, setVideoUpdatesCompleted] = useState(false);

  useEffect(() => {
    if (newProNo !== "") {
      setProNo(newProNo);
    }
  }, [newProNo]);

  // 어떤 시간을 설정하는지 설정 핸들링
  const handleOpenTimeSettingModal = (mode: any) => {
    if (!proNo) {
      setModalIsOpen(true);
      setModalMessage("입력가능한 영상이 없습니다.");
    } else {
      setTimeSettingMode(mode); // 'exercise', 'rest', 'break'
      setIsTimeInputModalOpen(true);
    }
  };

  // 입력 처리 로직
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    const time = parseInt(value, 10);

    if (!isNaN(time) && time >= 0) {
      // 입력된 시간이 유효한 경우, 상태 업데이트
      switch (timeSettingMode) {
        case "exercise":
          setExerciseTime(time);
          break;
        case "rest":
          setRestTime(time);
          break;
        case "break":
          setBreakTime(time);
          break;
        default:
          console.error("Unknown time setting mode:", timeSettingMode);
      }
    } else {
      console.error("Invalid input");
    }
  };

  const updateAllTimes = async () => {
    const data = {
      m_ex_time: timeSettingMode === "exercise" ? exerciseTime.toString() : "",
      m_wait_time: timeSettingMode === "rest" ? restTime.toString() : "",
      br_time: timeSettingMode === "break" ? breakTime.toString() : "",
      pro_no: proNo,
    };
    console.log("어떤 시간 데이터를 입력했나", data);

    try {
      const response = await ServerApi.m_app_pro_mv_time_all_set(data);
      if (response.rsp_code === "100") {
        setIsTimeInputModalOpen(false);
        m_app_pro_m_dt();
        console.log("Time update success:", response);
      } else {
        console.error("Time update failed:", response.msg);
      }
    } catch (error) {
      console.error("Error updating time:", error);
    }
  };
  // 시간 설정 모달 끝

  useEffect(() => {
    console.log(`pro_no 변경됨: ${proNo}`);
  }, [proNo]);

  const calculateTotalTimes = () => {
    const lists = [
      arrVideoA,
      arrVideoB,
      arrVideoC,
      arrVideoD,
      arrVideoE,
      arrVideoF,
      arrVideoG,
      arrVideoH,
    ];
    let totalExTime = 0;
    let totalRestTime = 0;
    let totalBreakTime = 0;

    lists.forEach((list, index) => {
      list.forEach((video) => {
        totalExTime += parseInt(video.ex_time || "0", 10);
        totalRestTime += parseInt(video.wait_time || "0", 10);
      });

      if (
        index < lists.length - 1 &&
        list.length > 0 &&
        lists[index + 1].length > 0
      ) {
        totalBreakTime += parseInt(brTime, 10);
      }
    });

    const formattedTotalExTime = MyUtil._secondToTimeFormat(totalExTime);
    const formattedTotalRestTime = MyUtil._secondToTimeFormat(totalRestTime);
    const formattedTotalBrTime = MyUtil._secondToTimeFormat(totalBreakTime);

    return {
      totalExTime: formattedTotalExTime,
      totalRestTime: formattedTotalRestTime,
      totalBrTime: formattedTotalBrTime,
    };
  };

  const { totalExTime, totalRestTime } = calculateTotalTimes();

  function handleHeaderButtonClick(page: string) {
    setActivePage(page);
  }

  useEffect(() => {
    if (proNo) {
      setCheckboxVisible(isContextMenuActive);
    }
  }, [isContextMenuActive]);

  useEffect(() => {
    if (proNo) {
      m_app_pro_m_dt();
    }
  }, [proNo, MLoginInfo.MLoginInfo, history, dispatch, allActions]);

  const m_app_pro_m_dt = useCallback(async () => {
    try {
      const result = await ServerApi.m_app_pro_m_dt({ pro_no: proNo });
      console.log("m_app_pro_m_dt data", result);

      if (result.rsp_code === "100") {
        setBranchName(result.pro_m_nm);
        setChecked(result.auto_yn === "y");
        setBrTime(result.br_time.toString());

        const exTime = parseInt(result.m_ex_time, 10) || 0;
        const restTime = parseInt(result.m_wait_time, 10) || 0;
        const breakTime = parseInt(result.br_time, 10) || 0;
        setExerciseTime(exTime);
        setRestTime(restTime);
        setBreakTime(breakTime);

        // 각 리스트에 대한 상태 업데이트 함수 매핑 및 업데이트
        const updateStateFunctions: UpdateStateFunctions = {
          list_a: setArrVideoA,
          list_b: setArrVideoB,
          list_c: setArrVideoC,
          list_d: setArrVideoD,
          list_e: setArrVideoE,
          list_f: setArrVideoF,
          list_g: setArrVideoG,
          list_h: setArrVideoH,
        };

        Object.keys(updateStateFunctions).forEach((listName) => {
          const videoList = result[listName] || [];
          const setStateFunction = updateStateFunctions[listName];
          if (setStateFunction) {
            const updatedList = videoList.map((video: ItemType) => ({
              ...video,
              ex_time: video.ex_time || "0",
              wait_time: video.wait_time || "0",
            }));
            setStateFunction(updatedList);
          }
        });
      }
    } catch (error) {
      console.error("Failed to load program data:", error);
    }
  }, [proNo]);

  // todo 아래로 MIIT
  const ModalVideoCb = useCallback(
    async (isOk: boolean, selectedVideos: ItemType[]) => {
      setIsVideoIuModalOpen(false);
      if (isOk) {
        let newArrVideoA = arrVideoA;
        let newArrVideoB = arrVideoB;
        let newArrVideoC = arrVideoC;
        let newArrVideoD = arrVideoD;
        let newArrVideoE = arrVideoE;
        let newArrVideoF = arrVideoF;
        let newArrVideoG = arrVideoG;
        let newArrVideoH = arrVideoH;

        switch (seqType) {
          case "A":
            newArrVideoA = [...arrVideoA, ...selectedVideos];
            setArrVideoA(newArrVideoA);
            break;
          case "B":
            newArrVideoB = [...arrVideoB, ...selectedVideos];
            setArrVideoB(newArrVideoB);
            break;
          case "C":
            newArrVideoC = [...arrVideoC, ...selectedVideos];
            setArrVideoC(newArrVideoC);
            break;
          case "D":
            newArrVideoD = [...arrVideoD, ...selectedVideos];
            setArrVideoD(newArrVideoD);
            break;
          case "E":
            newArrVideoE = [...arrVideoE, ...selectedVideos];
            setArrVideoE(newArrVideoE);
            break;
          case "F":
            newArrVideoF = [...arrVideoF, ...selectedVideos];
            setArrVideoF(newArrVideoF);
            break;
          case "G":
            newArrVideoG = [...arrVideoG, ...selectedVideos];
            setArrVideoG(newArrVideoG);
            break;
          case "H":
            newArrVideoH = [...arrVideoH, ...selectedVideos];
            setArrVideoH(newArrVideoH);
            break;
          default:
            break;
        }

        setVideoUpdatesCompleted(true);
      }
    },
    [
      seqType,
      breakTime,
      arrVideoA,
      arrVideoB,
      arrVideoC,
      arrVideoD,
      arrVideoE,
      arrVideoF,
      arrVideoG,
      arrVideoH,
    ]
  );

  useEffect(() => {
    if (videoUpdatesCompleted) {
      handleSubmit();
      setVideoUpdatesCompleted(false);
    }
  }, [videoUpdatesCompleted]);

  // 비디오 클릭 핸들러
  const handleVideoClick = (seqType: string, seq: string) => {
    const key = `${seqType}${seq}`;
    setVideoPlayStates((prevStates: any) => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  const handleDelete = (videoToDelete: any) => {
    setItemsForSeqType(seqType, (prevVideos: any) =>
      prevVideos.filter(
        (video: any) =>
          `${video.mv_no}-${video.seq}` !==
          `${videoToDelete.mv_no}-${videoToDelete.seq}`
      )
    );
  };

  // 비디오 목록을 가져오는 함수
  const getItemsForSeqType = (type: any) => {
    switch (type) {
      case "A":
        return arrVideoA;
      case "B":
        return arrVideoB;
      case "C":
        return arrVideoC;
      case "D":
        return arrVideoD;
      case "E":
        return arrVideoE;
      case "F":
        return arrVideoF;
      case "G":
        return arrVideoG;
      case "H":
        return arrVideoH;
      default:
        return [];
    }
  };

  // 비디오 목록을 업데이트하는 함수
  const setItemsForSeqType = (type: any, items: any) => {
    switch (type) {
      case "A":
        setArrVideoA(items);
        break;
      case "B":
        setArrVideoB(items);
        break;
      case "C":
        setArrVideoC(items);
        break;
      case "D":
        setArrVideoD(items);
        break;
      case "E":
        setArrVideoE(items);
        break;
      case "F":
        setArrVideoF(items);
        break;
      case "G":
        setArrVideoG(items);
        break;
      case "H":
        setArrVideoH(items);
        break;
    }
  };

  const renderVideosForSeqType = (type: string) => {
    const items = getItemsForSeqType(type).map((item) => ({
      ...item,
      id: item.mv_no,
    }));
    const onSort = (newState: any[]) => {
      const updatedItems = newState.map((item) => ({
        ...item,
        mv_no: item.id,
      }));
      setItemsForSeqType(type, updatedItems);
    };

    const handleClone = (index: number) => {
      // 현재 시퀀스의 비디오 목록 복사
      const currentVideos = [...getItemsForSeqType(seqType)];
      const clonedVideo = { ...currentVideos[index] };

      // 유니크한 seq 값 할당
      const maxSeq = currentVideos.reduce(
        (max, video) => Math.max(max, parseInt(video.seq || "0", 10)),
        0
      );
      clonedVideo.seq = (maxSeq + 1).toString();

      // 업데이트된 비디오 목록 설정
      const updatedVideos = [...currentVideos];
      updatedVideos.splice(index + 1, 0, clonedVideo); // 복사된 영상을 바로 뒷 순위로 삽입

      // 시퀀스에 맞는 상태 업데이트
      setItemsForSeqType(seqType, updatedVideos);
      console.log("updatedVideos", updatedVideos);
    };

    return (
      <ReactSortable
        list={items}
        setList={onSort}
        animation={200}
        handle=".drag-handle"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "5px",
        }}
      >
        {items.map((video, index, key) => (
          <VideoWrapper key={`${video.mv_no}-${seqType}${video.seq}`}>
            <VideoControl>
              <VideoNumber>No{index + 1}</VideoNumber>
              {checkboxVisible ? (
                <Checkbox
                  className={"checkBox"}
                  type="checkbox"
                  checked={selectedVideos[`${seqType}${video.seq}`] || false}
                  onChange={(e) =>
                    handleCheckboxChange(
                      seqType,
                      video.seq || "",
                      e.target.checked
                    )
                  }
                  onClick={handleCheckboxClick}
                  disabled={!video.seq}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  onClick={(e) => handleDragHandleMouseDown(index)}
                />
              )}
              <TimeWrap>
                {parseInt(video.ex_time) > 0 && (
                  <span className="fontRed">{`${Math.floor(
                    parseInt(video.ex_time)
                  )}`}</span>
                )}
                {parseInt(video.ex_time) > 0 &&
                  parseInt(video.wait_time) > 0 && <span> | </span>}
                {parseInt(video.wait_time) > 0 && (
                  <span className="fontGreen">{`${Math.floor(
                    parseInt(video.wait_time)
                  )}`}</span>
                )}
              </TimeWrap>
              <DeleteButton onClick={() => handleDelete(video)}>
                <FontAwesomeIcon icon={faTimes} />
              </DeleteButton>
            </VideoControl>
            <VideoContainer>
              {!videoPlayStates[`${seqType}${video.seq}`] ? (
                <VideoImg
                  src={video.mv_rep_img}
                  alt="thumbnail"
                  onClick={() => handleVideoClick(seqType, video.seq || "")}
                />
              ) : (
                <video
                  src={video.mv_url}
                  loop
                  autoPlay
                  muted
                  onClick={() => handleVideoClick(seqType, video.seq || "")}
                />
              )}
            </VideoContainer>
            <VideoTitleWrap>
              <VideoTitle className="drag-handle">{video.mv_nm}</VideoTitle>
              <CloneBtn>
                <FontAwesomeIcon
                  icon={faClone}
                  onClick={() => handleClone(index)}
                />
              </CloneBtn>
            </VideoTitleWrap>
          </VideoWrapper>
        ))}
      </ReactSortable>
    );
  };

  const handleDragHandleMouseDown = (index: number) => {
    // 클릭된 비디오의 데이터 찾기
    const currentVideoData = getItemsForSeqType(seqType)[index];

    // 선택된 비디오의 시퀀스 유형 저장
    setSelectedVideoSeqType(seqType);
    setSelectedVideoIndex(index);

    // 클릭된 비디오를 selectedVideo 상태에 설정
    setSelectedVideo(currentVideoData);

    // 컨텍스트 메뉴 활성화
    if (proNo) {
      setIsContextMenuActive(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const bottomNav = document.querySelector(".bottomNav");

      // bottomNav 밖에서 시작되었으며 체크박스 클릭이 아닌 경우에만 BottomNav 비활성화
      if (
        bottomNav &&
        !bottomNav.contains(e.target as Node) &&
        !(e.target as HTMLElement).classList.contains("checkBox")
      ) {
        setIsContextMenuActive(false);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isContextMenuActive]);

  const handleCheckboxChange = (
    seqType: string,
    seq: string,
    checked: boolean
  ) => {
    const videoId = `${seqType}${seq}`;
    if (!seqType || !seq) {
    } else {
      setSelectedVideos((prevSelectedVideos) => ({
        ...prevSelectedVideos,
        [videoId]: checked,
      }));
    }
  };

  const handleSelectAllVideos = () => {
    // 현재 시퀀스에 해당하는 모든 비디오를 가져오기
    const currentSeqVideos = getItemsForSeqType(seqType);
    // 모든 비디오가 현재 선택되어 있는지 확인
    const allSelected = currentSeqVideos.every(
      (video) => selectedVideos[`${seqType}${video.seq}`]
    );

    // 새로운 선택 상태를 생성
    const newSelectedVideos: SelectedVideos = {};
    currentSeqVideos.forEach((video) => {
      const videoId = `${seqType}${video.seq}`;
      newSelectedVideos[videoId] = !allSelected;
    });

    // 상태를 업데이트
    setSelectedVideos(newSelectedVideos);
    setIsAllSelected(!allSelected);

    console.log("New selected videos:", newSelectedVideos);
  };

  // 이벤트 전파 중단
  const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    // 외부 클릭 감지를 위한 이벤트 리스너 등록
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropdown]);

  const toggleDropdown = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setShowDropdown(true); // 항상 true로 설정하여 메뉴를 엽니다.
  };

  const renderDropdownMenu = () => {
    if (showDropdown) {
      return (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            zIndex: "9999",
            top: "17px",
            left: "10%",
            backgroundColor: "#f8f8f8",
          }}
        >
          <select
            value={selectedSeq}
            onChange={(e) => {
              setSelectedSeq(e.target.value);
              // handleShare(e.target.value);
              handleMoveSelected(e.target.value);
            }}
            style={{ background: "none", border: "none", fontSize: "18px" }}
          >
            <option value="" hidden>
              A
            </option>
            {["A", "B", "C", "D", "E", "F", "G", "H"].map((seq) => (
              <option key={seq} value={seq}>
                {seq}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = async () => {
    const formatVideos = (videos: any) =>
      videos.map((v: any) => ({
        ...v,
        wait_time: restTime.toString(),
        ex_time: exerciseTime.toString(),
      }));

    const data = {
      pro_m_nm: branchName,
      pro_no: proNo,
      center_no: MLoginInfo.MLoginInfo.center_no,
      auto_yn: checked ? "y" : "n",
      br_time: breakTime.toString(),
      array_a: formatVideos(arrVideoA),
      array_b: formatVideos(arrVideoB),
      array_c: formatVideos(arrVideoC),
      array_d: formatVideos(arrVideoD),
      array_e: formatVideos(arrVideoE),
      array_f: formatVideos(arrVideoF),
      array_g: formatVideos(arrVideoG),
      array_h: formatVideos(arrVideoH),
    };
    console.log("제출 데이터:", data);

    try {
      const response = await ServerApi.m_app_pro_m_i(data);

      if (response.rsp_code === "100") {
        console.log("제출 응답:", data);
        setModalMessage("Complete");
        setModalIsOpen(true);
        setNewProNo(response?.pro_no);
        console.log("response", response, newProNo);
        history.push("/ProgramsVideoM2", { pro_no: response?.pro_no });
      } else if (response.rsp_code === "300") {
        console.error("제출 실패:", response.msg);
      }
    } catch (error) {
      console.error("제출 중 오류 발생:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "./ProgramsItemM",
      state: { pro_no: proNo },
    });
  };

  useEffect(() => {
    if (location?.state?.pro_no) {
      // 수정 모드이며, 프로그램 번호가 제공됨
      setEditMode(true);
      console.log("현재는 수정모드", editMode);
    }
  }, [location, editMode]);

  // 체크된 영상에 대한 복사
  const handleCopySelected = () => {
    console.log("비디오 복사");
    const currentSeqVideos = getItemsForSeqType(seqType);

    // 가장 큰 seq 값을 찾습니다.
    const maxSeq = currentSeqVideos.reduce(
      (max, video) => Math.max(max, parseInt(video.seq || "", 10)),
      0
    );

    // 선택된 비디오를 복사하고, 새로운 seq 값을 할당합니다.
    const clonedVideos = currentSeqVideos
      .filter((video) => selectedVideos[`${seqType}${video.seq}`])
      .map((video, index) => ({
        ...video,
        // 복사된 비디오에 대해 새로운 seq 값을 할당합니다.
        seq: (maxSeq + index + 1).toString(),
      }));

    // 현재 시퀀스에 복사된 비디오를 추가합니다.
    const updatedVideos = [...currentSeqVideos, ...clonedVideos];
    setItemsForSeqType(seqType, updatedVideos);

    // 선택된 비디오 상태를 초기화합니다.
    setSelectedVideos({});
  };

  const handleDeleteSelected = () => {
    const updatedVideos = getItemsForSeqType(seqType).filter(
      (video) => !selectedVideos[`${seqType}${video.seq}`]
    );
    setItemsForSeqType(seqType, updatedVideos);
    setSelectedVideos({}); // 선택된 비디오 초기화
  };

  // 이전 영상데이터 이동 핸들러
  const handleMoveSelected = async (targetSeqType: string) => {
    console.log(`Moving selected videos to sequence ${targetSeqType}`);

    // 소스 시퀀스의 비디오 목록
    const sourceVideos = [...getItemsForSeqType(seqType)];
    // 타겟 시퀀스의 비디오 목록
    const targetVideos = [...getItemsForSeqType(targetSeqType)];

    // 이동할 비디오와 남아있을 비디오를 분류
    const movingVideos: ItemType[] = [];
    const remainingVideos: ItemType[] = [];

    sourceVideos.forEach((video) => {
      if (selectedVideos[`${seqType}${video.seq}`]) {
        movingVideos.push({ ...video });
      } else {
        remainingVideos.push({ ...video });
      }
    });

    // 타겟 시퀀스에 비디오들 추가
    const updatedTargetVideos = [...targetVideos, ...movingVideos].map(
      (video, index) => ({
        ...video,
        seq: `${index + 1}`, // 새로운 seq 할당
      })
    );

    // 소스와 타겟 시퀀스를 업데이트
    setItemsForSeqType(seqType, remainingVideos);
    setItemsForSeqType(targetSeqType, updatedTargetVideos);
    // 선택된 비디오 상태를 초기화
    setSelectedVideos({});

    // 변경 사항을 서버에 저장
    await handleSubmit();
  };

  const calculateAllTime = () => {
    const videoLists = [
      arrVideoA,
      arrVideoB,
      arrVideoC,
      arrVideoD,
      arrVideoE,
      arrVideoF,
      arrVideoG,
      arrVideoH,
    ];
    let totalTimeInSeconds = 0;
    let totalExercises = 0;

    videoLists.forEach((list) => {
      // 각 비디오 리스트(시퀀스)의 운동 시간과 휴식 시간을 합산
      list.forEach((video) => {
        totalTimeInSeconds +=
          parseInt(video.ex_time || "0", 10) +
          parseInt(video.wait_time || "0", 10);
      });
      // 운동 수를 누적하여 계산
      totalExercises += list.length;
    });

    // br_time은 각 비디오(운동) 사이에 적용되므로, 운동이 두 개 이상 있을 경우에만 총 시간에 추가
    if (totalExercises > 1) {
      // 전체 운동 수에서 1을 뺀 값과 br_time(초 단위)을 곱하여 총 시간에 추가
      totalTimeInSeconds += (totalExercises - 1) * parseInt(brTime, 10);
    }

    // 총 시간을 포맷팅하여 반환
    return MyUtil._secondToTimeFormat(totalTimeInSeconds);
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked);
  };

  type SeqType = "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H";

  // 시퀀스 타입에 따라 로컬 상태에서 비디오 목록을 업데이트하는 함수
  const updateVideosForSeqType = (type: SeqType) => {
    // 각 시퀀스 타입에 맞게 상태 업데이트 함수 매핑
    const currentVideos = getItemsForSeqType(type);
    const updateStateFunctions: {
      [key in SeqType]: React.Dispatch<React.SetStateAction<ItemType[]>>;
    } = {
      A: setArrVideoA,
      B: setArrVideoB,
      C: setArrVideoC,
      D: setArrVideoD,
      E: setArrVideoE,
      F: setArrVideoF,
      G: setArrVideoG,
      H: setArrVideoH,
    };

    // 해당 시퀀스의 비디오 목록을 업데이트
    const updateFunction = updateStateFunctions[type];
    if (updateFunction) {
      updateFunction(currentVideos);
    }
  };

  const handleTabClick = (type: string) => {
    console.log(`탭변경 from ${seqType} to ${type}`);
    setSelectedVideos({});
    setSeqType(type); // 탭 변경
    updateVideosForSeqType(type as SeqType);
  };

  return (
    <>
      <Overlay isModalOpen={isVideoIuModalOpen} />
      <ProgramsVideoContainer isModalOpen={isVideoIuModalOpen}>
        <MobileHeader
          textOne={"EXERCISE SETUP"}
          // textTwo={"DAILY EXERCISE SETUP"}
          onTextOneClick={() => handleHeaderButtonClick("exercise")}
          onTextTwoClick={() => handleHeaderButtonClick("daily")}
          initialActive={"textOne"}
        />
        {activePage === "exercise" && (
          <>
            <div className="containerWrap">
              <SubmitContainer>
                <Switch
                  onChange={handleChange}
                  checked={checked}
                  onColor="#09348a"
                  height={20}
                  width={44}
                  className="react-switch"
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ccc",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{ height: "16px" }}
                        src={icRepeat}
                        alt="logo image"
                      />
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        backgroundColor: "#ccc",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{ height: "16px" }}
                        src={icRepeat}
                        alt="logo image"
                      />
                    </div>
                  }
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <SubmitButton onClick={handleSubmit}>
                  <FaCircleCheck />
                </SubmitButton>
                <CancelButton onClick={handleCancel}>
                  <FaCircleXmark />
                </CancelButton>
              </SubmitContainer>
            </div>
            <div className="videoTitleWrap">
              <VideoTitleContents>
                <span className="videoTitle">TITLE</span>
                <InputTitle
                  type="text"
                  required
                  placeholder="Please enter"
                  value={branchName}
                  onChange={(evt) => setBranchName(evt.target.value)}
                ></InputTitle>
              </VideoTitleContents>

              <TotalTime>
                <p>TOTAL TIME</p>
                <span>{calculateAllTime()}</span>
              </TotalTime>

              <div
                className="videoTitleContents fontRed"
                onClick={() => handleOpenTimeSettingModal("exercise")}
              >
                <span className="videoTitle">EXERCISE</span>
                <div className="myTimeTxt">
                  {exerciseTime !== 0
                    ? MyUtil._secondToTimeFormat(exerciseTime)
                    : totalExTime}
                </div>
              </div>

              <div
                className="videoTitleContents fontGreen"
                onClick={() => handleOpenTimeSettingModal("rest")}
              >
                <span className="videoTitle">REST</span>
                <div className="myTimeTxt">
                  {restTime !== 0
                    ? MyUtil._secondToTimeFormat(restTime)
                    : totalRestTime}
                </div>
              </div>

              <div
                className="videoTitleContents"
                onClick={() => handleOpenTimeSettingModal("break")}
              >
                <span className="videoTitle">BREAK TIME</span>
                <div className="myTimeTxt">
                  {breakTime !== 0
                    ? MyUtil._secondToTimeFormat(breakTime)
                    : MyUtil._secondToTimeFormat(parseInt(brTime))}
                </div>
              </div>
            </div>

            <TabContainer>
              {["A", "B", "C", "D", "E", "F", "G", "H"].map((type) => (
                <TabButton
                  key={type}
                  active={seqType === type}
                  onClick={() => handleTabClick(type)}
                >
                  <span className="packagesItemTabTxt">{type}</span>
                </TabButton>
              ))}
            </TabContainer>
            <VideoContents>
              <VideoListContainer>
                {renderVideosForSeqType(seqType)}
              </VideoListContainer>

              {isContextMenuActive ? (
                <BottomNav className={"bottomNav"}>
                  <NavButton onClick={toggleDropdown}>
                    {renderDropdownMenu()}
                    <IoMdShare />
                  </NavButton>
                  <NavButton onClick={handleCopySelected}>
                    <MdContentCopy />
                  </NavButton>
                  <NavButton onClick={handleSelectAllVideos}>
                    {isAllSelected ? <FaCheck /> : <>All</>}
                  </NavButton>
                  <NavButton onClick={handleDeleteSelected}>
                    <IoTrashOutline />
                  </NavButton>
                </BottomNav>
              ) : null}
            </VideoContents>
            {isVideoIuModalOpen && (
              <ModalVideoSelect
                isModalOpen={isVideoIuModalOpen}
                videoList={videoList}
                ModalCb={ModalVideoCb}
              />
            )}
            <div className={"buttonContainer"}>
              <AccountButton
                buttonUse={buttonToShow}
                mod_yn={MLoginInfo.MLoginInfo.mod_yn}
                pro_no={proNo}
                setIsVideoIuModalOpen={setIsVideoIuModalOpen}
              />
            </div>
          </>
        )}

        {activePage === "daily" && <></>}
      </ProgramsVideoContainer>
      <AlertModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: "999",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeAlertModal}>OK</button>
      </AlertModal>
      <StyledModal
        isOpen={isTimeInputModalOpen}
        onRequestClose={() => setIsTimeInputModalOpen(false)}
        contentLabel="Time Setting"
        className="modalAlertBox"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "999" },
        }}
      >
        <ModalTitle>
          {timeSettingMode === "exercise"
            ? "EXERCISE TIME"
            : timeSettingMode === "rest"
            ? "REST TIME"
            : "BREAK TIME"}
        </ModalTitle>
        <StyledInputContainer>
          <StyledInput
            type="tel"
            placeholder="Time seconds"
            onChange={handleInputChange}
          />
        </StyledInputContainer>
        <button style={{ color: "#fff" }} onClick={updateAllTimes}>
          SAVE
        </button>
      </StyledModal>
    </>
  );
};
export default ProgramsVideoM2;
