import styled from "styled-components";

const Folder = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FolderCount = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FolderDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;
`;

const FolderTitle = styled.span`
  font-weight: bold;
  color: #000;
`;

const FolderSubTitle = styled.span`
  color: #666;
`;

const FolderSelect = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: 14px;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: white;
  }

  input[type="checkbox"]:checked + label::before {
    background: #09348a;
    border-color: #09348a;
  }

  input[type="checkbox"]:checked + label::after {
    position: absolute;
    left: 3px;
    top: 0px;
    color: #000;
    font-size: 16px;
  }
`;

function MusicFolder({
  editMode,
  folderList,
  selectedFolders,
  onFolderSelectionChange,
  onClick = () => {},
}: any) {
  return (
    <>
      {folderList.map((folder: any, index: number) => (
        <Folder key={index}>
          {editMode && (
            <FolderSelect>
              <input
                type="checkbox"
                id={`folderSelection-${folder.play_fold_no}`}
                name={`folderSelection-${folder.play_fold_no}`}
                checked={selectedFolders.has(folder.play_fold_no)}
                onChange={(e) =>
                  onFolderSelectionChange(folder.play_fold_no, e.target.checked)
                }
              />
              <label htmlFor={`folderSelection-${folder.play_fold_no}`}></label>
            </FolderSelect>
          )}
          <FolderCount>{folder.play_ct}</FolderCount>
          <FolderDetails onClick={() => onClick(folder)}>
            <FolderTitle>{folder.title}</FolderTitle>
            <FolderSubTitle>{folder.sub_title}</FolderSubTitle>
          </FolderDetails>
          {/*<SelectionIcon onClick={(event) => toggleSelection(event, index)}>*/}
          {/*    <FontAwesomeIcon icon={selections[index] ? faCheck : faPlus}/>*/}
          {/*</SelectionIcon>*/}
        </Folder>
      ))}
    </>
  );
}

export default MusicFolder;
